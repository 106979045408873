import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TAuctionObj } from '../../../../api/auctionApi'
import AppState from '../../../../store/AppState'
import styles from './AuctionCharact.module.scss'

type TCharact = {
  title: string
  text: string
}

const data: TCharact[] = [
  {
    title: 'Tipo inmueble',
    text: 'Chalet adosado',
  },
  {
    title: 'Estado inmueble',
    text: 'Entrar a vivir',
  },
  {
    title: 'Año construcción',
    text: '2009',
  },
  {
    title: 'Referencia catastral',
    text: '000000000',
  },
  {
    title: 'Emisiones (CE)',
    text: '0 kg CO2/m² año',
  },
  {
    title: 'Consumo (CE)',
    text: '47 kWh/m² año',
  },
  {
    title: 'Metros útiles',
    text: '300m2',
  },
  {
    title: 'Metros construidos',
    text: '400m2',
  },
  {
    title: 'Metros parcela',
    text: '200m2',
  },
  {
    title: 'Dormitorios',
    text: '8',
  },
  {
    title: 'Baños',
    text: '6',
  },
  {
    title: 'Ascensor',
    text: 'Sí',
  },
  {
    title: 'Parking',
    text: 'Propio para 4 vehículos',
  },
  {
    title: 'Jardin',
    text: 'Sí',
  },
  {
    title: 'Piscina',
    text: 'Sí',
  },
  {
    title: 'Terraza',
    text: 'Sí',
  },
]
export const AuctionCharact: FC<{ item: TAuctionObj }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.list}>
        {item.TypeProperty && (
          <div className={styles.item}>
            <span className={styles.title}>Tipo inmueble</span>
            <span className={styles.text}>{item.TypeProperty.name}</span>
          </div>
        )}
        {item.PropertyStatus && (
          <div className={styles.item}>
            <span className={styles.title}>Estado inmueble</span>
            <span className={styles.text}>{item.PropertyStatus.name}</span>
          </div>
        )}
        {item.yearConstruction && (
          <div className={styles.item}>
            <span className={styles.title}>Año construcción</span>
            <span className={styles.text}>{item.yearConstruction}</span>
          </div>
        )}
        {item.cadastralReference !== '' && (
          <div className={styles.item}>
            <span className={styles.title}>Referencia catastral</span>
            <span className={styles.text}>{item.cadastralReference}</span>
          </div>
        )}
        {item.Emission && (
          <div className={styles.item}>
            <span className={styles.title}>Emisiones (CE)</span>
            <span className={styles.text}>{item.Emission.name} kg CO2/m² año</span>
          </div>
        )}
        {item.Consumption && (
          <div className={styles.item}>
            <span className={styles.title}>Consumo (CE)</span>
            <span className={styles.text}>{item.Consumption.name} kWh/m² año</span>
          </div>
        )}
        {item.usableMeters && (
          <div className={styles.item}>
            <span className={styles.title}>Metros útiles</span>
            <span className={styles.text}>
              {item.usableMeters}m<sup>2</sup>
            </span>
          </div>
        )}
        {item.constructedMeters && (
          <div className={styles.item}>
            <span className={styles.title}>Metros construidos</span>
            <span className={styles.text}>
              {item.constructedMeters}m<sup>2</sup>
            </span>
          </div>
        )}
        {item.plotMeters && (
          <div className={styles.item}>
            <span className={styles.title}>Metros parcela</span>
            <span className={styles.text}>
              {item.plotMeters}m<sup>2</sup>
            </span>
          </div>
        )}
        {item.bedrooms && (
          <div className={styles.item}>
            <span className={styles.title}>Dormitorios</span>
            <span className={styles.text}>{item.bedrooms}</span>
          </div>
        )}
        {item.bathrooms && (
          <div className={styles.item}>
            <span className={styles.title}>Baños</span>
            <span className={styles.text}>{item.bathrooms}</span>
          </div>
        )}
        <div className={styles.item}>
          <span className={styles.title}>Ascensor</span>
          <span className={styles.text}>{item.elevator ? 'Si' : 'No'}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Parking</span>
          <span className={styles.text}>{item.garage ? 'Si' : 'No'}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Jardin</span>
          <span className={styles.text}>{item.garden ? 'Si' : 'No'}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Piscina</span>
          <span className={styles.text}>{item.pool ? 'Si' : 'No'}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Terraza</span>
          <span className={styles.text}>{item.terrace ? 'Si' : 'No'}</span>
        </div>
      </div>
    </div>
  )
})
