import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AppState from '../../../../store/AppState'
import { dateFormatFull } from '../../../../utils/date.utils'
import { priceFormat } from '../../../../utils/price.utils'
import styles from './AuctionHistory.module.scss'

export type TAuctionHistoryItem = {
	id: number
	name: string
	price: number
	date: string
}

type TAuctionHistoryProps = {
	items: TAuctionHistoryItem[]
}

export const AuctionHistory: FC<TAuctionHistoryProps> = observer(({ items }) => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const list = items.map((item) => (
		<div className={styles.item}>
			<div className={styles.block}>
				<span className={styles.name}>{item.name}</span>
				<span className={styles.date}>{dateFormatFull(item.date)}</span>
			</div>
			<div className={styles.price}>{priceFormat(item.price)}</div>
		</div>
	))
	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<h3 className={styles.title}>
				<span className={styles.inner}>Histórico de pujas </span>
				<span className={styles.counter}>{items.length} pujas</span>
			</h3>
			<div className={styles.list}>{list}</div>
		</div>
	)
})
