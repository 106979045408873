import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import AppState from '../../../store/AppState'
import ModalState from '../../../store/ModalState'
import { FADE_IN } from '../../../utils/animation/fade'
import { ExitIcon } from '../../Icons/Icons'
import styles from './ModalLegal.module.scss'
import {CheckBox} from '../../CheckBox/CheckBox'
import {Button} from '../../Button/Button'

export const ModalLegal: FC = observer(() => {
  const [isCheck, setIsCheck] = useState(true)
  const width = AppState.widthWindow
  const isMobile = width <= 768


  const closeModal = () => {
    ModalState.toggleModalLegal(false)
  }

  const toggleCheck = () => {
    setIsCheck(!isCheck)
  }

  const handlerClick = () => {
    if (isCheck) {
      ModalState.changeLegalStatus(true)
    }
  }

  return (
    <motion.div {...FADE_IN} className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <button className={styles.close} onClick={closeModal}>
        <ExitIcon />
      </button>
      <div className={styles.content}>
        <h1 className={styles.title}>Legal terms and conditions</h1>
        <p className={styles.legal}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque feugiat nisl in malesuada maximus. Nunc id diam dapibus, sodales lectus ut, hendrerit quam. Ut in mollis nulla, eget vestibulum ligula. In quis neque consectetur, ornare sem ut, facilisis sem. Etiam iaculis metus eget nibh varius molestie. Aliquam est ex, mollis vel facilisis vitae, lacinia at ante. Nullam purus magna, hendrerit eu ullamcorper aliquam, interdum quis quam. Pellentesque finibus fringilla erat, eu vestibulum velit pretium vitae. Suspendisse et dignissim neque.
          <br/>Mauris porttitor enim non felis mattis iaculis. Nunc id ex eu ante facilisis mollis. Maecenas lectus purus, laoreet in felis sit amet, iaculis varius sapien. Proin efficitur augue et diam congue, sed dictum odio pulvinar. Nunc non diam ac elit porttitor maximus.
          <br/>Nullam purus magna, hendrerit eu ullamcorper aliquam, interdum quis quam. Pellentesque finibus fringilla erat, eu vestibulum velit pretium vitae. Suspendisse et dignissim neque.
          <br/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque feugiat nisl in malesuada maximus. Nunc id diam dapibus, sodales lectus ut, hendrerit quam. Ut in mollis nulla, eget vestibulum ligula. In quis neque consectetur, ornare sem ut, facilisis sem.
          <br/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque feugiat nisl in malesuada maximus. Nunc id diam dapibus, sodales lectus ut, hendrerit quam. Ut in mollis nulla, eget vestibulum ligula. In quis neque consectetur, ornare sem ut, facilisis sem. Etiam iaculis metus eget nibh varius molestie. Aliquam est ex, mollis vel facilisis vitae, lacinia at ante. Nullam purus magna, hendrerit eu ullamcorper aliquam, interdum quis quam. Pellentesque finibus fringilla erat, eu vestibulum velit pretium vitae. Suspendisse et dignissim neque.
        </p>

        <div className={styles.check}>
          <CheckBox isCheck={isCheck} onChange={toggleCheck} label={'I have read and accept the conditions described in the table above.'}/>
        </div>

        <div className={styles.btn}>
          <Button disabled={!isCheck} onClick={handlerClick}>Accept and pay</Button>
        </div>
      </div>
    </motion.div>
  )
})
