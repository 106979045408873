import { observer } from 'mobx-react-lite'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { TAuctionObj, getMyAuctionAllApi } from '../../api/auctionApi'
import { Pagination } from '../../components/Pagination/Pagination'
import AppState from '../../store/AppState'
import AuthState from '../../store/AuthState'
import UpdateState from '../../store/UpdateState'
import styles from './MyAuctionsPage.module.scss'
import { MyAuctionsItem } from './components/MyAuctionsItem/MyAuctionsItem'

export const MyAuctionsPage: FC = observer(() => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	/*  const navigator = useNavigate()
  const user = AuthState.user?.type */
	const [items, setItems] = useState<TAuctionObj[]>([])
	const [len, setLen] = useState(0)
	const [count, setCount] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [postsPerPage] = useState(10)
	const role = AuthState.user?.type

	useEffect(() => {
		if (role) {
			getMyAuctionAllApi({ role, take: postsPerPage, skip: count })
				.then((res) => {
					/* console.log(res.data.auctions) */
					setLen(res.data.count)
					setItems(res.data.auctions)
					console.log(res.data.auctions)
				})
				.catch((err) => console.log(err))
		}
	}, [count, UpdateState.updateAuction])

	const paginates = (pageNumber: SetStateAction<number>) => {
		setCurrentPage(pageNumber)
		setCount(postsPerPage * Number(pageNumber))
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	const previousPage = () => {
		if (currentPage >= 0) {
			setCurrentPage((prev) => prev - 1)
			setCount((prev) => prev - postsPerPage)
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
		}
	}

	const nextPage = () => {
		if (currentPage !== Math.ceil(len / postsPerPage)) {
			setCurrentPage((prev) => prev + 1)
			setCount((prev) => prev + postsPerPage)
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
		}
	}
	useEffect(() => {
		console.log(items)
	}, [])
	const list = items.map((item) => <MyAuctionsItem item={item} key={item.id} />)
	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<h2 className={styles.title}>Mis propiedades en subasta</h2>
			<div className={styles.list}>{list}</div>
			{len > postsPerPage && (
				<div className={styles.pagination}>
					<Pagination
						postsPerPage={postsPerPage}
						totalPosts={len}
						paginate={paginates}
						previousPage={previousPage}
						nextPage={nextPage}
						currentPage={currentPage}
					/>
				</div>
			)}
		</div>
	)
})
