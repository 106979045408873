import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AppState from '../../../../../../store/AppState'
import { MainStatBidsBar } from '../MainStatBidsBar/MainStatBidsBar'
import { MainStatBidsLine } from '../MainStatBidsLine/MainStatBidsLine'
import styles from './MainStatBids.module.scss'

export const MainStatBids: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.block}>
        <MainStatBidsBar />
      </div>
      <div className={styles.block}>
        <MainStatBidsLine />
      </div>
    </div>
  )
})
