import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC, useRef, useState } from 'react'
import { urlApi } from '../../../config/apiConfig'
import ModalState from '../../../store/ModalState'
import { FADE_IN } from '../../../utils/animation/fade'
import styles from './ModalVideo.module.scss'

export const ModalVideo: FC = observer(() => {
	const { img, linkVideo, show } = ModalState.modalVideo
	const [isPlaying, setIsPlaying] = useState(false)
	const [thumb, setThumb] = useState(true)
	const videoRef = useRef<any>(null)

	const togglePlay = () => {
		videoRef.current.play()
		setIsPlaying(true)
		setThumb(false)
	}
	const togglePause = () => {
		videoRef.current.pause()
		setIsPlaying(false)
	}
	const onClose = () => {
		setIsPlaying(false)
		setThumb(false)
		videoRef.current.pause()
		ModalState.hideModalVideo()
	}
	return (
		<motion.div {...FADE_IN} className={styles.root}>
			<div className={`${styles.content} ${thumb ? styles.active : ''}`}>
				<div className={styles.video}>
					{linkVideo && (
						<video ref={videoRef} width="100%" height="100%" playsInline>
							<source src={linkVideo} />
						</video>
					)}
					{thumb && <div className={styles.thumb} style={img ? { backgroundImage: `url(${urlApi}auction/media?id=${img})` } : undefined}></div>}
					<div className={styles.overlay}>
						{isPlaying ? (
							<button className={styles.pause} onClick={togglePause}></button>
						) : (
							<button className={styles.play} onClick={togglePlay}></button>
						)}
						<button className={styles.close} onClick={onClose}></button>
					</div>
				</div>
			</div>
		</motion.div>
	)
})
