import { orderBy } from 'lodash'
import { FC } from 'react'
import { TAuctionObj } from '../../../../../api/auctionApi'
import img from '../../../../../assets/mainPage/sliderImg.png'
import { Timer } from '../../../../../components/Timer/Timer'
import { urlApi } from '../../../../../config/apiConfig'
import AppState from '../../../../../store/AppState'
import { priceFormat } from '../../../../../utils/price.utils'
import styles from './MainAuctionItem.module.scss'

export const MainAuctionItem: FC<{ item: TAuctionObj }> = ({ item }) => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			{item.AuctionMedia.length !== 0 ? (
				<span
					className={styles.img}
					style={
						item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id
							? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})` }
							: undefined
					}></span>
			) : (
				<span className={styles.img} style={{ backgroundImage: `url(${img})` }}></span>
			)}
			<div className={styles.content}>
				<h4 className={styles.title}>{item.TypeProperty ? item.TypeProperty?.name : 'Test'}</h4>
				<div className={styles.link}>Referencia: {item.reference}</div>
				<div className={styles.block}>
					<div className={styles.list}>
						<div className={styles.item}>
							<span className={styles.value}>{priceFormat(item.auctionStartingPrice)}</span>
							<span className={styles.text}>Precio de salida</span>
						</div>
						<div className={styles.item}>
							<span className={styles.value}>{priceFormat(item.Bid.length === 0 ? 0 : orderBy(item.Bid, 'sum', 'desc')[0].sum)}</span>
							<span className={styles.text}>Última puja</span>
						</div>
					</div>
					<div className={styles.status}>
						{/*<span className={styles.value}>Activa</span>*/}
						<span className={styles.value}>{item.status === 'APPROVED' ? 'Activa' : ''}</span>
						<span className={styles.text}>Estado</span>
					</div>
					<div className={styles.timer}>
						<Timer date={new Date(item.endAuction)} size="small" />
					</div>
				</div>
			</div>
		</div>
	)
}
