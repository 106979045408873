import { uniqBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TUsersObj, getUserByIdApi } from '../../../../api/usersApi'
import { HomeIconDefault, UserIcon } from '../../../../components/Icons/Icons'
import { Switch } from '../../../../components/Switch/Switch'
import AppState from '../../../../store/AppState'
import UserState from '../../../../store/UserState'
import { nameFormat } from '../../../../utils/name.utils'
import { UsersAuction } from '../UsersAuction/UsersAuction'
import { UsersBids } from '../UsersBids/UsersBids'
import { UsersDeposits } from '../UsersDeposits/UsersDeposits'
import styles from './UsersDetails.module.scss'

/* const elements: JSX.Element[] = [<UsersAuction />, <UsersBids />, <UsersDeposits />] */
const data: string[] = ['Subastas', 'Pujas', 'Depósitos']
export const UsersDetails: FC = observer(() => {
	const navigate = useNavigate()
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const [user, setUser] = useState<TUsersObj | null>(null)
	const [isActive, setIsActive] = useState(0)

	useEffect(() => {
		getUserByIdApi(UserState.id)
			.then((res) => {
				setUser(res.data)
				console.log(res.data)
			})
			.catch((err) => console.log(err))

		return () => {
			UserState.reset()
			// navigate('/users')
		}
	}, [])
	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<div className={styles.top}>
				{user?.ProfessionalData && (
					<>
						{user?.ProfessionalData.logoType ? (
							<div
								className={styles.icon}
								style={{ backgroundImage: `url(data:${user.ProfessionalData.logoType};base64,${user.ProfessionalData.logoData})` }}>
								<span className={styles.status}>
									<HomeIconDefault />
								</span>
							</div>
						) : (
							<div className={styles.icon}>
								<span className={styles.status}>
									<HomeIconDefault />
								</span>
							</div>
						)}
						<div className={styles.content}>
							<div className={styles.nick}>
								<UserIcon />
								{user.ProfessionalData.contactPerson}
							</div>
							<h3 className={styles.title}>{user.ProfessionalData.company}</h3>
							<div className={styles.cif}>CIF: {user.ProfessionalData.CIF}</div>
							<div className={styles.info}>
								{/* <div className={styles.block}>
									<span className={styles.value}>{user.ProfessionalData.phone}</span>
									<h4 className={styles.title}>Telefono</h4>
								</div> */}
								<div className={styles.block}>
									<span className={styles.value}>{user.email}</span>
									<h4 className={styles.title}>Email</h4>
								</div>
								<div className={styles.block}>
									<div className={styles.value}>
										{user.ProfessionalData.address && <span>{`${user.ProfessionalData.address}`},</span>}
										{user.ProfessionalData.postcode && <span>{`${user.ProfessionalData.postcode}`},</span>}
										{user.ProfessionalData.Province && <span>{`${user.ProfessionalData.Province.name}`},</span>}
										{user.ProfessionalData.city && <span>{`${user.ProfessionalData.city}`}.</span>}
									</div>
									<h4 className={styles.title}>Dirección</h4>
								</div>
							</div>
						</div>
					</>
				)}
				{user?.ParticularData && (
					<>
						<div className={styles.icon}>
							{nameFormat(`${user.ParticularData.name} ${user.ParticularData.firstSurname}`)}
							<span className={styles.status}>
								<UserIcon />
							</span>
						</div>
						<div className={styles.content}>
							<h3 className={styles.title}>
								{user?.ParticularData.name} {user?.ParticularData.firstSurname} {user?.ParticularData.secondSurname}
							</h3>
							{user?.ParticularData && <div className={styles.cif}>DNI: {user.ParticularData.NIF}</div>}
							<div className={styles.info}>
								<div className={styles.block}>
									<span className={styles.value}>{user?.ParticularData.phone}</span>
									<h4 className={styles.title}>Telefono</h4>
								</div>
								<div className={styles.block}>
									<span className={styles.value}>{user?.email}</span>
									<h4 className={styles.title}>Email</h4>
								</div>
								<div className={styles.block}>
									<span className={styles.value}>
										{user?.ParticularData.address && <span>{user?.ParticularData.address},</span>}
										{user?.ParticularData.postcode && <span>{user?.ParticularData.postcode},</span>}
										{user?.ParticularData.Province !== null && <span>{user?.ParticularData.Province.name}, </span>}
										{user?.ParticularData.city !== null && <span>{user?.ParticularData.city}.</span>}
									</span>
									<h4 className={styles.title}>Dirección</h4>
								</div>
							</div>
						</div>
					</>
				)}
				{/* <span className={styles.send}>
					<SendIcon />
				</span> */}
			</div>
			<div className={styles.switch}>
				<Switch items={data} index={isActive} onChange={(i) => setIsActive(i)} />
			</div>
			{user?.Auction && (
				<div className={styles.content}>
					{isActive === 0 && <UsersAuction items={user?.Auction} />}
					{isActive === 1 && user && user.Bid && <UsersBids items={uniqBy(user.Bid, 'auctionId')} />}
					{isActive === 2 && <UsersDeposits items={user.Deposit} />}
				</div>
			)}
		</div>
	)
})
