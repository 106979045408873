import { apiService } from '../services/apiServices/apiService'
import { TAuctionObj } from './auctionApi'

export type TGetByFIlter = {
  city: string
  createdAt: Date | null
  endAuction: Date | null
  provinceIds: string[] | undefined
  bedrooms: number | undefined
  bathrooms: number | undefined
  postcode: string | undefined
  pool: boolean
  garage: boolean
  elevator: boolean
  terrace: boolean
  garden: boolean
  priceMin: number
  priceMax: number
  bidMin: number
  bidMax: number
  typeProperty: 'Casa' | 'Piso' | 'ático' | 'bajo' | 'chalets' | 'locales' | 'naves' | 'fincas' | 'solar' | 'otros' | undefined
}

type TGetByFIlterApi = {
  count: number
  auctions: TAuctionObj[]
}

export const getByFIlterApi = async (payload: TGetByFIlter) => {
  return await apiService<TGetByFIlterApi>('post', 'auction/getByFilter', payload)
}

export type TLastFilter = {
  bathrooms: number | null
  bedrooms: number | null
  bidMax: number
  bidMin: number
  city: string | null
  createdAt: Date | null
  dateSearch: Date | null
  elevator: boolean
  endAuction: Date
  garage: boolean
  garden: boolean
  id: string
  pool: boolean
  postcode: string | null
  priceMax: number
  priceMin: number
  provinceIds: string
  terrace: boolean
  typeProperty: string | null
}

export type TResGetLastFiltersApi = TLastFilter[]

export const getLastFiltersApi = async (payload?: { take: number; skip: number }) => {
  return await apiService<TResGetLastFiltersApi>('get', 'auction/getLastFilters', payload)
}

type TResGetFiltersApi = {
  count: number
  filters: TLastFilter[]
}

export const getFiltersApi = async (payload: { take: number; skip: number }) => {
  return await apiService<TResGetFiltersApi>('get', 'auction/getFilters', payload)
}
