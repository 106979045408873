import {observer} from 'mobx-react-lite'
import {FC} from 'react'
import {deleteProductApi, TServicesApi} from '../../../../api/servicesApi'
import {BaseFotoIcon, LockCloseIcon, RightArrowIcon, TrashIcon} from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import {cuttextUtils} from '../../../../utils/cute.utils'
import {priceFormat} from '../../../../utils/price.utils'
import styles from './ServicesItem.module.scss'
import AuthState from '../../../../store/AuthState'

export type TServicesItem = {
  id: string
  type: 'baseFoto' | 'mediumFoto' | 'advancedFoto' | 'video' | 'videoFoto'
  category: string
  title: string
  text: string
  price: number
  isBlocked: boolean
}

type TServicesItemProps = TServicesApi

type TProps = {
  item: TServicesItemProps
  handlerOpenPay: (productId: string) => void
  updateItems: (items: TServicesApi[]) => void
}

export const ServicesItem: FC<TProps> = observer(({item, handlerOpenPay, updateItems}) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const isAdmin = AuthState.user?.type === 'ADMIN'

  const deleteItem = async () => {
    deleteProductApi({id: item.id})
      .then(res => {
        updateItems(res.data)
      })
  }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.icon}>
        <BaseFotoIcon/>
        {/* {item.type === 'baseFoto' && <BaseFotoIcon />}
        {item.type === 'mediumFoto' && <MediumFotoIcon />}
        {item.type === 'advancedFoto' && <BaseFotoIcon />}
        {item.type === 'video' && <VideoIcon />}
        {item.type === 'videoFoto' && <StarIcon />} */}
      </div>
      <div className={styles.content}>
        <span className={styles.category}>Monográfico</span>
        <h4 className={styles.title}>{item.name}</h4>
        <p className={styles.text}>{cuttextUtils(item.description, 70)}</p>
        <div className={styles.price}>
          <span className={styles.value}>{priceFormat(item.price)}</span>
          <span className={styles.text}>IVA incluido</span>
        </div>
        <div className={styles.bottom}>
          <div className={styles.left}>
            {isAdmin && (
              <>
                  <span className={styles.lock}><span className={styles.green}><LockCloseIcon/></span>
                    {/* {item.isBlocked ? (
                <span className={styles.red}>
                  <CloseLockIcon />
                </span>
              ) : (
                <span className={styles.green}>
                  <LockCloseIcon />
                </span>
              )} */}
            </span>
                <span className={styles.remove} onClick={deleteItem}><TrashIcon/></span>
              </>
            )}
          </div>
          <div className={styles.right} onClick={() => handlerOpenPay(item.id)}>
            Comprar
            <RightArrowIcon/>
          </div>
        </div>
      </div>
    </div>
  )
})
