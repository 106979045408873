import { observer } from 'mobx-react-lite'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TAuctionObj, getAuctionAllApi } from '../../api/auctionApi'
import { Switch } from '../../components/Switch/Switch'
import AppState from '../../store/AppState'
import AuthState from '../../store/AuthState'
import styles from './MyBidsPage.module.scss'
import { MyBidsItem } from './components/MyBidsItem/MyBidsItem'
import {getMyBidsApi} from '../../api/bidApi'

const data: string[] = ['Pujas activas', 'Pujas cerradas', 'Pujas adjudicadas']
export const MyBidsPage: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const navigator = useNavigate()
  const user = AuthState.user?.type
  const [isActive, setIsActive] = useState(0)
  const [items, setItems] = useState<TAuctionObj[]>([])
  const [len, setLen] = useState(0)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage] = useState(10)

  useEffect(() => {
    if (user !== 'PARTICULAR' && user !== 'PROFESSIONAL') {
      navigator('/')
    }
    // getAuctionAllApi({ take: postsPerPage, skip: count })
    //   .then((res) => {
    //     setLen(res.data.count)
    //     setItems(res.data.auctions)
    //   })
    //   .catch((err) => console.log(err))
    setItems([])
    if (isActive === 0) {
      getMyBidsApi({type: 'active'})
        .then(res => {
          setCount(res.data.count)
          setItems(res.data.auctions)
        })
    }

    if (isActive === 1) {
      getMyBidsApi({type: 'closed'})
        .then(res => {
          setCount(res.data.count)
          setItems(res.data.auctions)
        })
    }


  }, [isActive])

  const paginates = (pageNumber: SetStateAction<number>) => {
    setCurrentPage(pageNumber)
    setCount(postsPerPage * Number(pageNumber))
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const previousPage = () => {
    if (currentPage >= 0) {
      setCurrentPage((prev) => prev - 1)
      setCount((prev) => prev - postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const nextPage = () => {
    if (currentPage !== Math.ceil(len / postsPerPage)) {
      setCurrentPage((prev) => prev + 1)
      setCount((prev) => prev + postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  const listActive = items.map((item) => <MyBidsItem item={item} status="active" />)
  const listClose = items.map((item) => <MyBidsItem item={item} status="close" />)
  const listSuccess = items.map((item) => <MyBidsItem item={item} status="success" />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h2 className={styles.title}>Mis pujas</h2>
      <div className={styles.switch}>
        <Switch items={data} index={isActive} onChange={(i) => setIsActive(i)} />
      </div>
      <div className={styles.content}>
        {isActive === 0 && <div className={styles.list}>{listActive}</div>}
        {isActive === 1 && <div className={styles.list}>{listClose}</div>}
        {isActive === 2 && <div className={styles.list}>{listSuccess}</div>}
      </div>
    </div>
  )
})
