import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useState } from 'react'
import { Button } from '../../../../components/Button/Button'
import { LockIcon } from '../../../../components/Icons/Icons'
import { Input } from '../../../../components/Input/Input'
import AppState from '../../../../store/AppState'
import ModalState from '../../../../store/ModalState'
import styles from './ChangePasswordForm.module.scss'

type TFormChangePassword = { password: string; passwordRepeat: string }

export const ChangePasswordForm: FC = observer(() => {
  const [form, setForm] = useState<TFormChangePassword>({ password: '', passwordRepeat: '' })
  const width = AppState.widthWindow
  const isMobile = width <= 768

  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'password' || name === 'passwordRepeat') {
      setForm({ ...form, [name]: e.currentTarget.value })
    }
  }

  const registrationHandler = async () => {
    if (form.password !== '' && form.passwordRepeat !== '') {
      ModalState.setModalPassword()
    }
    /* registrationUserApi({ password: form.password, repeatPassword: form.repeatPassword })
      .then((res) => {
        setAuthLocaleStorage(res.data.accessToken)
        AuthState.setAccessToken(res.data.accessToken)
        AuthState.setUser(res.data.user)
      })
      .catch((err) => console.log(err.response.data)) */
  }

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <div className={styles.password}>
        <Input color={'grey'} iconLeft={<LockIcon />} type={'password'} placeholder={'Nueva contraseña'} name={'password'} value={form.password} onChange={changeForm} />
      </div>
      <div className={styles.password}>
        <Input color={'grey'} iconLeft={<LockIcon />} type={'password'} placeholder={'Repite tu nueva contraseña'} name={'passwordRepeat'} value={form.passwordRepeat} onChange={changeForm} />
      </div>
      <div className={styles.button}>
        <Button onClick={registrationHandler}>Cambiar contraseña</Button>
      </div>
    </div>
  )
})
