import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import AppState from '../../store/AppState'
import styles from './Timer.module.scss'

type TTimerProps = {
  date: Date
  size: 'big' | 'small'
}

export const Timer: FC<TTimerProps> = observer(({ date, size }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [count, setCount] = useState<number>(0)
  const newDate = Number(new Date())
  const dateStart = Number(date)
  const dayCount = dateStart - newDate + 1000
  const dayValue = Math.floor(dayCount / 1000 / 60 / 60 / 24)
  const hoursValue = Math.floor((dayCount / 1000 / 60 / 60) % 24)
  const minutesValue = Math.floor((dayCount / 1000 / 60) % 60)
  const seconsdsValue = Math.floor((dayCount / 1000) % 60)
  const [day, setDay] = useState<number>(dayValue)
  const [hours, setHours] = useState<number>(hoursValue)
  const [minutes, setMinutes] = useState<number>(minutesValue)
  const [seconds, setSeconds] = useState<number>(seconsdsValue)

  useEffect(() => {
    if (day >= 0 && hours >= 0 && minutes >= 0 && seconds >= 0) {
      const timer = setInterval(() => {
        setDay(dayValue)
        setHours(hoursValue)
        setMinutes(minutesValue)
        setSeconds(seconsdsValue)

        setCount(count + 1)
      }, 1000)

      return () => clearInterval(timer)
    } else {
      setDay(0)
      setHours(0)
      setMinutes(0)
      setSeconds(0)
    }
  }, [count])
  return (
    <div className={`${styles.root} ${size === 'big' ? styles.big : styles.small} ${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.block}>
        <span className={styles.value}>{day}</span>
        <span className={styles.text}>días</span>
      </div>
      <div className={styles.block}>
        <span className={styles.value}>{hours < 10 ? `0${hours}` : hours}</span>
        <span className={styles.text}>horas</span>
      </div>
      <div className={styles.block}>
        <span className={styles.value}>{minutes < 10 ? `0${minutes}` : minutes}</span>
        <span className={styles.text}>minutos</span>
      </div>
      <div className={styles.block}>
        <span className={styles.value}>{seconds < 10 ? `0${seconds}` : seconds}</span>
        <span className={styles.text}>segundos</span>
      </div>
    </div>
  )
})
