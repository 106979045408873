import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'

import { getEmissionApi, getСonsumptionApi } from '../../../../../api/auctionApi'
import AppState from '../../../../../store/AppState'
import ModalState from '../../../../../store/ModalState'
import { Button } from '../../../../Button/Button'
import { CheckBox } from '../../../../CheckBox/CheckBox'
import { RightArrowIcon } from '../../../../Icons/Icons'
import { SelectField } from '../../../../SelectField/SelectField'
import { TextField } from '../../../../TextField/TextField'
import { TAuctionFormInput, TAuctionSelectData, TAuctionSelectField } from '../ModalAuctionProperty/ModalAuctionProperty'
import styles from './ModalAuctionCharact.module.scss'

export type TPropertyForm = {
  plotMeters: TAuctionFormInput
  useMeters: TAuctionFormInput
}

export type TCheckboxItem = {
  id: number
  name: string
  active: boolean
}

export const ModalAuctionCharact: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const step = ModalState.step
  const [checkList, setCheckList] = useState<TCheckboxItem[]>([
    {
      id: 0,
      name: 'Piscina',
      active: false,
    },
    {
      id: 1,
      name: 'Garaje',
      active: false,
    },
    {
      id: 2,
      name: 'Ascensor',
      active: false,
    },
    {
      id: 3,
      name: 'Terraza',
      active: false,
    },
    {
      id: 4,
      name: 'Jardin',
      active: false,
    },
  ])
  const [form, setForm] = useState<TPropertyForm>({
    plotMeters: {
      name: '',
      error: false,
    },
    useMeters: {
      name: '',
      error: false,
    },
  })
  const [yearData, setYearData] = useState<TAuctionSelectData[]>([
    { id: '2018', name: '2018' },
    { id: '2019', name: '2019' },
    { id: '2020', name: '2020' },
    { id: '2021', name: '2021' },
    { id: '2022', name: '2022' },
    { id: '2023', name: '2023' },
  ])

  const [year, setYear] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  const [metersData, setMetersData] = useState<TAuctionSelectData[]>([
    { id: '80', name: '80' },
    { id: '90', name: '90' },
    { id: '100', name: '100' },
    { id: '200', name: '200' },
    { id: '300', name: '300' },
    { id: '400', name: '400' },
  ])

  const [meters, setMeters] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  const [emissionsData, setEmissionsData] = useState<TAuctionSelectData[]>([])

  const [emissions, setEmissions] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })
  const [consumptionData, setConsumptionData] = useState<TAuctionSelectData[]>([])

  const [consumption, setConsumption] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  const [bedroomsData, setBedroomsData] = useState<TAuctionSelectData[]>([
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
  ])

  const [bedrooms, setBedrooms] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  const [bathroomsData, setBathroomsData] = useState<TAuctionSelectData[]>([
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
  ])

  const [bathrooms, setBathrooms] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })
  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'plotMeters' || name === 'useMeters') {
      setForm({ ...form, [name]: { name: e.currentTarget.value, error: !!true } })
    }
  }

  useEffect(() => {
    getEmissionApi().then((res) => {
      setEmissionsData(res.data)
    })

    getСonsumptionApi().then((res) => {
      setConsumptionData(res.data)
    })
  }, [])

  useEffect(() => {
    if (ModalState.data) {
      setCheckList([
        {
          id: 0,
          name: 'Piscina',
          active: ModalState.data.pool,
        },
        {
          id: 1,
          name: 'Garaje',
          active: ModalState.data.garage,
        },
        {
          id: 2,
          name: 'Ascensor',
          active: ModalState.data.elevator,
        },
        {
          id: 3,
          name: 'Terraza',
          active: ModalState.data.terrace,
        },
        {
          id: 4,
          name: 'Jardin',
          active: ModalState.data.garden,
        },
      ])

      setForm({
        plotMeters: {
          name: String(ModalState.data.plotMeters),
          error: false,
        },
        useMeters: {
          name: String(ModalState.data.usableMeters),
          error: false,
        },
      })

      setYear({
        value: {
          id: String(ModalState.data.yearConstruction),
          name: String(ModalState.data.yearConstruction),
        },
        error: false,
      })

      setMeters({
        value: {
          id: String(ModalState.data.constructedMeters),
          name: String(ModalState.data.constructedMeters),
        },
        error: false,
      })

      setEmissions({
        value: ModalState.data.Emission,
        error: false,
      })

      setConsumption({
        value: ModalState.data.Consumption,
        error: false,
      })

      setBedrooms({
        value: {
          id: String(ModalState.data.bedrooms),
          name: String(ModalState.data.bedrooms),
        },
        error: false,
      })

      setBathrooms({
        value: {
          id: String(ModalState.data.bathrooms),
          name: String(ModalState.data.bathrooms),
        },
        error: false,
      })
    }
  }, [ModalState.data])

  const changeSelect = (name: string, item: TAuctionSelectData) => {
    switch (name) {
      case 'year':
        setYear((prev) => ({ ...prev, value: item, error: false }))
        break
      case 'meters':
        setMeters((prev) => ({ ...prev, value: item, error: false }))
        break
      case 'emissions':
        setEmissions((prev) => ({ ...prev, value: item, error: false }))
        break
      case 'consumption':
        setConsumption((prev) => ({ ...prev, value: item, error: false }))
        break
      case 'bedrooms':
        setBedrooms((prev) => ({ ...prev, value: item, error: false }))
        break
      case 'bathrooms':
        setBathrooms((prev) => ({ ...prev, value: item, error: false }))
        break
    }
  }
  const checkboxList = checkList.map((item) => (
    <div className={styles.item} key={item.id}>
      <CheckBox isCheck={item.active} label={item.name} onChange={() => setCheckList((prev) => prev.map((el) => (item.id === el.id ? { ...el, active: !el.active } : el)))} />
    </div>
  ))
  const onSubmit = () => {
    const result = Object.entries(form).map(([key, value]) => [key, { name: value.name, error: value.name === '' ? true : false }])
    setForm(Object.fromEntries(result))
    setYear((prev) => ({ ...prev, error: prev.value === null ? true : false }))
    setMeters((prev) => ({ ...prev, error: prev.value === null ? true : false }))
    setEmissions((prev) => ({ ...prev, error: prev.value === null ? true : false }))
    setConsumption((prev) => ({ ...prev, error: prev.value === null ? true : false }))
    setBedrooms((prev) => ({ ...prev, error: prev.value === null ? true : false }))
    setBathrooms((prev) => ({ ...prev, error: prev.value === null ? true : false }))

    if (year.value !== null && meters.value !== null && form.plotMeters.name !== '' && form.useMeters.name !== '') {
      const data = {
        pool: checkList[0].active,
        garage: checkList[1].active,
        elevator: checkList[2].active,
        terrace: checkList[3].active,
        garden: checkList[4].active,
        yearConstruction: year.value.name,
        plotMeters: form.plotMeters.name,
        usableMeters: form.useMeters.name,
        constructedMeters: meters.value.name,
        Emission: emissions.value,
        Consumption: consumption.value,
        bedrooms: bedrooms.value?.name,
        bathrooms: bathrooms.value?.name,
      }

      ModalState.updateEditData(data)
      ModalState.setNextStep()
    }
  }
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.form}>
        <div className={styles.wrap}>
          <div className={styles.group}>
            <div className={styles.input}>
              <SelectField scroll label={'Año de construcción*'} placeholder={'Selecciona año'} list={yearData} selected={year.value} changeSelected={changeSelect} name="year" error={year.error} />
            </div>
            <div className={styles.input}>
              <SelectField
                scroll
                label={'Metros construidos*'}
                placeholder={'Introduce metros m2'}
                list={metersData}
                selected={meters.value}
                changeSelected={changeSelect}
                name="meters"
                error={meters.error}
              />
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.input}>
              <TextField
                type="number"
                label={'Metros parcela*'}
                name="plotMeters"
                error={form.plotMeters.error}
                value={form.plotMeters.name}
                placeholder={'Introduce metros m2'}
                onChange={changeForm}
              />
            </div>
            <div className={styles.input}>
              <TextField type="number" label={'Metros útiles*'} name="useMeters" error={form.useMeters.error} value={form.useMeters.name} placeholder={'Introduce metros m2'} onChange={changeForm} />
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.input}>
              <SelectField label={'Emisiones (CE)'} placeholder={'Seleccione rango'} list={emissionsData} selected={emissions.value} changeSelected={changeSelect} name="emissions" error={false} />
            </div>
            <div className={styles.input}>
              <SelectField label={'Consumo (CE)'} placeholder={'Seleccione rango'} list={consumptionData} selected={consumption.value} changeSelected={changeSelect} name="consumption" error={false} />
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.input}>
              <SelectField
                label={'Dormitorios'}
                placeholder={'Introduce número de habitaciones'}
                list={bedroomsData}
                selected={bedrooms.value}
                changeSelected={changeSelect}
                name="bedrooms"
                error={false}
              />
            </div>
            <div className={styles.input}>
              <SelectField label={'Baños'} placeholder={'Introduce número de baños'} list={bathroomsData} selected={bathrooms.value} changeSelected={changeSelect} name="bathrooms" error={false} />
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <h4 className={styles.title}>Características</h4>
          <div className={styles.list}>{checkboxList}</div>
        </div>
      </div>
      <div className={styles.navigation}>
        {step > 0 && (
          <span className={styles.prev} onClick={() => ModalState.setPrevStep()}>
            <RightArrowIcon />
            Volver al paso anterior
          </span>
        )}
        <span className={styles.next} onClick={onSubmit}>
          <Button color="blue">Continuar</Button>
        </span>
      </div>
    </div>
  )
})
