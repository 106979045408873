import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Button } from '../../../../components/Button/Button'
import { ElephantIcon, MinusIcon, PlusIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import { priceFormat } from '../../../../utils/price.utils'
import styles from './AuctionPrice.module.scss'
import AuctionState from '../../../../store/AuctionState'

type TProps = {
  startPrice: number
  handlerAddDeposit: (bid: number) => void
  handlerBind: (amount: number) => void
  isDeposit: boolean
  lastBid: number
  biddingTranche: number
}

export const AuctionPrice: FC<TProps> = observer(({ startPrice, handlerAddDeposit, isDeposit, handlerBind, lastBid, biddingTranche }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [value, setValue] = useState<number>(0)
  const bidMessages = AuctionState.bidMessage

  const onPlus = () => {
    setValue(value + biddingTranche)
  }

  const onMinus = () => {
    if (value - biddingTranche >= biddingTranche) {
      setValue(value - biddingTranche)
    }
  }

  useEffect(() => {
    setValue(biddingTranche)
  }, [])

  const handlerPujar = () => {
    if (isDeposit) {
      handlerBind(value)
    } else {
      handlerAddDeposit(value)
    }
  }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.content}>
        <div className={styles.price}>
          <h4 className={styles.title}>Estado de la subasta</h4>
          <div className={styles.start}>
            <span>Precio de salida</span>
            <span>{priceFormat(startPrice)}</span>
          </div>
          <div className={styles.last}>
            <span>Última puja</span>
            <span>{priceFormat(lastBid)}</span>
          </div>
        </div>
        <div className={styles.counter}>
          <h4 className={styles.title}>Importe a pujar</h4>
          <div className={styles.wrap}>
            <div className={styles.block}>
              <button className={styles.btn} onClick={onMinus}>
                <MinusIcon />
              </button>
              <span className={styles.value}>
                <span>+</span>
                <input type="text" value={value} />
                <span>€</span>
              </span>
              <button className={styles.btn} onClick={onPlus}>
                <PlusIcon />
              </button>
            </div>

            <div className={styles.btn}>
              <Button onClick={handlerPujar}>Pujar</Button>
            </div>
          </div>
          <p className={styles.text}>Pellentesque at tempor arcu. Nulla facilisi. Mauris id metus justo. Vestibulum eleifend porttitor dignissim. Vivamus ut elit eu quam consequat.</p>
          {bidMessages.length !== 0 && bidMessages.map(i => {
            return (
              <div key={i} className={styles.complete}>New bid made</div>
            )
          })}
          {isDeposit && (
            <div className={styles.deposit}>
              <ElephantIcon />
              Depósito constituido
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
