import { ChangeEvent, FC } from 'react'
import AppState from '../../store/AppState'
import styles from './Input.module.scss'

type TProps = {
  placeholder?: string
  value?: string
  name?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  iconLeft?: JSX.Element
  type?: 'email' | 'password' | 'text'
  color?: 'white' | 'grey'
}

export const Input: FC<TProps> = ({ value, placeholder, onChange, iconLeft, name, type = 'text', color = 'white' }) => {
  const id = String(Math.floor(Math.random() * 100000))
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      {iconLeft && (
        <label htmlFor={id} className={styles.iconLeft}>
          {iconLeft}
        </label>
      )}
      <input
        id={id}
        name={name}
        type={type}
        className={`${color === 'grey' ? styles.inputGrey : styles.inputWhite} ${iconLeft ? styles.inputLeftIcon : ''}`}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  )
}
