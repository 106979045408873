import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TAuctionObj, getPropertyStatusApi, getTypePropertyApi } from '../../../../../api/auctionApi'
import { getProvincesApi } from '../../../../../api/provinceApi'
import { TGetZipCodeObj, getZipCodeApi } from '../../../../../api/zipcodeApi'
import AppState from '../../../../../store/AppState'
import AuctionState from '../../../../../store/AuctionState'
import ModalState from '../../../../../store/ModalState'
import { Button } from '../../../../Button/Button'
import { RightArrowIcon, StopIcon } from '../../../../Icons/Icons'
import { SelectField } from '../../../../SelectField/SelectField'
import { TextField } from '../../../../TextField/TextField'
import styles from './ModalAuctionProperty.module.scss'

export type TAuctionFormInput = {
  name: string
  error: boolean
}
export type TAuctionSelectData = {
  id: string
  name: string
}
export type TAuctionSelectField = {
  value: TAuctionSelectData | null
  error: boolean
}
export type TPropertyForm = {
  address: TAuctionFormInput
  code: TAuctionFormInput
  cadastralReference: TAuctionFormInput
  city: TAuctionFormInput
}
export const ModalAuctionProperty: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const step = ModalState.step
  const [auction, setAuction] = useState<TAuctionObj | null>(null)
  const [error, setError] = useState(false)
  const [codes, setCodes] = useState<TGetZipCodeObj[] | null>(null)
  const [ref, setRef] = useState<number>(0)
  const [form, setForm] = useState<TPropertyForm>({
    address: {
      name: '',
      error: false,
    },
    code: {
      name: '',
      error: false,
    },
    cadastralReference: {
      name: '',
      error: false,
    },
    city: {
      name: '',
      error: false,
    },
  })
  const [propertyData, setPropertyData] = useState<TAuctionSelectData[]>([])

  const [property, setProperty] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  const [provinceData, setProvinceData] = useState<TAuctionSelectData[]>([])

  const [province, setProvince] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  const [statusData, setStatusData] = useState<TAuctionSelectData[]>([])

  const [status, setStatus] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })
  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'address' || name === 'code' || name === 'cadastralReference' || name === 'ref' || name === 'city') {
      setForm({ ...form, [name]: { name: e.currentTarget.value, error: !!true } })
    }

    /* codes?.some((item) => (item.code === form.code.name ? setError(false) : setError(true))) */
  }

  useEffect(() => {
    getTypePropertyApi().then((res) => {
      setPropertyData(res.data)
      if (AuctionState.obj.typePropertyId !== '') {
        const result = res.data.find((item) => item.id === AuctionState.obj.typePropertyId)
        setProperty({ value: result ? result : null, error: false })
      }
    })

    getPropertyStatusApi().then((res) => {
      setStatusData(res.data)
      if (AuctionState.obj.propertyStatusId !== '') {
        const result = res.data.find((item) => item.id === AuctionState.obj.propertyStatusId)
        setStatus({ value: result ? result : null, error: false })
      }
    })

    getProvincesApi().then((res) => {
      setProvinceData(res.data)
      if (AuctionState.obj.provinceId !== '') {
        const result = res.data.find((item) => item.id === AuctionState.obj.provinceId)
        setProvince({ value: result ? result : null, error: false })
      }
    })

    getZipCodeApi({ take: 999999, skip: 0 }).then((res) => {
      console.log(res.data.zipCodes)
      setCodes(res.data.zipCodes)
    })
  }, [])

  useEffect(() => {
    if (codes) {
      if (codes.filter((i) => i.code === form.code.name && !i.active).length) {
        return setError(true)
      } else {
        setError(false)
      }
    }
  }, [form.code.name])

  useEffect(() => {
    if (ModalState.data) {
      setRef(ModalState.data.reference)
      setForm({
        address: {
          name: ModalState.data.address,
          error: false,
        },
        code: {
          name: ModalState.data.postcode,
          error: false,
        },
        cadastralReference: {
          name: ModalState.data.cadastralReference,
          error: false,
        },
        city: {
          name: ModalState.data.city,
          error: false,
        },
      })

      setProperty({ value: ModalState.data.TypeProperty, error: false })
      setProvince({ value: ModalState.data.Province, error: false })
      setStatus({ value: ModalState.data.PropertyStatus, error: false })
    }
  }, [ModalState.data])

  const changeSelect = (name: string, item: TAuctionSelectData) => {
    switch (name) {
      case 'property':
        setProperty((prev) => ({ ...prev, value: item, error: false }))
        break
      case 'province':
        setProvince((prev) => ({ ...prev, value: item, error: false }))
        break
      case 'status':
        setStatus((prev) => ({ ...prev, value: item, error: false }))
        break
    }
  }
  const onSubmit = () => {
    const result = Object.entries(form).map(([key, value]) => [key, { name: value.name, error: value.name === '' ? true : false }])
    setForm(Object.fromEntries(result))
    setProperty((prev) => ({ ...prev, error: prev.value === null ? true : false }))
    setProvince((prev) => ({ ...prev, error: prev.value === null ? true : false }))
    setStatus((prev) => ({ ...prev, error: prev.value === null ? true : false }))

    if (property.value !== null && form.address.name !== '' && form.code.name !== '') {
      const data = {
        address: form.address.name,
        postcode: form.code.name,
        cadastralReference: form.cadastralReference.name,
        city: form.city.name,
        TypeProperty: property.value,
        Province: province.value,
        PropertyStatus: status.value,
      }

      ModalState.updateEditData(data)
      ModalState.setNextStep()
    }
  }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      {error && (
        <div className={styles.top}>
          <div className={styles.left}>
            <StopIcon />
            <span className={styles.text}>¡Upps! El código postal introducido no está disponible</span>
          </div>
          <div className={styles.right}>
            <Link to="/" className={styles.text}>
              Contactar con el administrador
            </Link>
          </div>
        </div>
      )}
      <div className={styles.form}>
        <div className={styles.group}>
          <div className={styles.input}>
            <TextField label={'Referencia'} name="ref" error={false} value={String(ref)} placeholder={'Referencia'} onChange={changeForm} />
          </div>
          <div className={styles.input}>
            <SelectField
              scroll
              label={'Tipo inmueble*'}
              placeholder={'Selecciona tu país'}
              list={propertyData}
              selected={property.value}
              changeSelected={changeSelect}
              name="property"
              error={property.error}
            />
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.input}>
            <TextField label={'Dirección*'} name="address" error={form.address.error} value={form.address.name} placeholder={'Intoduce dirección'} onChange={changeForm} />
          </div>
          <div className={styles.input}>
            <TextField label={'Ciudad'} name="city" error={form.city.error} value={form.city.name} placeholder={'Intoduce ciudad'} onChange={changeForm} />
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.input}>
            <SelectField scroll label={'Provincia'} placeholder={'Selecciona provincia'} list={provinceData} selected={province.value} changeSelected={changeSelect} name="province" error={false} />
          </div>
          <div className={styles.input}>
            <TextField type="number" label={'Código postal*'} name="code" error={form.code.error} value={form.code.name} placeholder={'Introduce código postal'} onChange={changeForm} />
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.input}>
            <TextField label={'Referencia catastral'} name="cadastralReference" error={false} value={form.cadastralReference.name} placeholder={'Introduce referencia catastral'} onChange={changeForm} />
          </div>
          <div className={styles.input}>
            <SelectField
              scroll
              label={'Estado inmueble'}
              placeholder={'Selecciona estado inmueble'}
              list={statusData}
              selected={status.value}
              changeSelected={changeSelect}
              name="status"
              error={false}
            />
          </div>
        </div>
      </div>
      <div className={styles.navigation}>
        {step > 0 && (
          <span className={styles.prev} onClick={() => AuctionState.setPrevStep()}>
            <RightArrowIcon />
            Volver al paso anterior
          </span>
        )}
        {error ? (
          <span className={styles.next}>
            <Button color="gray">Continuar</Button>
          </span>
        ) : (
          <span className={styles.next} onClick={onSubmit}>
            <Button color="blue">Continuar</Button>
          </span>
        )}
      </div>
    </div>
  )
})
