import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import img2 from '../../assets/mainPage/rates-img1.png'
import img3 from '../../assets/mainPage/rates-img3.png'
import img1 from '../../assets/mainPage/sliderImg.png'
import AppState from '../../store/AppState'
import styles from './Sidebar.module.scss'
import { SidebarItem, TSidebarItemProps } from './SidebarItem/SidebarItem'

const data: TSidebarItemProps[] = [
  {
    id: 1,
    img: img1,
    title: 'Chalet pareado',
    location: 'Majadahonda, Madrid ',
    price: 230000,
    square: 150,
    places: 8,
    showers: 6,
  },
  {
    id: 2,
    img: img2,
    title: 'Chalet pareado',
    location: 'Majadahonda, Madrid ',
    price: 230000,
    square: 150,
    places: 8,
    showers: 6,
  },
  {
    id: 3,
    img: img3,
    title: 'Chalet pareado',
    location: 'Majadahonda, Madrid ',
    price: 230000,
    square: 150,
    places: 8,
    showers: 6,
  },
]

type TSidebarProps = {
  title: string
}
export const Sidebar: FC<TSidebarProps> = observer(({ title }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const list = data.map((item) => <SidebarItem item={item} key={item.id} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.list}>{list}</div>
    </div>
  )
})
