import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import accountUserCreated from '../../../../assets/authPage/accountUserCreated.svg'
import { Button } from '../../../../components/Button/Button'
import { ExitIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import styles from './AccountUserCreated.module.scss'

type TProps = {
  closeAccountUserCreated: () => void
}

export const AccountUserCreated: FC<TProps> = observer(({ closeAccountUserCreated }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <div className={styles.exit} onClick={closeAccountUserCreated}>
        <ExitIcon />
      </div>
      <h1 className={styles.title}>Cuenta creada correctamente</h1>
      <img className={styles.image} src={accountUserCreated} alt={'accountUserCreated'} />

      <p className={styles.text}>Estamos encantados de tenerte por aqui</p>
      <p className={styles.description}>¡Muchas gracias por confiar en Subasta tu casa!</p>

      <div className={styles.button}>
        <Button onClick={closeAccountUserCreated}>Ir al inicio</Button>
      </div>
    </div>
  )
})
