import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useState } from 'react'
import { loginApi } from '../../../../api/authApi'
import { Button } from '../../../../components/Button/Button'
import { CheckBox } from '../../../../components/CheckBox/CheckBox'
import { LockIcon, MailIcon } from '../../../../components/Icons/Icons'
import { Input } from '../../../../components/Input/Input'
import { setAuthLocaleStorage } from '../../../../services/localStorageServices/authLocalStorageService'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import styles from './SignInForm.module.scss'
import AuctionState from "../../../../store/AuctionState";

export type TFormSignIn = { email: string; password: string; isCheck: boolean }

type TProps = {
  changeForgot: (value: boolean) => void
  handlerForm: () => void
}

export const SignInForm: FC<TProps> = observer(({ changeForgot, handlerForm }) => {
  const [form, setForm] = useState<TFormSignIn>({ email: '', password: '', isCheck: false })
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  })
  const [error, setError] = useState(false)
  const changeCheck = () => {
    setForm({ ...form, isCheck: !form.isCheck })
  }

  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'email' || name === 'password') {
      setForm({ ...form, [name]: e.currentTarget.value })
    }
  }

  const handlerLogin = async () => {
    loginApi({ email: form.email, password: form.password })
      .then((res) => {
        setAuthLocaleStorage(res.data.accessToken)
        AuthState.setAccessToken(res.data.accessToken)
        AuthState.setUser(res.data.user)
        AuctionState.resetObj()
        if (res.data.user.type === 'ADMIN') {
          return AuthState.setIsAuth(true)
        }
        if ((res.data.user.ProfessionalPlan && res.data.user.ProfessionalPlan.active === true) || res.data.user.ParticularData) {
          return AuthState.setIsAuth(true)
        }
      })
      .catch((err) => {
        setErrors({ email: false, password: false })
        setError(false)
        if (err.response.data.errors) {
          const len = err.response.data.errors
          len.forEach((item: any) => {
            if (item.param === 'email') {
              setErrors((prev) => ({ ...prev, email: item.param === 'email' ? true : false }))
            }

            if (item.param === 'password') {
              setErrors((prev) => ({ ...prev, password: item.param === 'password' ? true : false }))
            }
          })
        }

        if (err.response.data.message) {
          setError(true)
        }
      })
  }
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <div className={`${styles.email} ${errors.email ? styles.error : ''}`}>
        <Input color={'grey'} iconLeft={<MailIcon />} type={'email'} placeholder={'Email'} name={'email'} value={form.email} onChange={changeForm} />
      </div>
      <div className={`${styles.password} ${errors.password ? styles.error : ''}`}>
        <Input color={'grey'} iconLeft={<LockIcon />} type={'password'} placeholder={'Contraseña'} name={'password'} value={form.password} onChange={changeForm} />
      </div>
      <div className={styles.errors}>{error && 'Not found email or password'}</div>
      <div className={styles.checkBox}>
        <CheckBox isCheck={form.isCheck} label={'Recordar contraseña'} onChange={changeCheck} />
      </div>
      <div className={styles.button}>
        <Button onClick={handlerLogin}>Iniciar sesión</Button>
      </div>
      <div className={styles.forgot} onClick={() => changeForgot(true)}>
        <span>Olvidé mi contraseña</span>
      </div>
      {isMobile && (
        <div className={styles.forgot} onClick={handlerForm}>
          <span>¿Aún no tienes cuenta?</span>
        </div>
      )}
    </div>
  )
})
