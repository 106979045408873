import { observer } from 'mobx-react-lite'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MainLayout } from '../../components/Layout/MainLayout'
import { AccessPage } from '../../pages/AccessPage/AccessPage'
import { AuctionPage } from '../../pages/AuctionPage/AuctionPage'
import { AuthPage } from '../../pages/AuthPage/AuthPage'
import { BidsPage } from '../../pages/BidsPage/BidsPage'
import { ChangePasswordPage } from '../../pages/ChangePasswordPage/ChangePasswordPage'
import { DepositsPage } from '../../pages/DepositsPage/DepositsPage'
import { EstatePage } from '../../pages/EstatePage/EstatePage'
import { MainPage } from '../../pages/MainPage/MainPage'
import { MyAuctionsPage } from '../../pages/MyAuctionsPage/MyAuctionsPage'
import { MyBidsPage } from '../../pages/MyBidsPage/MyBidsPage'
import { MyDepositsPage } from '../../pages/MyDepositsPage/MyDepositsPage'
import { MySearchesPage } from '../../pages/MySearchesPage/MySearchesPage'
import { NewAuctionPage } from '../../pages/NewAuctionPage/NewAuctionPage'
import { PostcodesPage } from '../../pages/PostcodesPage/PostcodesPage'
import { RolesPage } from '../../pages/RolesPage/RolesPage'
import { ServicesPage } from '../../pages/ServicesPage/ServicesPage'
import { UsersPage } from '../../pages/UsersPage/UsersPage'
import AuthState from '../../store/AuthState'

export const MAIN_PATH = '/'
export const AUTH_PATH = '/auth'
export const USERS_PATH = '/users'
export const DEPOSITS_PATH = '/deposits'
export const POSTCODES_PATH = '/postcodes'
export const CHANGE_PASSWORD_PATH = '/change-password'
export const AUCTIONS_PATH = '/auctions'
export const SINGLE_AUCTIONS_PATH = '/auction/:id'
export const MY_AUCTIONS_PATH = '/my-auctions'
export const NEW_AUCTION_PATH = '/new-auction'
export const MY_SEARCHES_PATH = '/my-searches'
export const MY_DEPOSITS_PATH = '/my-deposits'
export const MY_BIDS_PATH = '/my-bids'
export const BIDS_PATH = '/bids'
export const MY_SERVICES_PATH = '/services'
export const ROLES_PATH = '/roles'
export const ACCESS_PATH = '/access'

type TRoute = { path: string; element: JSX.Element; layout?: JSX.Element; isAuth: boolean; isNoAuth: boolean }

const routes: TRoute[] = [
  { path: MAIN_PATH, element: <MainPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: USERS_PATH, element: <UsersPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: AUCTIONS_PATH, element: <EstatePage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: DEPOSITS_PATH, element: <DepositsPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: BIDS_PATH, element: <BidsPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: POSTCODES_PATH, element: <PostcodesPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: SINGLE_AUCTIONS_PATH, element: <AuctionPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: MY_AUCTIONS_PATH, element: <MyAuctionsPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: NEW_AUCTION_PATH, element: <NewAuctionPage />, isAuth: true, isNoAuth: false },
  { path: MY_SEARCHES_PATH, element: <MySearchesPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: MY_DEPOSITS_PATH, element: <MyDepositsPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: MY_BIDS_PATH, element: <MyBidsPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: MY_SERVICES_PATH, element: <ServicesPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: ROLES_PATH, element: <RolesPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: ACCESS_PATH, element: <AccessPage />, layout: <MainLayout />, isAuth: true, isNoAuth: false },
  { path: AUTH_PATH, element: <AuthPage />, isAuth: false, isNoAuth: true },
  { path: CHANGE_PASSWORD_PATH, element: <ChangePasswordPage />, isAuth: false, isNoAuth: false },

  { path: '*', element: <Navigate to={MAIN_PATH} />, isAuth: true, isNoAuth: false },
  { path: '*', element: <Navigate to={AUTH_PATH} />, isAuth: false, isNoAuth: true },
]

export const RoutesService = observer(() => {
  const isAuth = AuthState.isAuth
  const isLayout = (obj: TRoute) =>
    obj.layout ? (
      <Route key={obj.path} element={obj.layout}>
        <Route key={obj.path} path={obj.path} element={obj.element} />
      </Route>
    ) : (
      <Route key={obj.path} path={obj.path} element={obj.element} />
    )

  return <Routes>{isAuth ? routes.filter((i) => i.isAuth).map((i) => isLayout(i)) : routes.filter((i) => i.isNoAuth).map((i) => isLayout(i))}</Routes>
})
