import { FC, useEffect, useState } from 'react'
import { addPlanProfessionalApi } from '../../../../api/authApi'
import { getPlansApi, TPlan } from '../../../../api/planApi'
import { Button } from '../../../../components/Button/Button'
import { ExitIcon, FavoriteIcon, PlanIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import styles from './SelectPlan.module.scss'

type TProps = {
  closeSelectPlan: () => void
  setOrderForm: () => void
}

export const SelectPlan: FC<TProps> = ({ closeSelectPlan, setOrderForm }) => {
  const [plans, setPlans] = useState<TPlan[]>([])

  useEffect(() => {
    getPlansApi()
      .then((res) => {
        setPlans(res.data)
      })
      .catch((err) => {
        console.log(err.response.data)
      })
  }, [])

  const getAuctionsText = (limit: number) => {
    if (limit === 1) {
      return ['Subasta', '1 única propiedad']
    }
    if (limit > 1 && limit < 1000) {
      return ['Subasta de', `1 a ${limit} propiedades`]
    }
    if (limit >= 1000) {
      return ['Subastas', 'ilimitadas']
    }
    return ['', '']
  }

  const handlerSelectedPlan = async (planId: string) => {
    addPlanProfessionalApi({ planId })
      .then((res) => {
        AuthState.setUser(res.data)
        setOrderForm()
      })
      .catch((err) => {
        console.log(err.response.data)
      })
  }
  const width = AppState.widthWindow
  const isMobile = width <= 768

  const items = plans.map((i) => {
    return (
      <div key={i.id} className={styles.card}>
        <div className={styles.header}>
          <div className={styles.icon}>
            <PlanIcon />
          </div>
          <p className={styles.name}>{i.name}</p>
        </div>
        <div className={styles.priceBlock}>
          <p className={styles.price}>
            {String(i.price).split('.')[0]},<span className={styles.penny}>{String(i.price).split('.').length >= 2 ? String(i.price).split('.')[1] : '00'}€</span>
            <span className={styles.priceDescription}> (IVA incluido)</span>
          </p>
        </div>
        <p className={styles.payment}>{i.payment === 'ONE_TIME_PAYMENT' ? 'Pago único' : i.payment === 'MONTHLY_PAYMENT' ? i.description : ''}</p>
        <p className={styles.auctions}>
          {getAuctionsText(i.limit)[0]}&nbsp;
          <span className={styles.black}>{getAuctionsText(i.limit)[1]}</span>
        </p>
        <div className={styles.button}>
          <Button onClick={() => handlerSelectedPlan(i.id)}>Quiero este plan</Button>
        </div>
        {i.favorite && (
          <div className={styles.favorite}>
            <FavoriteIcon />
          </div>
        )}
      </div>
    )
  })

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <div className={styles.exit} onClick={closeSelectPlan}>
        <ExitIcon />
      </div>

      <h1 className={styles.title}>Elije tu plan de pago</h1>

      <div className={styles.cards}>{items}</div>
    </div>
  )
}
