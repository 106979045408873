import { apiService } from '../services/apiServices/apiService'
import { TParticularData, TProfessionalData } from './usersApi'

type TAccessPropsApi = {
  role: 'PROFESSIONAL' | 'PARTICULAR'
  take: number
  skip: number
}
export type TAccessObj = {
  ParticularData: TParticularData
  ProfessionalData: TProfessionalData
  accessControl: string
  id: string
}
type TAccessApi = {
  count: number
  users: TAccessObj[]
}

export const getAccessApi = async (payload: TAccessPropsApi) => {
  return await apiService<TAccessApi>('get', 'user/getAccessControl', payload)
}
