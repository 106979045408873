import { FC, useEffect, useState } from 'react'
import { TAuctionObj, getMyAuctionAllApi } from '../../../../api/auctionApi'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import styles from './MainAuction.module.scss'
import { MainAuctionItem } from './MainAuctionItem/MainAuctionItem'
export const MainAuction: FC = () => {
	const [items, setItems] = useState<TAuctionObj[]>([])
	const width = AppState.widthWindow
	const isMobile = width <= 768
	useEffect(() => {
		getMyAuctionAllApi({ role: AuthState.user?.type === 'PARTICULAR' ? 'PARTICULAR' : 'PROFESSIONAL', take: 3, skip: 0 })
			.then((res) => {
				setItems(res.data.auctions)
			})
			.catch((err) => console.log(err))
	}, [])
	const list = items.map((item) => <MainAuctionItem key={item.id} item={item} />)
	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<h3 className={styles.title}>Mis subastas</h3>
			<div className={styles.list}>{list}</div>
		</div>
	)
}
