import { observer } from 'mobx-react-lite'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { TAuctionObj, getAuctionAllApi } from '../../api/auctionApi'
import { FilterBlock } from '../../components/FilterBlock/FilterBlock'
import { Pagination } from '../../components/Pagination/Pagination'
import { Switch } from '../../components/Switch/Switch'
import AppState from '../../store/AppState'
import AuthState from '../../store/AuthState'
import styles from './EstatePage.module.scss'
import { EstateItem } from './components/EstateItem/EstateItem'
import SearchState from '../../store/SearchState'
import UserState from '../../store/UserState'


const dataSwitch: string[] = ['Todas', 'Pte. Validar', 'Validadas', 'Adjudicadas']
const dataFilter: string[] = ['Data 1', 'Data 2', 'Data 3', 'Data 4', 'Data 5']


export const EstatePage: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const isAdmin = AuthState.user?.type === 'ADMIN'
  const [isActive, setIsActive] = useState(0)
  const [value, setValue] = useState('')
  const [items, setItems] = useState<TAuctionObj[]>([])
  const [len, setLen] = useState(0)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage] = useState(10)
  const isSearch = SearchState.isSearch

  useEffect(() => {
    if (!isAdmin) {
      UserState.changeFirstSignIn()
    }
  }, [])

  useEffect(() => {
   if (isSearch) {
     setItems([])
     setItems(SearchState.auctions)
   } else {
     setItems([])
     const getWhich = (): 'all' | 'underConsideration' | 'approved' | 'rejected' | 'final' | undefined => {
       if (!isAdmin) {
         return undefined
       }
       if (isActive === 0) {
         return 'all'
       }
       if (isActive === 1) {
         return 'underConsideration'
       }
       if (isActive === 2) {
         return 'approved'
       }
       if (isActive === 3) {
         return 'final'
       }
     }

     getAuctionAllApi({ take: postsPerPage, skip: count, which: getWhich() })
       .then((res) => {
         setLen(res.data.count)
         setItems(res.data.auctions)
       })
       .catch((err) => console.log(err))
   }
  }, [count, isSearch, isActive])

  const paginates = (pageNumber: SetStateAction<number>) => {
    setCurrentPage(pageNumber)
    setCount(postsPerPage * Number(pageNumber))
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const previousPage = () => {
    if (currentPage >= 0) {
      setCurrentPage((prev) => prev - 1)
      setCount((prev) => prev - postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const nextPage = () => {
    if (currentPage !== Math.ceil(len / postsPerPage)) {
      setCurrentPage((prev) => prev + 1)
      setCount((prev) => prev + postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const variableItems = isSearch ? SearchState.auctions : items

  const list = items.map((item, i) => <EstateItem item={item} key={i}></EstateItem>)
  const list1 = items.map((item, i) => <EstateItem isAdmin item={item} key={i}></EstateItem>)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.top}>{isAdmin ? <FilterBlock items={dataFilter} title="Subastas" full /> : <FilterBlock items={dataFilter} title="Propiedades en subasta" />}</div>
      {isAdmin && (
        <div className={styles.switch}>
          <Switch items={dataSwitch} index={isActive} onChange={(i) => setIsActive(i)} />
        </div>
      )}
      <div className={styles.estate}>
        {isAdmin ? <div className={styles.list}>{list1}</div> : <div className={styles.list}>{list}</div>}
        {len > postsPerPage && (
          <div className={styles.pagination}>
            <Pagination postsPerPage={postsPerPage} totalPosts={len} paginate={paginates} previousPage={previousPage} nextPage={nextPage} currentPage={currentPage} />
          </div>
        )}
      </div>
    </div>
  )
})
