import axios from 'axios'
import { urlApi } from '../../config/apiConfig'
import AuthState from '../../store/AuthState'

type TMethods = 'get' | 'post' | 'patch' | 'delete'

export const apiService = async <T = any>(method: TMethods, url: string, body: object = {}, config: object = {}) => {
  if (method === 'get') {
    return axios.get<T>(urlApi + url, {
      params: body,
      headers: {
        Authorization: `Bearer ${AuthState.accessToken}`,
      },
    })
  }
  if (method === 'post') {
    return axios.post<T>(urlApi + url, body, {
      ...config,
      headers: {
        Authorization: `Bearer ${AuthState.accessToken}`,
      },
    })
  }
  if (method === 'patch') {
    return axios.patch<T>(urlApi + url, body, {
      ...config,
      headers: {
        Authorization: `Bearer ${AuthState.accessToken}`,
      },
    })
  }
  if (method === 'delete') {
    return axios.delete<T>(urlApi + url, {
      data: body,
      headers: {
        Authorization: `Bearer ${AuthState.accessToken}`,
      },
    })
  }
  return axios.request<T>({})
}
