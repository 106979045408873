import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { TAuctionObj } from '../../../api/auctionApi'
import { TUsersObj, getUserByIdApi } from '../../../api/usersApi'
import { HomeIconHover } from '../../../components/Icons/Icons'
import { urlApi } from '../../../config/apiConfig'
import AppState from '../../../store/AppState'
import { dateFormatFull } from '../../../utils/date.utils'
import { priceFormat } from '../../../utils/price.utils'
import styles from './DepositsItem.module.scss'

export const DepositsItem: FC<{ item: TAuctionObj }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [user, setUser] = useState<TUsersObj | null>(null)
  useEffect(() => {
    getUserByIdApi(item.userId).then((res) => setUser(res.data))
  }, [])
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.wrap}>
        <div className={styles.img} style={(item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id) ? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})` } : undefined}></div>
        <div className={styles.text}>
          <h4 className={styles.title}>{item.TypeProperty && item.TypeProperty.name}</h4>
          <span className={styles.ref}>Referencia: {item.reference}</span>
        </div>
      </div>
      <div className={styles.wrap}>
        {item.auctionStartingPrice <= 125000 && <span className={`${styles.status} ${styles.green}`}>ActivA</span>}
        {item.auctionStartingPrice > 125000 && <span className={`${styles.status} ${styles.yellow}`}>Revisión</span>}
        <div className={styles.user}>
          {user?.type === 'PROFESSIONAL' && (
            <>
              <div className={styles.prof}>
                <HomeIconHover />
              </div>
              <div className={styles.content}>
                <h3 className={styles.title}>{user.ProfessionalData?.company}</h3>
                <span className={styles.text}>{user.ProfessionalData?.contactPerson}</span>
              </div>
            </>
          )}
          {user?.type === 'PARTICULAR' && (
            <>
              <div className={styles.part}>
                {/*{user.ParticularData && user.ParticularData?.firstSurname + user.ParticularData?.secondSurname}*/}
                {user.ParticularData && `${user.ParticularData.firstSurname.length !== 0 && user.ParticularData.firstSurname[0]}${user.ParticularData.secondSurname.length !== 0 && user.ParticularData.secondSurname[0]}`}
              </div>
              <div className={styles.content}>
                <h3 className={styles.title}>
                  {user.ParticularData?.firstSurname} {user.ParticularData?.secondSurname}
                </h3>
                <span className={styles.text}>{user.type}</span>
              </div>
            </>
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.block}>
            <h4 className={styles.title}>Constituido</h4>
            <span className={styles.text}>Estado depósito</span>
          </div>
          <div className={styles.block}>
            <h4 className={styles.title}>{dateFormatFull(item.createdAt)}</h4>
            <span className={styles.text}>Fecha depósito</span>
          </div>
          <div className={styles.block}>
            <h4 className={styles.title}>{priceFormat(item.auctionStartingPrice)}</h4>
            <span className={styles.text}>Cantidad</span>
          </div>
        </div>
      </div>
    </div>
  )
})
