import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { FreeMode, Navigation, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { Swiper, SwiperSlide } from 'swiper/react'
import { TAuctionImages, TAuctionLinkVideo } from '../../../../api/auctionApi'
import { PlaySmallIcon, SliderNextArrowIcon, SliderPrevArrowIcon } from '../../../../components/Icons/Icons'
import { urlApi } from '../../../../config/apiConfig'
import AppState from '../../../../store/AppState'
import ModalState from '../../../../store/ModalState'
import styles from './AuctionGallery.module.scss'
type TAuctionArray = {
  type: 'img' | 'video'
  id: string
  link?: string
}
export const AuctionGallery: FC<{ images: TAuctionImages[]; video: TAuctionLinkVideo[] }> = observer(({ images, video }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)
  const onChange = (obj: TAuctionArray) => {
    if (obj.type === 'video') {
      ModalState.setModalVideo({
        type: 'video',
        img: obj.id,
        linkVideo: obj.link,
        show: true,
      })
    }
  }
  const imageList = images.map((item) => (
    <SwiperSlide key={item.id}>
      <span className={styles.img} style={item.id ? { backgroundImage: `url(${urlApi}auction/media?id=${item.id})`} : undefined}></span>
    </SwiperSlide>
  ))
  const thumbsArray: TAuctionArray[] = [...images, ...video].map((item) => (!item.hasOwnProperty('link') ? { type: 'img', ...item } : { type: 'video', ...item }))

  const thumbsList = thumbsArray.map((item) => (
    <SwiperSlide key={item.id}>
      <span className={styles.thumb} style={item.id ? { backgroundImage: `url(${urlApi}auction/media?id=${item.id})` } : undefined}>
        {item.type === 'video' && (
          <div className={styles.overlay} onClick={() => onChange(item)}>
            <span className={styles.play}>
              <PlaySmallIcon />
            </span>
            <span className={styles.text}>video</span>
          </div>
        )}
      </span>
    </SwiperSlide>
  ))
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.img}>
        <Swiper
          spaceBetween={10}
          navigation={{
            nextEl: `.${styles.next}`,
            prevEl: `.${styles.prev}`,
            disabledClass: `${styles.disabled}`,
          }}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className={styles.slider}
        >
          {imageList}
        </Swiper>
        <div className={`swiper-button ${styles.prev}`}>
          <SliderPrevArrowIcon />
        </div>
        <div className={`swiper-button ${styles.next}`}>
          <SliderNextArrowIcon />
        </div>
      </div>
      <div className={styles.thumb}>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={isMobile ? 15 : 25}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className={styles.thumbs}
        >
          {thumbsList}
        </Swiper>
      </div>
    </div>
  )
})
