import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useState } from 'react'
import { registrationUserApi } from '../../../../api/authApi'
import { Button } from '../../../../components/Button/Button'
import { LockIcon, MailIcon } from '../../../../components/Icons/Icons'
import { Input } from '../../../../components/Input/Input'
import { setAuthLocaleStorage } from '../../../../services/localStorageServices/authLocalStorageService'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import { TFormName } from '../../AuthPage'
import styles from './SignUpForm.module.scss'

type TFormSignUp = { email: string; password: string }

type TProps = {
  changeActiveForm: (nameForm: TFormName) => void
}

export const SignUpForm: FC<TProps> = observer(({ changeActiveForm }) => {
  const [form, setForm] = useState<TFormSignUp>({ email: '', password: '' })
  const [error, setError] = useState(false)
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  })
  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'email' || name === 'password') {
      setForm({ ...form, [name]: e.currentTarget.value })
    }
  }

  const registrationHandler = async () => {
    registrationUserApi({ email: form.email, password: form.password })
      .then((res) => {
        setAuthLocaleStorage(res.data.accessToken)
        AuthState.setAccessToken(res.data.accessToken)
        AuthState.setUser(res.data.user)
        console.log(res)
      })
      .catch((err) => {
        setErrors({ email: false, password: false })
        const len = err.response.data.errors

        if (err.response.data.message === 'Email is already registered') {
          setError(true)
        }

        len.forEach((item: any) => {
          if (item.msg === 'No valid email') {
            setErrors((prev) => ({ ...prev, email: item.value === '' ? true : false }))
          }

          if (item.msg === 'No valid password') {
            setErrors((prev) => ({ ...prev, password: item.value === '' ? true : false }))
          }
        })
      })
  }
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <div className={`${styles.email} ${errors.email ? styles.error : ''}`}>
        <Input color={'grey'} iconLeft={<MailIcon />} type={'email'} placeholder={'Email'} name={'email'} value={form.email} onChange={changeForm} />
      </div>
      <div className={`${styles.password} ${errors.password ? styles.error : ''}`}>
        <Input color={'grey'} iconLeft={<LockIcon />} type={'password'} placeholder={'Contraseña'} name={'password'} value={form.password} onChange={changeForm} />
        {error && <span className={styles.error}>Este email ya ha sido registrado</span>}
      </div>
      <div className={styles.errors}>
        {errors.email && !errors.password && 'No valid email'}
        {errors.password && !errors.email && 'No valid password'}
        {errors.email && errors.password && 'No valid email or password'}
      </div>
      <div className=""></div>
      <div className={styles.button}>
        <Button onClick={registrationHandler}>Crear cuenta</Button>
      </div>
      <div className={styles.signIn}>
        <span onClick={() => changeActiveForm('signIn')}>Iniciar sesión</span>
      </div>
    </div>
  )
})
