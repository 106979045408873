import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import Iframe from 'react-iframe'
import ModalState from '../../../store/ModalState'
import { FADE_IN } from '../../../utils/animation/fade'
import styles from './ModalMap.module.scss'

export const ModalMap: FC = observer(() => {
  const { link, show } = ModalState.modalMap
  const [thumb, setThumb] = useState(true)

  const onClose = () => {
    setThumb(false)
    ModalState.hideModalMap()
  }

  return (
    <motion.div {...FADE_IN} exit={{ opacity: 0 }} className={styles.root}>
      <div className={`${styles.content} ${thumb ? styles.active : ''}`}>
        <div className={styles.img}>
          <Iframe url={`${link}`} width="100%" height="100%" styles={{ border: 0 }} className={styles.iframe} />
          <button className={styles.close} onClick={onClose}></button>
        </div>
      </div>
    </motion.div>
  )
})
