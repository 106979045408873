import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { TAuctionObj } from '../../../../../api/auctionApi'
import { DownIcon } from '../../../../../components/Icons/Icons'
import { Timer } from '../../../../../components/Timer/Timer'
import { urlApi } from '../../../../../config/apiConfig'
import AppState from '../../../../../store/AppState'
import { dateFormatFull } from '../../../../../utils/date.utils'
import { priceFormat } from '../../../../../utils/price.utils'
import styles from './UsersAuctionItem.module.scss'

export const UsersAuctionItem: FC<{ item: TAuctionObj }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const arr = item.AuctionMedia

  const getLastBid = () => {
    let amount = item.auctionStartingPrice

    for (let i = 0; i < item.Bid.length; i++) {
      amount = amount + item.Bid[i].bid
    }
    return amount
  }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      {item.AuctionMedia.length !== 0 ? (
        <div className={styles.img} style={(item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id) ? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})` } : undefined}></div>
      ) : (
        <div className={styles.img}></div>
      )}
      <div className={styles.text}>
        <h4 className={styles.title}>{item.TypeProperty ? item.TypeProperty.name : 'Empty'}</h4>
        <span className={styles.ref}>Referencia: {item.reference}</span>
      </div>
      <div className={styles.info}>
        <div className={styles.block}>
          <h4 className={styles.title}>{dateFormatFull(item.createdAt)}</h4>
          <span className={styles.text}>Fecha de alta</span>
        </div>
        <div className={styles.block}>
          <h4 className={styles.title}>{priceFormat(item.auctionStartingPrice)}</h4>
          <span className={styles.text}>Precio de salida</span>
        </div>
        <div className={styles.block}>
          <h4 className={styles.title}>{priceFormat(item.Bid.length !== 0 ? getLastBid() : 0)}</h4>
          <span className={styles.text}>Última puja</span>
        </div>
      </div>
      {item.status === 'APPROVED' && <span className={`${styles.status} ${styles.green}`}>ActivA</span>}
      {item.status === 'UNDER_CONSIDERATION' && <span className={`${styles.status} ${styles.yellow}`}>Revisión</span>}
      {item.status === 'REJECTED' && <span className={`${styles.status} ${styles.red}`}>cerrada</span>}
      <div className={styles.timer}>
        <Timer date={new Date(item.endAuction)} size="small" />
      </div>
      <Link to={`/auction/${item.id}`} className={styles.link}>
        <DownIcon />
      </Link>
    </div>
  )
})
