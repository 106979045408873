import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import img from '../../../../assets/other/complete-auction.png'
import { Button } from '../../../../components/Button/Button'
import { MY_SERVICES_PATH } from '../../../../services/mainServices/RoutesService'
import AppState from '../../../../store/AppState'
import AuctionState from '../../../../store/AuctionState'
import styles from './Complete.module.scss'

export const Complete: FC = observer(() => {
	const navigate = useNavigate()
	const width = AppState.widthWindow
	const isMobile = width <= 768

	const handlerNavigate = () => {
		/* e.preventDefault() */
		AuctionState.resetStep()
		navigate(MY_SERVICES_PATH)
	}

	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<h2 className={styles.title}>¡Nueva subasta creada!</h2>
			<img className={styles.img} src={img} alt="" />
			<p className={styles.text}>Nuestro equipo esta revisando que todo está correcto, en breve podrás verla en su panel </p>
			<p className={styles.subtitle}>¿Quieres añadir algún servicio adicional?</p>
			<div className={styles.bottom}>
				<div className={styles.btn}>
					<Button onClick={handlerNavigate}>Ver servicios adicionales</Button>
				</div>
				<Link to="/auctions" className={styles.link} onClick={handlerNavigate}>
					Ir a mis subastas
				</Link>
			</div>
		</div>
	)
})
