import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import ModalState from '../../../store/ModalState'
import { FADE_IN } from '../../../utils/animation/fade'
import { Button } from '../../Button/Button'
import { CloseIcon } from '../../Icons/Icons'
import styles from './ModalContact.module.scss'

export const ModalContact: FC = observer(() => {
  const isActive = ModalState.modalContact
  const [text, setText] = useState('')
  return (
    <motion.div {...FADE_IN} className={styles.root}>
      <div className={styles.content}>
        <button className={styles.close} onClick={() => ModalState.setModalContact()}>
          <CloseIcon />
        </button>
        <h3 className={styles.title}>Contacta con el propietario</h3>
        <textarea className={styles.textarea} value={text} onChange={(e) => setText(e.currentTarget.value)} placeholder="Escribe aquí lo que necesites."></textarea>
        <div className={styles.btn}>
          <Button>Enviar mensaje</Button>
        </div>
      </div>
    </motion.div>
  )
})
