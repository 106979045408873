import { FC, useState } from 'react'
import { HeartEmptyIcon, HeartFullIcon } from '../Icons/Icons'
import styles from './Favorites.module.scss'

export const Favorites: FC = () => {
  const [active, setActive] = useState(false)
  return (
    <div className={styles.root} onClick={() => setActive(!active)}>
      {active ? (
        <span className={styles.active}>
          <span className={styles.text}>Favorito </span>
          <HeartFullIcon />
        </span>
      ) : (
        <span className={styles.deactive}>
          <span className={styles.text}>Guardar como favorito </span>
          <HeartEmptyIcon />
        </span>
      )}
    </div>
  )
}
