import { FC } from 'react'
import AppState from '../../../../store/AppState'
import { priceFormat } from '../../../../utils/price.utils'
import styles from './AuctionStatus.module.scss'

export const AuctionStatus: FC<{ price: number }> = ({ price }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h4 className={styles.title}>Estado de la subasta</h4>
      <div className={styles.price}>
        <span className={styles.text}>Precio de salida</span>
        <span className={styles.value}>{priceFormat(price)}</span>
      </div>
    </div>
  )
}
