import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TAuctionFile, postAuctionApi } from '../../../../api/auctionApi'
import { Button } from '../../../../components/Button/Button'
import { DocumentsIcon, LocationIcon, PenIcon } from '../../../../components/Icons/Icons'
import { urlApi } from '../../../../config/apiConfig'
import AppState from '../../../../store/AppState'
import AuctionState from '../../../../store/AuctionState'
import AuthState from '../../../../store/AuthState'
import { dateFormat } from '../../../../utils/date.utils'
import { priceFormat } from '../../../../utils/price.utils'
import styles from './Preview.module.scss'

export const Preview: FC = observer(() => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const { obj, select, documents, images } = AuctionState
	const role = AuthState.user?.type

	const list = AuctionState.documents.map((item: TAuctionFile) => (
		<div className={styles.item}>
			<DocumentsIcon />
			{item.name}
		</div>
	))

	const handlerCreate = () => {
		if (role === 'PROFESSIONAL') {
			postAuctionApi(AuctionState.obj)
				.then((res) => console.log(res.data))
				.catch((err) => console.log(err))
			AuctionState.setNextStep()
			/* AuctionState.resetObj() */
		}

		if (role === 'PARTICULAR') {
			return AuctionState.setNextStep()
		}
		// если профи, то отправить на создание
		//
		// если партикулар , то как сейчсас
	}

	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<h2 className={styles.title}>Resumen de tu propiedad en subasta</h2>
			<div className={styles.content}>
				<div className={styles.left}>
					{images.length !== 0 && (
						<div
							className={styles.img}
							style={images.length !== 0 && images[0].id ? { backgroundImage: `url(${urlApi}auction/media?id=${images[0].id})` } : undefined}></div>
					)}
					<h3 className={styles.subtitle}>Características del inmueble</h3>
					<div className={styles.list}>
						<div className={styles.item}>
							<span className={styles.text}>Tipo vivienda</span>
							<span className={styles.value}>{select.property}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Estado vivienda</span>
							<span className={styles.value}>{select.status}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Metros útiles</span>
							<span className={styles.value}>
								{obj.usableMeters}m<sup>2</sup>
							</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Metros parcela</span>
							<span className={styles.value}>
								{obj.plotMeters}m<sup>2</sup>
							</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Dormitorios</span>
							<span className={styles.value}>{obj.bedrooms ? obj.bedrooms : 0}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Emisiones(CE)</span>
							<span className={styles.value}>{select.emission ? select.emission : '-'}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Consumo(CE)</span>
							<span className={styles.value}>{select.consumption ? select.consumption : '-'}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Baños</span>
							<span className={styles.value}>{obj.bathrooms ? obj.bathrooms : 0}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Año de construcción</span>
							<span className={styles.value}>{obj.yearConstruction}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Piscina</span>
							<span className={styles.value}>{obj.pool ? 'Sí' : 'No'}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Parking</span>
							<span className={styles.value}>{obj.garage ? 'Sí' : 'No'}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Terraza</span>
							<span className={styles.value}>{obj.terrace ? 'Sí' : 'No'}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Ascensor</span>
							<span className={styles.value}>{obj.elevator ? 'Sí' : 'No'}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Jardín</span>
							<span className={styles.value}>{obj.garden ? 'Sí' : 'No'}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.text}>Certificado enérgetico</span>
							<span className={styles.value}>{obj.emissionId || obj.consumptionId ? 'Sí' : 'No'}</span>
						</div>
					</div>
				</div>
				<div className={styles.right}>
					<span className={styles.ref}>Referencia: {obj.reference}</span>
					<h3 className={styles.name}>{select.property.length !== 0 ? `${select.property[0].toUpperCase()}${select.property.substr(1)}` : ''}</h3>
					<div className={styles.location}>
						<LocationIcon />
						<div className={styles.address}>
							{obj.address && <span>{obj.address},</span>}
							<br />
							{obj.postcode && <span>{obj.postcode},</span>}
							{select.province !== '' && <span>{select.province}, </span>}
							{obj.city !== '' && <span>{obj.city}. </span>}
						</div>
					</div>
					<div className={styles.block}>
						<div className={styles.date}>
							<span className={styles.value}>{dateFormat(Date.now())}</span>
							<span className={styles.text}>Fecha inicio</span>
						</div>
						<div className={styles.date}>
							<span className={styles.value}>{dateFormat(obj.endAuction)}</span>
							<span className={styles.text}>Fecha fin</span>
						</div>
						<div className={styles.price}>
							<span className={styles.value}>{priceFormat(obj.auctionStartingPrice)}</span>
							<span className={styles.text}>Precio de salida</span>
						</div>
					</div>
					<div className={styles.description}>
						<h3 className={styles.title}>Descripción</h3>
						<div className={styles.text}>{obj.description}</div>
					</div>
					<div className={styles.description}>
						<h3 className={styles.title}>Observaciones internas</h3>
						<div className={styles.text}>{obj.internalObservations}</div>
					</div>
					<div className={styles.documents}>
						<h3 className={styles.title}>Documentos adicionales</h3>
						<div className={styles.list}>{list}</div>
					</div>
				</div>
			</div>
			<div className={styles.bottom}>
				<span className={styles.link} onClick={() => AuctionState.setPrevStep()}>
					<PenIcon />
					Editar la subasta
				</span>
				<div className={styles.btn} onClick={handlerCreate}>
					<Button>Publicar subasta</Button>
				</div>
			</div>
		</div>
	)
})
