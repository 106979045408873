import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AppState from '../../../store/AppState'
import ModalState from '../../../store/ModalState'
import { FADE_IN } from '../../../utils/animation/fade'
import { ExitIcon } from '../../Icons/Icons'
import styles from './ModalCodes.module.scss'
import { ModalCodesCreate } from './components/ModalCodesCreate/ModalCodesCreate'
import { ModalCodesUpdate } from './components/ModalCodesUpdate/ModalCodesUpdate'

export const ModalCodes: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  return (
    <motion.div {...FADE_IN} className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <button className={styles.close} onClick={() => ModalState.hideModalCodes()}>
        <ExitIcon />
      </button>
      <div className={styles.wrap}>{ModalState.modalCodesType === 'create' ? <ModalCodesCreate /> : <ModalCodesUpdate />}</div>
    </motion.div>
  )
})
