import {FC} from 'react'
import {TAuctionObj} from '../../../../api/auctionApi'
import {urlApi} from '../../../../config/apiConfig'
import AppState from '../../../../store/AppState'
import {dateFormatFull} from '../../../../utils/date.utils'
import {priceFormat} from '../../../../utils/price.utils'
import styles from './MyDepositsItem.module.scss'
import AuthState from '../../../../store/AuthState'

export const MyDepositsItem: FC<{ item: TAuctionObj }> = ({item}) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const userId = AuthState.user?.id

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.wrap}>
        <div className={styles.img}
             style={(item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id) ? {backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})`} : undefined}></div>
        <div className={styles.text}>
          <h4 className={styles.title}>{item.TypeProperty && item.TypeProperty.name}</h4>
          <span className={styles.ref}>Referencia: {item.reference}</span>
        </div>
      </div>
      <div className={styles.wrap}>
        {item.status === 'APPROVED' && <span className={`${styles.status} ${styles.green}`}>ActivA</span>}
        {item.status === 'UNDER_CONSIDERATION' && <span className={`${styles.status} ${styles.yellow}`}>Revisión</span>}
        {item.status === 'REJECTED' && <span className={`${styles.status} ${styles.red}`}>cerrada</span>}
        <div className={styles.info}>
          <div className={styles.block}>
            <h4 className={styles.title}>Constituido</h4>
            <span className={styles.text}>Estado depósito</span>
          </div>
          <div className={styles.block}>
            <h4
              className={styles.title}>

              {dateFormatFull(userId && item.Deposit.filter(i => i.userId === userId).length !== 0 ? item.Deposit.filter(i => i.userId === userId)[0].createdAt : item.createdAt)}</h4>
            <span className={styles.text}>Fecha depósito</span>
          </div>
          <div className={styles.block}>
            <h4 className={styles.title}>{priceFormat(userId && item.Deposit.filter(i => i.userId === userId).length !== 0 ? item.Deposit.filter(i => i.userId === userId)[0].price : 0)}</h4>
            <span className={styles.text}>Cantidad</span>
          </div>
        </div>
      </div>
    </div>
  )
}
