import { orderBy } from 'lodash'
import { FC } from 'react'
import { TAuctionObj } from '../../../../api/auctionApi'
import { EditIcon, TrashIcon } from '../../../../components/Icons/Icons'
import { Timer } from '../../../../components/Timer/Timer'
import { urlApi } from '../../../../config/apiConfig'
import AppState from '../../../../store/AppState'
import AuctionState from '../../../../store/AuctionState'
import ModalState from '../../../../store/ModalState'
import { dateFormatFull } from '../../../../utils/date.utils'
import { priceFormat } from '../../../../utils/price.utils'
import styles from './MyAuctionsItem.module.scss'

export const MyAuctionsItem: FC<{ item: TAuctionObj }> = ({ item }) => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const onShowModal = () => {
		console.log(item.id)
		AuctionState.setAuctionId(item.id)
		ModalState.toggleModalAuctionDelete(true)
	}

	const onEdit = () => {
		ModalState.setAuctionId(item.id)
		ModalState.toggleModalAuctionEdit(true)
	}
	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			{item.AuctionMedia.length !== 0 ? (
				<div
					className={styles.img}
					style={
						item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id
							? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})` }
							: undefined
					}></div>
			) : (
				<div className={styles.img}></div>
			)}
			<div className={styles.text}>
				<h4 className={styles.title}>{item.TypeProperty && item.TypeProperty.name}</h4>
				<span className={styles.ref}>Referencia: {item.reference}</span>
			</div>
			<div className={styles.info}>
				<div className={styles.block}>
					<h4 className={styles.title}>{dateFormatFull(item.createdAt)}</h4>
					<span className={styles.text}>Fecha de alta</span>
				</div>
				<div className={styles.block}>
					<h4 className={styles.title}>{priceFormat(item.auctionStartingPrice)}</h4>
					<span className={styles.text}>Precio de salida</span>
				</div>
				<div className={styles.block}>
					<h4 className={styles.title}>{priceFormat(item.Bid.length ? orderBy(item.Bid, ['sum'], ['desc'])[0].sum : 0)}</h4>
					<span className={styles.text}>Última puja</span>
				</div>
			</div>
			{item.status === 'APPROVED' && <span className={`${styles.status} ${styles.green}`}>ActivA</span>}
			{item.status === 'UNDER_CONSIDERATION' && <span className={`${styles.status} ${styles.yellow}`}>Revisión</span>}
			{item.status === 'REJECTED' && <span className={`${styles.status} ${styles.red}`}>cerrada</span>}
			<div className={styles.timer}>
				<Timer date={new Date(item.endAuction)} size="small" />
			</div>
			<div className={styles.btn}>
				<button className={styles.edit} onClick={onEdit}>
					<EditIcon />
				</button>
				<button className={styles.edit} onClick={onShowModal}>
					<TrashIcon />
				</button>
			</div>
		</div>
	)
}
