import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import { TAuctionObj } from '../../../../api/auctionApi'
import { RightArrowIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import styles from './MainSlider.module.scss'
import { MainSliderItem } from './MainSliderItem/MainSliderItem'

export const MainSlider: FC<{ items: TAuctionObj[] }> = observer(({ items }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const isAdmin = AuthState.user?.type === 'ADMIN'
  const list = items.map((item) => (
    <SwiperSlide key={item.id}>
      <MainSliderItem item={item} />
    </SwiperSlide>
  ))

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      {isAdmin ? (
        <h3 className={styles.title}>
          Últimas subastas{' '}
          <Link to="/">
            Ver todas <RightArrowIcon />
          </Link>
        </h3>
      ) : (
        <h3 className={styles.title}>Últimas propiedades en subasta</h3>
      )}
      <Swiper
        navigation={{
          nextEl: `.${styles.next}`,
          prevEl: `.${styles.prev}`,
          disabledClass: `${styles.disabled}`,
        }}
        slidesPerView={isMobile ? 1 : 4}
        spaceBetween={isMobile ? 0 : 20}
        modules={[Navigation]}
        className={styles.slider}
      >
        {list}
      </Swiper>
      <div className={`swiper-button ${styles.prev}`}></div>
      <div className={`swiper-button ${styles.next}`}></div>
    </div>
  )
})
