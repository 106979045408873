import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AppState from '../../../store/AppState'
import ModalState from '../../../store/ModalState'
import { FADE_IN } from '../../../utils/animation/fade'
import { ExitIcon } from '../../Icons/Icons'
import styles from './ModalRoles.module.scss'
import { ModalRolesCreate } from './components/ModalRolesCreate/ModalRolesCreate'
import { ModalRolesUpdate } from './components/ModalRolesUpdate/ModalRolesUpdate'

export const ModalRoles: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  return (
    <motion.div {...FADE_IN} className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <button className={styles.close} onClick={() => ModalState.hideModalRoles()}>
        <ExitIcon />
      </button>
      <div className={styles.wrap}>{ModalState.modalCodesType === 'create' ? <ModalRolesCreate /> : <ModalRolesUpdate />}</div>
    </motion.div>
  )
})
