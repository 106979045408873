import { CategoryScale, Chart as ChartJS, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { TGetStatAllApi, TMonthStatUser, getStatAllApi } from '../../../../../../api/statApi'
import AppState from '../../../../../../store/AppState'
import styles from './MainStatBidsLine.module.scss'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
}

const month: string[] = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
export const MainStatBidsLine: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [stat, setStat] = useState<TGetStatAllApi | null>(null)
  const [monthData, setMonthData] = useState<TMonthStatUser[] | null>(null)

  useEffect(() => {
    getStatAllApi().then((res) => {
      setStat(res.data)
      console.log(res.data)
    })
  }, [])

  useEffect(() => {
    if (stat) {
      const statAuctionLast = stat?.statisticUsers.slice(-4)
      const statAuctionFirst = stat?.statisticUsers.slice(0, 8)
      const statAuction = [...statAuctionLast, ...statAuctionFirst]
      const result = statAuction.map((item, i) => ({ month: month[i], particular: item.particular, professional: item.professional }))

      setMonthData(result)
    }
  }, [stat])

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.top}>
        <div className={styles.left}>
          <h5 className={styles.title}>Comparativa</h5>
          <span className={styles.value}>Usuarios vs profesionales</span>
        </div>
        <div className={styles.right}>
          <span className={styles.individ}>Usuarios</span>
          <span className={styles.prof}>Profesionales</span>
        </div>
      </div>
      <div className={styles.chart}>
        <Line
          options={options as any}
          data={{
            labels: monthData?.map((item) => item.month),
            datasets: [
              {
                data: monthData?.map((item) => item.particular),
                borderColor: '#0093d3',
                backgroundColor: '#0093d3',
              },
              {
                data: monthData?.map((item) => item.professional),
                borderColor: '#f2af03',
                backgroundColor: '#f2af03',
              },
            ],
          }}
        />
      </div>
    </div>
  )
})
