import { observer } from 'mobx-react-lite'
import { FC, ReactNode } from 'react'
import { TAuctionObj } from '../../../../api/auctionApi'
import { BuildIcon, LocationIcon, PlacesIcon, RepairIcon, ShowersIcon, SquareIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import styles from './AuctionContent.module.scss'
import AuthState from '../../../../store/AuthState'

type TInfo = {
  title: string
  icon: ReactNode
}

const data: TInfo[] = [
  {
    title: 'Metros útiles',
    icon: <SquareIcon />,
  },
  {
    title: 'Dormitorios',
    icon: <PlacesIcon />,
  },
  {
    title: 'Baños',
    icon: <ShowersIcon />,
  },
  {
    title: 'Tipo vivienda',
    icon: <BuildIcon />,
  },
  {
    title: 'Estado vivienda',
    icon: <RepairIcon />,
  },
]

export const AuctionContent: FC<{ item: TAuctionObj }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const user = AuthState.user

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <span className={styles.ref}>Referencia: {item.reference}</span>
      <h2 className={styles.title}>{item.TypeProperty && item.TypeProperty.name}</h2>
      <div className={styles.location}>
        <LocationIcon />
        {item.city && <span>{item.city},</span>} {item.Province && <span>{item.Province.name},</span>} <span>{item.address && item.address}</span>
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <h5 className={styles.title}>Metros útiles</h5>
          <span className={styles.value}>
            <>
              <SquareIcon />
              {item.usableMeters}m<sup>2</sup>
            </>
          </span>
        </div>
        {item.bedrooms && (
          <div className={styles.item}>
            <h5 className={styles.title}>Dormitorios</h5>
            <span className={styles.value}>
              <>
                <PlacesIcon />
                {item.bedrooms} hab
              </>
            </span>
          </div>
        )}
        {item.bathrooms && (
          <div className={styles.item}>
            <h5 className={styles.title}>Baños</h5>
            <span className={styles.value}>
              <>
                <ShowersIcon />
                {item.bathrooms} baños
              </>
            </span>
          </div>
        )}
        <div className={styles.item}>
          <h5 className={styles.title}>Tipo vivienda</h5>
          <div className={styles.value}>
            <BuildIcon />
            {item.TypeProperty && item.TypeProperty.name}
          </div>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Estado vivienda</h5>
          <div className={styles.value}>
            <>
              <RepairIcon />
              {item.PropertyStatus && item.PropertyStatus.name}
            </>
          </div>
        </div>
      </div>
      <div className={styles.text}>
        <div className={styles.description}>{item.description}</div>
        {(user && user.type === 'ADMIN' || user && item.userId === user.id) && (
          <div className={styles.obser}>{item.internalObservations}</div>
        )}

      </div>
    </div>
  )
})
