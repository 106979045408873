import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import img1 from '../../../../assets/mainPage/rates-img1.png'
import img2 from '../../../../assets/mainPage/rates-img2.png'
import img3 from '../../../../assets/mainPage/rates-img3.png'
import AppState from '../../../../store/AppState'
import styles from './MainBids.module.scss'
import { MainBidsItem, TBidsProps } from './MainBidsItem/MainBidsItem'
import {TGetStatisticUserApi} from '../../../../api/statApi'
import {orderBy} from 'lodash'
import AuthState from '../../../../store/AuthState'


type TProps = {
  userStatistic: TGetStatisticUserApi
}

export const MainBids: FC<TProps> = observer(({userStatistic}) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const userId = AuthState.user?.id

  const list = userStatistic.auctionsBid.map(i => {
    return {
      id: i.id,
      img: i.AuctionMedia[0].id,
      title: i.TypeProperty?.name || '',
      location: `${i.address}, ${i.postcode}, ${i.Province?.name}, ${i.city}`,
      price: [
        {
          title: 'Precio de salida',
          value: i.auctionStartingPrice,
        },
        {
          title: 'Última puja',
          value: i.Bid.length === 0 ? 0 : orderBy(i.Bid, 'sum', 'desc')[0].sum,
        },
        {
          title: 'Mi última puja',
          value: orderBy(i.Bid.filter(i => i.userId === userId), 'sum', 'desc')[0].sum,
        },
      ],
    }
  }).map((item) => <MainBidsItem item={item} key={item.id} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h3 className={styles.title}>Mis pujas</h3>
      <div className={styles.list}>{list}</div>
    </div>
  )
})
