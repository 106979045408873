import { makeAutoObservable } from 'mobx'
import { TAuctionFile, TAuctionFileApi } from '../api/auctionApi'

export type TAuction = {
	reference: number
	address: string
	postcode: string
	plotMeters: number
	usableMeters: number
	description: string
	internalObservations: string
	auctionStartingPrice: number
	endAuction: number
	yearConstruction: number
	constructedMeters: number
	bedrooms: number
	bathrooms: number
	pool: boolean
	garage: boolean
	elevator: boolean
	terrace: boolean
	garden: boolean
	typePropertyId: string
	city: string
	provinceId: string
	propertyStatusId: string
	emissionId: string
	consumptionId: string
	cadastralReference: string
	biddingTranche?: number
}
type SelectData = {
	property: string
	province: string
	status: string
	emission: string
	consumption: string
}
class AuctionState {
	auctionId: string | '' = ''
	step: number = 0
	images: TAuctionFileApi[] = []
	documents: TAuctionFile[] = []
	select: SelectData = {
		property: '',
		province: '',
		status: '',
		emission: '',
		consumption: '',
	}
	obj: TAuction = {
		reference: 0,
		address: '',
		postcode: '',
		plotMeters: 0,
		usableMeters: 0,
		description: '',
		internalObservations: '',
		auctionStartingPrice: 0,
		endAuction: 0,
		yearConstruction: 0,
		constructedMeters: 0,
		bedrooms: 0,
		bathrooms: 0,
		pool: false,
		garage: false,
		elevator: false,
		terrace: false,
		garden: false,
		typePropertyId: '',
		city: '',
		provinceId: '',
		propertyStatusId: '',
		emissionId: '',
		consumptionId: '',
		cadastralReference: '',
	}
	constructor() {
		makeAutoObservable(this)
	}

	bidMessage: number[] = []

	resetStep() {
		this.step = 0
	}

	setBidMessage = (id: number) => {
		this.bidMessage.push(id)

		setTimeout(() => {
			this.bidMessage = this.bidMessage.filter((i) => i !== id)
		}, 5000)
	}

	resetObj = () => {
		this.obj = {
			reference: 0,
			address: '',
			postcode: '',
			plotMeters: 0,
			usableMeters: 0,
			description: '',
			internalObservations: '',
			auctionStartingPrice: 0,
			endAuction: 0,
			yearConstruction: 0,
			constructedMeters: 0,
			bedrooms: 0,
			bathrooms: 0,
			pool: false,
			garage: false,
			elevator: false,
			terrace: false,
			garden: false,
			typePropertyId: '',
			city: '',
			provinceId: '',
			propertyStatusId: '',
			emissionId: '',
			consumptionId: '',
			cadastralReference: '',
		}
	}

	setAuctionId(id: string) {
		this.auctionId = id
	}
	setImg(items: TAuctionFileApi[]) {
		this.images = items
	}
	setDoc(items: TAuctionFile[]) {
		this.documents = items
	}
	setObj(obj: any) {
		let copy = Object.assign(this.obj, obj)

		this.obj = copy
	}

	setNewObj(obj: any) {
		this.obj = obj
	}
	setSelectObj(obj: any) {
		let copy = Object.assign(this.select, obj)

		this.select = copy
	}
	setPrevStep() {
		if (this.step > 0) {
			this.step = this.step - 1
		}
	}

	setNextStep() {
		this.step = this.step + 1
	}
}

export default new AuctionState()
