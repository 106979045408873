import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { TAuctionObj } from '../../../../../../api/auctionApi'
import { TUsersObj, getUserByIdApi } from '../../../../../../api/usersApi'
import userImg from '../../../../../../assets/user/user-avatar.png'
import { HomeIconHover, RightArrowIcon } from '../../../../../../components/Icons/Icons'
import { urlApi } from '../../../../../../config/apiConfig'
import AppState from '../../../../../../store/AppState'
import { priceFormat } from '../../../../../../utils/price.utils'
import styles from './MainValidateItem.module.scss'

export const MainValidateItem: FC<{ item: TAuctionObj }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [user, setUser] = useState<TUsersObj>()

  useEffect(() => {
    getUserByIdApi(item.userId).then((res) => setUser(res.data))
  }, [])

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.img} style={(item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id) ? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0]?.id})` } : undefined}></div>
      <div className={styles.content}>
        <h4 className={styles.title}>{item.TypeProperty ? item.TypeProperty.name : 'Test 1'}</h4>
        <div className={styles.location}>
          {item.address && <span>{item.address},</span>}
          {item.postcode && <span>{item.postcode},</span>}
          {item.Province !== null && <span>{item.Province.name}, </span>}
          {item.city !== null && <span>{item.city}, </span>}
        </div>
        <div className={styles.price}>
          Precio de salida: <span>{priceFormat(item.auctionStartingPrice)}</span>
        </div>
        <div className={styles.user}>
          {user?.ProfessionalData && (
            <>
              <span className={styles.icon}>
                <HomeIconHover />
              </span>
              <div className={styles.content}>{user?.ProfessionalData.company}</div>
            </>
          )}
          {user?.ParticularData && (
            <>
              <span className={styles.img} style={{ backgroundImage: `url(${userImg})` }}></span>
              <div className={styles.content}>
                <span>{user?.ParticularData.firstSurname}</span>
                <span>{user?.ParticularData.secondSurname}</span>
              </div>
            </>
          )}
        </div>
        <span className={styles.link}>
          Ver subasta <RightArrowIcon />
        </span>
      </div>
    </div>
  )
})
