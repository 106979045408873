import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { postAuctionApi } from '../../../../api/auctionApi'
import { stripePublicKey } from '../../../../config/stripeConfig'
import AuctionState from '../../../../store/AuctionState'
// import { OrderForm } from '../../../AuthPage/components/OrderForm/OrderForm'
import AppState from '../../../../store/AppState'
import styles from './PayForm.module.scss'
import { OrderFormCreateAuction } from './components/OrderFormCreateAuction'

export const PayForm: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [isOrderForm, setIsOrderForm] = useState(false)
  const [isCorrectPayment, setIsCorrectPayment] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (isCorrectPayment) {
      postAuctionApi(AuctionState.obj)
        .then((res) => console.log(res.data))
        .catch((err) => console.log(err))
      AuctionState.setNextStep()
      /*  AuctionState.resetObj() */
    }
  }, [isCorrectPayment])
  const closeOrderForm = () => {
    setIsOrderForm(false)
  }
  const setCorrectedPayment = () => {
    setIsOrderForm(false)
    setIsCorrectPayment(true)
    console.log(isCorrectPayment)
  }
  const setError = () => {
    setIsOrderForm(false)
    setIsError(true)
  }
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <Elements stripe={loadStripe(stripePublicKey)}>
        <OrderFormCreateAuction closeOrderForm={closeOrderForm} setIsCorrectedPayment={setCorrectedPayment} setError={setError} icon={false} />
      </Elements>
    </div>
  )
})
