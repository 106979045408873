import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { addDataParticularApi } from '../../../../api/authApi'
import { TProvince, getProvincesApi } from '../../../../api/provinceApi'
import { Button } from '../../../../components/Button/Button'
import { ExitIcon } from '../../../../components/Icons/Icons'
import { SelectField, TItemSelect } from '../../../../components/SelectField/SelectField'
import { TextField } from '../../../../components/TextField/TextField'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import styles from './RegistrationUser.module.scss'

type TFormRegistrationUser = {
	name: string
	firstSurname: string
	secondSurname: string
	NIF: string
	phone: string
	address: string
	postcode: string
	city: string
}

type TFormFieldName = 'name' | 'firstSurname' | 'secondSurname' | 'NIF' | 'phone' | 'address' | 'postcode' | 'city'
type TFormFieldNameSelect = 'province'

type TProps = {
	closeRegistrationUser: () => void
	setAccountUserCreated: () => void
}

export const RegistrationUser: FC<TProps> = observer(({ closeRegistrationUser, setAccountUserCreated }) => {
	const [form, setForm] = useState<TFormRegistrationUser>({
		name: '',
		firstSurname: '',
		secondSurname: '',
		NIF: '',
		city: '',
		phone: '',
		address: '',
		postcode: '',
	})
	const [province, setProvince] = useState<TItemSelect | null>(null)
	const [provinces, setProvinces] = useState<TProvince[]>([])

	useEffect(() => {
		getProvincesApi().then((res) => {
			setProvinces(res.data)
		})
	}, [])

	const fields: {
		id: number
		label: string
		placeholder: string
		type: 'input' | 'select'
		fieldName: TFormFieldName | TFormFieldNameSelect
		listSelect?: TItemSelect[]
	}[] = [
		{ id: 1, label: 'Nombre*', placeholder: 'Introduce tu nombre', type: 'input', fieldName: 'name' },
		{ id: 2, label: 'Primer apellido*', placeholder: 'Introduce tu primer apellido', type: 'input', fieldName: 'firstSurname' },
		{ id: 3, label: 'Segundo apellido*', placeholder: 'Introduce tu segundo apellido', type: 'input', fieldName: 'secondSurname' },
		{ id: 4, label: 'NIF*', placeholder: 'Introduce tu NIF', type: 'input', fieldName: 'NIF' },
		{ id: 5, label: 'Teléfono*', placeholder: 'Intoduce tu teléfono', type: 'input', fieldName: 'phone' },
		{ id: 6, label: 'Dirección*', placeholder: 'Introduce tu dirección', type: 'input', fieldName: 'address' },
		{ id: 7, label: 'Código postal*', placeholder: 'Código postal', type: 'input', fieldName: 'postcode' },
		{ id: 8, label: 'Ciudad*', placeholder: 'Introduce tu ciudad', type: 'input', fieldName: 'city' },
		{ id: 9, label: 'Provincia*', placeholder: 'Selecciona tu provincia', type: 'select', fieldName: 'province', listSelect: provinces },
	]

	const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name
		if (
			name === 'name' ||
			name === 'firstSurname' ||
			name === 'secondSurname' ||
			name === 'city' ||
			name === 'NIF' ||
			name === 'phone' ||
			name === 'address' ||
			name === 'postcode'
		) {
			setForm({ ...form, [name]: e.currentTarget.value })
		}
	}

	const changeSelect = (name: string, item: TItemSelect) => {
		if (name === 'province') {
			setProvince(item)
		}
	}

	const handlerAddParticularData = async () => {
		if (form.name && form.firstSurname && form.secondSurname && form.NIF && form.phone && form.address && form.postcode && form.city && province) {
			await addDataParticularApi({
				name: form.name,
				firstSurname: form.firstSurname,
				secondSurname: form.secondSurname,
				NIF: form.NIF,
				phone: form.phone,
				address: form.address,
				postcode: form.postcode,
				city: form.city,
				provinceId: province.id,
			})
				.then((res) => {
					AuthState.setUser(res.data)
					setAccountUserCreated()
				})
				.catch((err) => {
					console.log(err.response.data)
				})
		} else {
			alert('Fill in all the fields')
		}
	}

	const list = fields.map((i) => {
		if (i.type === 'input' && i.fieldName !== 'province') {
			return (
				<div key={i.id} className={styles.field}>
					<TextField label={i.label} name={i.fieldName} value={form[i.fieldName]} placeholder={i.placeholder} onChange={changeForm} />
				</div>
			)
		}
		if (i.type === 'select' && i.listSelect) {
			return (
				<div key={i.id} className={styles.field}>
					<SelectField
						scroll
						label={i.label}
						placeholder={i.placeholder}
						selected={i.fieldName === 'province' ? province : null}
						list={i.listSelect}
						name={i.fieldName}
						changeSelected={changeSelect}
					/>
				</div>
			)
		}
		return null
	})

	const width = AppState.widthWindow
	const isMobile = width <= 768

	return (
		<div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
			<div className={styles.exit} onClick={closeRegistrationUser}>
				<ExitIcon />
			</div>
			<h1 className={styles.title}>Completa los datos de tu cuenta</h1>
			<div className={styles.form}>{list}</div>

			<div className={styles.button}>
				<Button onClick={handlerAddParticularData}>Crear cuenta</Button>
			</div>
		</div>
	)
})
