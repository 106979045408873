import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AppState from '../../../../../../store/AppState'
import { MainStatAuctionsBar } from '../MainStatAuctionsBar/MainStatAuctionsBar'
import { MainStatAuctionsLine } from '../MainStatAuctionsLine/MainStatAuctionsLine'
import styles from './MainStatAuctions.module.scss'

export const MainStatAuction: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.block}>
        <MainStatAuctionsBar />
      </div>
      <div className={styles.block}>
        <MainStatAuctionsLine />
      </div>
    </div>
  )
})
