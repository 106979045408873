import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FileIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import styles from './ImageFile.module.scss'

/* export type TFileApi = {
  path?: string
  lastModified?: string
  lastModifiedDate?: string
  name: string
  size?: number
  type?: 'application/pdf' | 'image/png' | 'image/jpeg' | 'link'
  webkitRelativePath?: string
} */
type TImageFile = {
  title: string
  changeFile: (file: File[]) => void
}
export const ImageFile: FC<TImageFile> = observer(({ title, changeFile }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  /* const onDrop = useCallback((acceptedFiles: any) => {
    const arr = acceptedFiles.map((item: TFileApi) => ({
      path: item.path,
      lastModified: item.lastModified,
      lastModifiedDate: item.lastModifiedDate,
      name: item.name,
      size: item.size,
      type: item.type,
      webkitRelativePath: item.webkitRelativePath,
    }))
    changeFile(acceptedFiles)
  }, []) */
  const onDrop = useCallback((acceptedFiles: any) => {
    changeFile(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const id = String(Math.floor(Math.random() * 100000))
  const [nameFile, setNameFile] = useState<string | null>(null)

  // const _changeFile = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e.currentTarget.files && e.currentTarget.files.length) {
  //     setNameFile(e.currentTarget.files[0].name)
  //     changeFile(e.currentTarget.files[0])
  //   }
  // }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`} {...getRootProps()}>
      <h3 className={styles.title}>{title}</h3>
      <label>
        <div className={styles.block}>
          <FileIcon />
          <span className={styles.text}>Arrastra aquí tus imágenes</span>
          <span className={styles.link}>Busca tus imágenes</span>
          <input type={'file'} className={styles.input} onChange={onDrop} accept="image/jpeg,image/jpg,image/png,application/pdf" {...getInputProps()} />
        </div>
      </label>
    </div>
  )
})
