import { observer } from 'mobx-react-lite'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { TAccessObj, getAccessApi } from '../../../../api/accessApi'
import { Pagination } from '../../../../components/Pagination/Pagination'
import AppState from '../../../../store/AppState'
import { AccessItem } from '../AccessItem/AccessItem'
import styles from './AccessPart.module.scss'

export const AccessPart: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [len, setLen] = useState(0)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage] = useState(3)
  const [items, setItems] = useState<TAccessObj[] | null>(null)

  useEffect(() => {
    getAccessApi({ role: 'PARTICULAR', take: postsPerPage, skip: count }).then((res) => {
      setLen(res.data.count)
      setItems(res.data.users)
    })
  }, [count])

  const paginates = (pageNumber: SetStateAction<number>) => {
    setCurrentPage(pageNumber)
    setCount(postsPerPage * Number(pageNumber))
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const previousPage = () => {
    if (currentPage >= 0) {
      setCurrentPage((prev) => prev - 1)
      setCount((prev) => prev - postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const nextPage = () => {
    if (currentPage !== Math.ceil(len / postsPerPage)) {
      setCurrentPage((prev) => prev + 1)
      setCount((prev) => prev + postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const list = items?.map((item) => <AccessItem item={item} role="PROFESSIONAL" key={item.id} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.list}>{list}</div>
      {len > postsPerPage && (
        <div className={styles.pagination}>
          <Pagination postsPerPage={postsPerPage} totalPosts={len} paginate={paginates} previousPage={previousPage} nextPage={nextPage} currentPage={currentPage} />
        </div>
      )}
    </div>
  )
})
