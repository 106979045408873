import {FC} from 'react'
import styles from './Logo.module.scss'
import logo from '../../assets/other/logo.png'

type TProps = {
  height?: string
  width?: string
}

export const Logo: FC<TProps> = ({height, width}) => {
  return (
    <div className={styles.wrapper} style={{backgroundImage: `url(${logo})`, height: height ? height : '52px', width: width ? width : '164px'}}/>
  )
}