import {apiService} from '../services/apiServices/apiService'
import {TAuctionObj} from './auctionApi'

export const addBidApi = async (data: {auctionId: string, bid: number, otherUserId?: string}) => {
  return await apiService('post', 'bid/addBid', data)
}

type TGetMyBidsApi = {
  count: number
  auctions: TAuctionObj[]
}

export const getMyBidsApi = async (data: {type: 'active' | 'closed' | 'awarded', take?: number, skip?: number}) => {
  return await apiService<TGetMyBidsApi>('get', 'bid/getMyBids', data)
}

export const getBidsApi = async (data: {type: 'active' | 'closed' | 'awarded', take?: number, skip?: number}) => {
  return await apiService<TGetMyBidsApi>('get', 'bid/getBids', data)
}
