import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import masterCard from '../../../../assets/other/masterCard.png'
import visa from '../../../../assets/other/visa.png'
import AppState from '../../../../store/AppState'
import { FacebookIcon, InstagramIcon } from '../../../Icons/Icons'
import { Logo } from '../../../Logo/Logo'
import { TMenuDrop } from '../Header/Menu/Menu'
import styles from './Footer.module.scss'

const data: TMenuDrop[] = [
  {
    title: 'Subastas',
    path: '/auctions',
  },
  {
    title: 'Mis búsquedas',
    path: '/my-searches',
  },
  {
    title: 'Mis pujas',
    path: '/bids',
  },
  {
    title: 'Mis subastas',
    path: '/my-auctions',
  },
  {
    title: 'Mis depósitos',
    path: '/my-deposits',
  },
  {
    title: 'Servicios',
    path: '/services',
  },
]
export const Footer: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const items = data.map((item) => (
    <Link to={item.path} className={styles.item} key={item.path}>
      {item.title}
    </Link>
  ))
  return (
    <footer className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className="container">
        <div className={styles.top}>
          <div className={styles.left}>
            <Link to="/" className={styles.logo}>
              <Logo />
            </Link>
            <p className={styles.text}>
              Desde Subasta tu casa te ayudamos a que vendas tu propiedad de una manera diferente, si vale más, te pagarán más y si vale menos, puedes saber cuanto podrán pagarte por ella. y si eres
              comprador, pagaras un precio justo, ni más ni menos.
            </p>
          </div>
          <div className={styles.center}>
            <h3 className={styles.title}>Secciones</h3>
            <div className={styles.list}>{items}</div>
          </div>
          <div className={styles.right}>
            <h3 className={styles.title}>Secciones</h3>
            <div className={styles.social}>
              <span className={styles.link}>
                <FacebookIcon />
              </span>
              <span className={styles.link}>
                <InstagramIcon />
              </span>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.block}>Texto legal de la página</div>
          <div className={styles.block}>
            <img src={visa} alt="" className={styles.img} />
            <img src={masterCard} alt="" className={styles.img} />
          </div>
        </div>
      </div>
    </footer>
  )
})
