import { observer } from 'mobx-react-lite'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { TAuctionObj, getAuctionAllApi } from '../../api/auctionApi'
import { FilterBlock } from '../../components/FilterBlock/FilterBlock'
import AppState from '../../store/AppState'
import styles from './DepositsPage.module.scss'
import { DepositsItem } from './components/DepositsItem'
const dataFilter: string[] = ['Data 1', 'Data 2', 'Data 3', 'Data 4', 'Data 5']
export const DepositsPage: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [items, setItems] = useState<TAuctionObj[]>([])
  const [len, setLen] = useState(0)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage] = useState(10)

  useEffect(() => {
    getAuctionAllApi({ take: postsPerPage, skip: count })
      .then((res) => {
        setLen(res.data.count)
        setItems(res.data.auctions)
      })
      .catch((err) => console.log(err))
  }, [])

  const paginates = (pageNumber: SetStateAction<number>) => {
    setCurrentPage(pageNumber)
    setCount(postsPerPage * Number(pageNumber))
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const previousPage = () => {
    if (currentPage >= 0) {
      setCurrentPage((prev) => prev - 1)
      setCount((prev) => prev - postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const nextPage = () => {
    if (currentPage !== Math.ceil(len / postsPerPage)) {
      setCurrentPage((prev) => prev + 1)
      setCount((prev) => prev + postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  const list = items.map((item) => <DepositsItem item={item} key={item.id} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.top}>
        <FilterBlock items={dataFilter} title="Depósitos" full />
      </div>
      <div className={styles.list}>{list}</div>
    </div>
  )
})
