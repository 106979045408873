import { orderBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { TAuctionObj } from '../../../../api/auctionApi'
import { TUsersObj, getUserByIdApi } from '../../../../api/usersApi'
import { HomeIconHover, LocationIcon } from '../../../../components/Icons/Icons'
import { urlApi } from '../../../../config/apiConfig'
import AppState from '../../../../store/AppState'
import { dateFormatFull } from '../../../../utils/date.utils'
import { priceFormat } from '../../../../utils/price.utils'
import styles from './BidsItem.module.scss'

export const BidsItem: FC<{ item: TAuctionObj; status: 'active' | 'close' | 'success' }> = observer(({ item, status }) => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const [user, setUser] = useState<TUsersObj | null>(null)

	useEffect(() => {
		getUserByIdApi(item.userId).then((res) => {
			setUser(res.data)
			console.log(res.data)
		})
	}, [])

	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<div className={styles.left}>
				<div
					className={styles.img}
					style={
						item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id
							? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})` }
							: undefined
					}></div>
				<div className={styles.text}>
					<span className={styles.ref}>Ref: {item.reference}</span>
					<h4 className={styles.title}>{item.TypeProperty && item.TypeProperty.name}</h4>
					<div className={styles.location}>
						<LocationIcon />
						{item.city && <span>{item.city},</span>} {item.Province && <span>{item.Province.name},</span>} {item.address && item.address}
					</div>
				</div>
			</div>
			<div className={styles.right}>
				<div className={styles.info}>
					<div className={styles.block}>
						<h4 className={styles.title}>{priceFormat(item.auctionStartingPrice)}</h4>
						<span className={styles.text}>Precio de salida</span>
					</div>
					<div className={styles.block}>
						<h4 className={styles.title}>{priceFormat(orderBy(item.Bid, ['sum'], ['desc'])[0].sum)}</h4>
						<span className={styles.text}>Última puja</span>
					</div>
					<div className={styles.block}>
						<h4 className={styles.title}>{dateFormatFull(orderBy(item.Bid, ['sum'], 'desc')[0].createdAt)}</h4>
						<span className={styles.text}>Fecha última puja</span>
					</div>
					<div className={styles.user}>
						{user?.type === 'PROFESSIONAL' && (
							<>
								<div className={styles.prof}>
									<HomeIconHover />
								</div>
								<div className={styles.content}>
									{/* <h3 className={styles.title}>{user.ProfessionalData?.company}</h3> */}
									<span className={styles.text}>{user.ProfessionalData?.contactPerson}</span>
								</div>
							</>
						)}
						{user?.type === 'PARTICULAR' && (
							<>
								<div className={styles.part}>
									{/*{user.ParticularData && user.ParticularData?.firstSurname + user.ParticularData?.secondSurname}*/}
									{user.ParticularData &&
										`${user.ParticularData.firstSurname.length !== 0 && user.ParticularData.firstSurname[0]}${
											user.ParticularData.secondSurname.length !== 0 && user.ParticularData.secondSurname[0]
										}`}
								</div>
								<div className={styles.content}>
									<h3 className={styles.title}>
										{user.ParticularData?.firstSurname} {user.ParticularData?.secondSurname}
									</h3>
									<span className={styles.text}>{user.type}</span>
								</div>
							</>
						)}
					</div>
				</div>
				{status === 'active' && <span className={`${styles.status} ${styles.green}`}>ActivA</span>}
				{status === 'close' && <span className={`${styles.status} ${styles.red}`}>Cerrada</span>}
				{status === 'success' && <span className={`${styles.status} ${styles.blue}`}>Adjudicada</span>}
			</div>
		</div>
	)
})
