import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { TCountry, getCountriesApi } from '../../../../api/countryApi'
import { TProvince, getProvincesApi } from '../../../../api/provinceApi'
import { Button } from '../../../../components/Button/Button'
import { CheckBox } from '../../../../components/CheckBox/CheckBox'
import { ExitIcon } from '../../../../components/Icons/Icons'
import { Input } from '../../../../components/Input/Input'
import { SelectField, TItemSelect } from '../../../../components/SelectField/SelectField'
import { TextField } from '../../../../components/TextField/TextField'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import PayState from '../../../../store/PayState'
import styles from './OrderForm.module.scss'
import {createDepositApi, depositConfirmationApi} from '../../../../api/depositApi'
import ModalState from '../../../../store/ModalState'

type TOrderFormObj = {
  name: string
  error: boolean
}

type TOrderForm = {
  userName: TOrderFormObj
  userSurnames: TOrderFormObj
  address: TOrderFormObj
  postalCode: TOrderFormObj
  phone: TOrderFormObj
  city: TOrderFormObj
}

type TProps = {
  closeOrderForm: () => void
  setIsCorrectedPayment: () => void
  setError: () => void
  icon?: boolean
  auctionId: string
}

export const OrderForm: FC<TProps> = observer(({ closeOrderForm, setIsCorrectedPayment, setError, icon = true, auctionId }) => {
  const user = AuthState.user
  const stripe = useStripe()
  const elements = useElements()
  const [form, setForm] = useState<TOrderForm>({userName: {name: '', error: false,}, userSurnames: {name: '', error: false,}, address: {name: '', error: false,}, postalCode: {name: '', error: false,}, phone: {name: '', error: false,}, city: {name: '', error: false,}})
  const [price, setPrice] = useState<string>('')
  const [selectCityError, setSelectCityError] = useState<boolean>(false)
  const [province, setProvince] = useState<TItemSelect | null>(null)
  const [selectProvinceError, setSelectProvinceError] = useState<boolean>(false)
  const [country, setCountry] = useState<TItemSelect | null>(null)
  const [selectCountryError, setSelectCountryError] = useState<boolean>(false)
  const [provinces, setProvinces] = useState<TProvince[]>([])
  const [countries, setCountries] = useState<TCountry[]>([])
  const [isCheck, setIsCheck] = useState(true)
  const [planName, setPlanName] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [paymentError, setPaymentError] = useState<null | { message: string }>(null)
  const otherUser = ModalState.otherUserData

  const provinceActive = provinces.find((item) => item.id === user?.ProfessionalData?.provinceId)

  useEffect(() => {
    ModalState.changeLegalStatus(false)
    ModalState.toggleModalLegal(false)
    getProvincesApi().then((res) => {
      setProvinces(res.data)
    })
    getCountriesApi().then((res) => {
      setCountries(res.data)
    })


  if (!otherUser) {
    createDepositApi({auctionId})
      .then(res => {
        const formData = res.data.formData
        setForm({...form, address: {name: formData.address, error: false}, phone: {name: formData.phone, error: false}, postalCode: {name: formData.postalCode, error: false}, userSurnames: {name: formData.userSurnames, error: false}, userName: {name: formData.userName, error: false},})

        setPlanName(res.data.payData.product.name)
        setPrice(String(Number(res.data.payData.product.price) / 100))
        setClientSecret(res.data.payData.clientSecret)
      })
  }
  if (otherUser) {
    const formData = otherUser.formData
    setForm({...form, address: {name: formData.address, error: false}, phone: {name: formData.phone, error: false}, postalCode: {name: formData.postalCode, error: false}, userSurnames: {name: formData.userSurnames, error: false}, userName: {name: formData.userName, error: false},})
    setPlanName(otherUser.payData.product.name)
    setPrice(String(Number(otherUser.payData.product.price) / 100))
    setClientSecret(otherUser.payData.clientSecret)

  }
  }, [])

  useEffect(() => {
    provinceActive ? setProvince(provinceActive) : setProvince(null)
  }, [provinces])

  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'userName' || name === 'userSurnames' || name === 'city' || name === 'address' || name === 'postalCode' || name === 'phone') {
      setForm({ ...form, [name]: { name: e.currentTarget.value, error: !!true } })
    }
  }

  const handlerPayment = async () => {
    const result = Object.entries(form).map(([key, value]) => [key, { name: value.name, error: value.name === '' ? true : false }])
    setSelectProvinceError(province === null ? true : false)
    setSelectCountryError(country === null ? true : false)
    setForm(Object.fromEntries(result))

    const error = Object.entries(form).some(([key, value]) => value.name === '')

    if (!error && province !== null && country !== null) {
      const formData = {
        name: form.userName.name,
        surname: form.userSurnames.name,
        address: form.address.name,
        postalCode: form.postalCode.name,
        phone: form.phone.name,
        city: form.city.name,
        provinceId: province.id,
        country: country.id,
      }

      PayState.setPay(formData)

      if (!elements || !stripe || !AuthState.user || !AuthState.user.email) {
        return console.log('Нет elements или stripe или email')
      }

      // Получаем объект элемента карточки из Stripe
      const cardElement = elements.getElement(CardNumberElement)

      if (!cardElement) {
        return console.log('нет cardElement')
      }

      const paymentResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: form.userName.name,
          email: AuthState.user.email,
        },
      })

      if (paymentResult.error) {
        console.error(paymentResult.error.message)
        if (paymentResult.error.message) {
          setPaymentError({ message: paymentResult.error.message })
        }
      } else {
        const paymentMethod = paymentResult.paymentMethod
        // Создаем платежное намерение с помощью clientSecret
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod?.id,
        })

        if (result.error) {
          console.error(result.error.message)
          if (result.error.message) {
            setPaymentError({ message: result.error.message })
          }
        } else {
          console.log(result)
          await depositConfirmationApi({idPayment: result.paymentIntent.id, auctionId, otherUserId: otherUser ? otherUser.formData.userId : undefined})
            .then((res) => {
              console.log('Payment successful!')
              setIsCorrectedPayment()
            })
            .catch(() => {
              setError()
            })
        }
      }
    }
  }
  const changeSelect = (name: string, item: TItemSelect) => {
    switch (name) {
      case 'province':
        setProvince(item)
        setSelectProvinceError(false)
        break
      case 'country':
        setCountry(item)
        setSelectCountryError(false)
        break
    }
  }

  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      {icon && (
        <div className={styles.exit} onClick={closeOrderForm}>
          <ExitIcon />
        </div>
      )}
      <h1 className={styles.title}>Tu compra</h1>
      <div className={styles.body}>
        <div className={styles.left}>
          <div className={styles.discountCode}>
            <p className={styles.titleDiscountCode}>¿Tienes un código de descuento?</p>
            <div className={styles.field}>
              <div className={styles.input}>
                <Input type={'text'} placeholder={'Introduce tu  código de descuento'} />
              </div>
              <div className={styles.button}>
                <Button>Validar</Button>
              </div>
            </div>

            <div className={styles.information}>
              <p className={styles.titleInformation}>Datos de facturación</p>
              <div className={styles.person}>
                <div className={styles.name}>
                  <TextField label={'Nombre*'} name="userName" error={form.userName.error} value={form.userName.name} placeholder={'Introduce un nombre'} onChange={changeForm} />
                </div>
                <div className={styles.lastName}>
                  <TextField label={'Apellidos*'} name="userSurnames" error={form.userSurnames.error} value={form.userSurnames.name} placeholder={'Nombre de la empresa'} onChange={changeForm} />
                </div>
              </div>

              <div className={styles.country}>
                <SelectField label={'País*'} placeholder={'Selecciona tu país'} list={countries} selected={country} changeSelected={changeSelect} name={'country'} error={selectCountryError} />
              </div>

              <div className={styles.address}>
                <TextField label={'Dirección*'} name="address" error={form.address.error} value={form.address.name} placeholder={'Intoduce dirección'} onChange={changeForm} />
              </div>

              <div className={styles.addressFields}>
                <TextField label={'Ciudad*'} name="city" error={form.city.error} value={form.city.name} placeholder={'Introduce ciudad'} onChange={changeForm} />
                <SelectField
                  label={'Provincia*'}
                  placeholder={'Selecciona provincia'}
                  list={provinces}
                  selected={province}
                  changeSelected={changeSelect}
                  name={'province'}
                  error={selectProvinceError}
                />
                <TextField label={'Código postal*'} name="postalCode" error={form.postalCode.error} value={form.postalCode.name} placeholder={'Introduce código postal'} onChange={changeForm} />
                <TextField type="number" label={'Teléfono*'} name="phone" error={form.phone.error} value={form.phone.name} placeholder={'Introduce un teléfono de contacto'} onChange={changeForm} />
              </div>

              <div className={styles.checkBox}>
                <CheckBox isCheck={isCheck} label={'He leído y acepto la política de privacidad y las condiciones generales de compra*'} onChange={() => setIsCheck(!isCheck)} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.order}>
            <p className={styles.titleOrder}>Tu pedido</p>
            <div className={styles.products}>
              <p className={styles.productName}>{planName}</p>
              <p className={styles.productPrice}>{price ? `${String(price).split('.')[0]},${String(price).split('.').length >= 2 ? String(price).split('.')[1] : '00'}€` : ''}</p>
            </div>
            <div className={styles.subtotal}>
              <p className={styles.subtotalName}>Subtotal</p>
              <p className={styles.subtotalPrice}>{price ? `${String(price).split('.')[0]},${String(price).split('.').length >= 2 ? String(price).split('.')[1] : '00'}€` : ''}</p>
            </div>
            <div className={styles.total}>
              <p className={styles.totalName}>Total</p>
              <p className={styles.totalPrice}>{price ? `${String(price).split('.')[0]},${String(price).split('.').length >= 2 ? String(price).split('.')[1] : '00'}€` : ''}</p>
            </div>
            <div className={styles.iva}>Incluye 0,00€ IVA</div>
          </div>

          <div className={styles.card}>
            <p className={styles.cardTitle}>Stripe</p>
            <p className={styles.cardDescription}>Pago seguro con tu tarjeta de crédito a través de Stripe.</p>

            <div className={styles.cardFields}>
              <div className={styles.stripeField}>
                <label htmlFor="cardNumber">Número de la tarjeta*</label>
                <CardNumberElement id="cardNumber" options={{}} className={styles.stripeInput} />
              </div>
              <div className={styles.stripeField}>
                <label htmlFor="cardExpiry">Fecha de caducidad*</label>
                <CardExpiryElement id="cardExpiry" options={{}} className={styles.stripeInput} />
              </div>
              <div className={styles.stripeField}>
                <label htmlFor="cardCvc">Código de verificación*</label>
                <CardCvcElement id="cardCvc" options={{}} className={styles.stripeInput} />
              </div>
              {paymentError && (
                <div className={styles.errorStripe}>
                  <span>{paymentError.message}</span>
                </div>
              )}

              <div className={styles.button}>
                <Button onClick={handlerPayment} disabled={!isCheck}>
                  Realizar el pago
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
