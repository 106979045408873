import {makeAutoObservable} from 'mobx'
import {TAuctionObj} from '../api/auctionApi'

class SearchState {
  constructor() {
    makeAutoObservable(this)
  }

  isSearch = false

  auctions: TAuctionObj[] = []

  setIsSearch = (value: boolean) => {
    this.isSearch = value
  }

  setAuctions = (auctions: TAuctionObj[]) => {
    this.auctions = auctions
  }
}

export default new SearchState()