import { observer } from 'mobx-react-lite'
import {FC, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import img from '../../../../../assets/other/complete-auction.png'
import AppState from '../../../../../store/AppState'
import ModalState from '../../../../../store/ModalState'
import { Button } from '../../../../Button/Button'
import styles from './ModalAuctionComplete.module.scss'
import AuctionState from "../../../../../store/AuctionState";
import {MY_SERVICES_PATH} from "../../../../../services/mainServices/RoutesService";

export const ModalAuctionComplete: FC = observer(() => {
  const navigate = useNavigate()
  const width = AppState.widthWindow
  const isMobile = width <= 768

  useEffect(() => {
    return () => {
      ModalState.setAuctionId('')
      ModalState.toggleModalAuctionEdit(false)
      ModalState.resetStep()
    }
  }, [])

  const handlerNavigate = (e: any) => {
    e.preventDefault()
    ModalState.setAuctionId('')
    ModalState.toggleModalAuctionEdit(false)
    ModalState.resetStep()
    navigate('/auctions')
  }

  const goService = () => {
    ModalState.setAuctionId('')
    ModalState.toggleModalAuctionEdit(false)
    AuctionState.resetStep()
    navigate(MY_SERVICES_PATH)
  }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h2 className={styles.title}>¡Nueva subasta creada!</h2>
      <img className={styles.img} src={img} alt="" />
      <p className={styles.text}>Nuestro equipo esta revisando que todo está correcto, en breve podrás verla en su panel </p>
      <p className={styles.subtitle}>¿Quieres añadir algún servicio adicional?</p>
      <div className={styles.bottom}>
        <div className={styles.btn}>
          <Button onClick={goService}>Ver servicios adicionales</Button>
        </div>
        <Link to="/auctions" className={styles.link} onClick={handlerNavigate}>
          Ir a mis subastas
        </Link>
      </div>
    </div>
  )
})
