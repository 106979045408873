import Slider from '@mui/material/Slider'
import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useNavigate } from 'react-router-dom'
import { getProvincesApi } from '../../../api/provinceApi'
import { TGetByFIlter, TLastFilter, getByFIlterApi, getLastFiltersApi } from '../../../api/searchApi'
import { TCheckboxItem } from '../../../pages/NewAuctionPage/components/CharactForm/CharactForm'
import { TAuctionSelectData, TAuctionSelectField } from '../../../pages/NewAuctionPage/components/PropertyForm/PropertyForm'
import { AUCTIONS_PATH } from '../../../services/mainServices/RoutesService'
import AppState from '../../../store/AppState'
import ModalState from '../../../store/ModalState'
import SearchState from '../../../store/SearchState'
import { FADE_IN } from '../../../utils/animation/fade'
import { priceFormat } from '../../../utils/price.utils'
import { Button } from '../../Button/Button'
import { CheckBox } from '../../CheckBox/CheckBox'
import {
  ApartmentIcon,
  CalendarIcon,
  DeleteWhiteIcon,
  ExitIcon,
  FactoryIcon,
  FarmsIcon,
  GroundIcon,
  HouseIcon,
  OthersIcon,
  PenthouseNewIcon,
  SolarIcon,
  StoresIcon,
  VillasIcon,
} from '../../Icons/Icons'
import { SelectField } from '../../SelectField/SelectField'
import { TextField } from '../../TextField/TextField'
import styles from './ModalSearch.module.scss'
import { SearchResult } from './components/SearchResult/SearchResult'

export type TIcons = {
  icon: JSX.Element
  name: string
}
const dataIcons: TIcons[] = [
  {
    icon: <HouseIcon />,
    name: 'Casa',
  },
  {
    icon: <ApartmentIcon />,
    name: 'Piso',
  },
  {
    icon: <PenthouseNewIcon />,
    name: 'ático',
  },
  {
    icon: <GroundIcon />,
    name: 'bajo',
  },
  {
    icon: <VillasIcon />,
    name: 'chalets',
  },
  {
    icon: <StoresIcon />,
    name: 'locales',
  },
  {
    icon: <FactoryIcon />,
    name: 'naves',
  },
  {
    icon: <FarmsIcon />,
    name: 'fincas',
  },
  {
    icon: <SolarIcon />,
    name: 'solar',
  },
  {
    icon: <OthersIcon />,
    name: 'otros',
  },
]
type TModalSearchFormItem = {
  value: ''
  error: false
}

type TModalSearchFormDate = {
  value: Date | null
  error: false
}

type TModalSearchForm = {
  postcode: TModalSearchFormItem
  startDate: TModalSearchFormDate
  endDate: TModalSearchFormDate
  city: TModalSearchFormItem
}
function valuetext(value: number) {
  return `${value}°C`
}

export const ModalSearch: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const navigate = useNavigate()
  const [lastFilters, setLastFilters] = useState<TLastFilter[]>([])

  const [checkList, setCheckList] = useState<TCheckboxItem[]>([
    {
      id: 0,
      name: 'Piscina',
      active: false,
    },
    {
      id: 1,
      name: 'Garaje',
      active: false,
    },
    {
      id: 2,
      name: 'Ascensor',
      active: false,
    },
    {
      id: 3,
      name: 'Terraza',
      active: false,
    },
    {
      id: 4,
      name: 'Jardin',
      active: false,
    },
  ])

  const [form, setForm] = useState<TModalSearchForm>({
    postcode: {
      value: '',
      error: false,
    },
    startDate: {
      value: null,
      error: false,
    },
    endDate: {
      value: null,
      error: false,
    },
    city: {
      value: '',
      error: false,
    },
  })

  const [provinceData, setProvinceData] = useState<TAuctionSelectData[]>([])

  const [province, setProvince] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  const [bedroomsData, setBedroomsData] = useState<TAuctionSelectData[]>([
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
  ])

  const [bedrooms, setBedrooms] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  const [bathroomsData, setBathroomsData] = useState<TAuctionSelectData[]>([
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
  ])

  const [bathrooms, setBathrooms] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })
  const [defaultPrice, setDefaultPrice] = useState([100000, 300000])
  const [price, setPrice] = useState<number[]>([0, 200000])
  const [defaultBids, setDefaultBids] = useState([100000, 300000])
  const [bids, setBids] = useState<number[]>([0, 200000])
  const [provinces, setProvinces] = useState<{ id: string; name: string }[]>([])
  const [typeProperty, setTypeProperty] = useState<'Casa' | 'Piso' | 'ático' | 'bajo' | 'chalets' | 'locales' | 'naves' | 'fincas' | 'solar' | 'otros' | undefined>(undefined)

  useEffect(() => {
    getProvincesApi().then((res) => {
      setProvinceData(res.data)
    })

    getLastFiltersApi().then((res) => {
      setLastFilters(res.data)
    })
  }, [])

  const handlePrice = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      setPrice([Math.min(newValue[0], price[1]), price[1]])
    } else {
      setPrice([price[0], Math.max(newValue[1], price[0])])
    }
  }

  const handleBids = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      setBids([Math.min(newValue[0], bids[1]), bids[1]])
    } else {
      setBids([bids[0], Math.max(newValue[1], bids[0])])
    }
  }

  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'postcode' || name === 'startDate' || name === 'endDate' || name === 'city') {
      setForm({ ...form, [name]: { value: e.currentTarget.value, error: !!true } })
    }
  }
  const changeSelect = (name: string, item: TAuctionSelectData) => {
    switch (name) {
      case 'province':
        if (!provinces.some((i) => i.id === item.id)) {
          setProvinces([...provinces, item])
        }
        break
      case 'bedrooms':
        setBedrooms((prev) => ({ ...prev, value: item, error: false }))
        break
      case 'bathrooms':
        setBathrooms((prev) => ({ ...prev, value: item, error: false }))
        break
    }
  }

  const deleteProvince = (id: string) => {
    setProvinces(provinces.filter((i) => i.id !== id))
  }

  const onSubmit = () => {
    const data: TGetByFIlter = {
      city: form.city.value,
      createdAt: form.startDate.value,
      endAuction: form.endDate.value,
      provinceIds: provinces.map((i) => i.id),
      bedrooms: bedrooms.value ? Number(bedrooms.value.name) : undefined,
      bathrooms: bathrooms.value ? Number(bathrooms.value.name) : undefined,
      postcode: form.postcode.value,
      pool: checkList[0].active,
      garage: checkList[1].active,
      elevator: checkList[2].active,
      terrace: checkList[3].active,
      garden: checkList[4].active,
      priceMin: price[0],
      priceMax: price[1],
      bidMin: bids[0],
      bidMax: bids[1],
      typeProperty: typeProperty,
    }
    getByFIlterApi(data).then((res) => {
      SearchState.setIsSearch(true)
      SearchState.setAuctions(res.data.auctions)
      console.log(res.data.auctions)
      ModalState.hideModalSearch()
      navigate(AUCTIONS_PATH)
    })
  }

  const changeTypeProperty = (name: string) => {
    if (
      name === 'Casa' ||
      name === 'Piso' ||
      name === 'ático' ||
      name === 'bajo' ||
      name === 'chalets' ||
      name === 'locales' ||
      name === 'naves' ||
      name === 'fincas' ||
      name === 'solar' ||
      name === 'otros'
    ) {
      if (typeProperty !== name) {
        return setTypeProperty(name)
      }
      return setTypeProperty(undefined)
    }
  }

  const checkboxList = checkList.map((item) => (
    <div className={styles.item} key={item.id}>
      <CheckBox isCheck={item.active} label={item.name} onChange={() => setCheckList((prev) => prev.map((el) => (item.id === el.id ? { ...el, active: !el.active } : el)))} />
    </div>
  ))

  const list = dataIcons.map((item) => {
    const isActive = typeProperty === item.name

    return (
      <div className={styles.item} onClick={() => changeTypeProperty(item.name)}>
        <div style={isActive ? { borderColor: '#99D4ED' } : undefined} className={styles.icon}>
          <span>{item.icon}</span>
        </div>
        <h4 className={styles.title}>{item.name}</h4>
      </div>
    )
  })

  return (
    <motion.div {...FADE_IN} className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <button className={styles.close} onClick={() => ModalState.hideModalSearch()}>
        <ExitIcon />
      </button>
      <div className={styles.content}>
        <h2 className={styles.title}>Buscador</h2>
        <div className={styles.list}>{list}</div>
        <div className={styles.wrap}>
          <div className={styles.left}>
            <div className={styles.single}>
              <div className={styles.input}>
                <TextField label={'Ciudad'} name="city" error={form.city.error} value={form.city.value} placeholder={'Selecciona ciudad'} onChange={changeForm} />
              </div>
            </div>
            <div className={styles.single}>
              <div className={styles.input}>
                <SelectField label={'Población'} placeholder={'Selecciona población'} list={provinceData} selected={province.value} changeSelected={changeSelect} name="province" error={false} />
                <div className={styles.provinces}>
                  {provinces.map((i) => {
                    return (
                      <div className={styles.provinceItem} key={i.id}>
                        {i.name}{' '}
                        <div className={styles.deleteProvince} onClick={() => deleteProvince(i.id)}>
                          <DeleteWhiteIcon />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={styles.single}>
              <div className={styles.input}>
                <TextField label={'Código postal'} name="postcode" error={form.postcode.error} value={form.postcode.value} placeholder={'Código postal'} onChange={changeForm} />
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.group}>
              <div className={styles.input}>
                <div className={styles.date}>
                  <h4 className={styles.title}>Inicio subasta</h4>
                  <label htmlFor="date1" className={`${form.startDate.error ? styles.error : ''}`}>
                    <DatePicker
                      locale="es"
                      placeholderText="Selecciona fecha"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      showDisabledMonthNavigation
                      id="date1"
                      selected={form.startDate.value}
                      onChange={(date: any) => setForm((prev) => ({ ...prev, startDate: { value: date, error: false } }))}
                    />
                    <CalendarIcon />
                  </label>
                </div>
              </div>
              <div className={styles.input}>
                <div className={styles.date}>
                  <h4 className={styles.title}>Fin subasta</h4>
                  <label htmlFor="date2" className={`${form.endDate.error ? styles.error : ''}`}>
                    <DatePicker
                      locale="es"
                      placeholderText="Selecciona fecha"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      id="date2"
                      selected={form.endDate.value}
                      onChange={(date: any) => setForm((prev) => ({ ...prev, endDate: { value: date, error: false } }))}
                    />
                    <CalendarIcon />
                  </label>
                </div>
              </div>
            </div>
            <div className={styles.group}>
              <div className={styles.input}>
                <SelectField label={'Número dormitorios'} placeholder={'Selecciona número'} list={bedroomsData} selected={bedrooms.value} changeSelected={changeSelect} name="bedrooms" error={false} />
              </div>
              <div className={styles.input}>
                <SelectField label={'Número baños'} placeholder={'Selecciona número'} list={bathroomsData} selected={bathrooms.value} changeSelected={changeSelect} name="bathrooms" error={false} />
              </div>
            </div>
            <div className={styles.checkbox}>
              <h4 className={styles.title}>Caracteristicas</h4>
              <div className={styles.list}>{checkboxList}</div>
            </div>
          </div>
        </div>
        <div className={styles.range}>
          <div className={styles.block}>
            <div className={styles.top}>
              <h4 className={styles.title}>Precio</h4>
              <span>{priceFormat(price[1])}</span>
            </div>
            <Slider getAriaLabel={() => 'Minimum distance'} min={0} max={300000} value={price} onChange={handlePrice} valueLabelDisplay="auto" disableSwap />
            <div className={styles.bottom}>
              <div className={styles.block}>
                <span className={styles.price}>{priceFormat(defaultPrice[0])}</span>
                <span className={styles.text}>Mínimo</span>
              </div>
              <div className={styles.block}>
                <span className={styles.price}>{priceFormat(defaultPrice[1])}</span>
                <span className={styles.text}>Máximo</span>
              </div>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.top}>
              <h4 className={styles.title}>Última puja</h4>
              <span>{priceFormat(bids[1])}</span>
            </div>
            <Slider getAriaLabel={() => 'Minimum distance'} min={0} max={300000} value={bids} onChange={handleBids} valueLabelDisplay="auto" disableSwap />
            <div className={styles.bottom}>
              <div className={styles.block}>
                <span className={styles.price}>{priceFormat(defaultBids[0])}</span>
                <span className={styles.text}>Mínimo</span>
              </div>
              <div className={styles.block}>
                <span className={styles.price}>{priceFormat(defaultBids[1])}</span>
                <span className={styles.text}>Máximo</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.btn} onClick={onSubmit}>
            <Button>Buscar</Button>
          </div>
        </div>
      </div>
      <div className={styles.result}>
        <div className={styles.line}>Ver mis últimas {lastFilters.length} búsquedas</div>
        <div className="container">
          <SearchResult filters={lastFilters} />
        </div>
      </div>
    </motion.div>
  )
})
