import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { getEmissionApi, getСonsumptionApi } from '../../../../api/auctionApi'
import { Button } from '../../../../components/Button/Button'
import { CheckBox } from '../../../../components/CheckBox/CheckBox'
import { RightArrowIcon } from '../../../../components/Icons/Icons'
import { SelectField } from '../../../../components/SelectField/SelectField'
import { TextField } from '../../../../components/TextField/TextField'
import AppState from '../../../../store/AppState'
import AuctionState from '../../../../store/AuctionState'
import { TAuctionFormInput, TAuctionSelectData, TAuctionSelectField } from '../PropertyForm/PropertyForm'
import styles from './CharactForm.module.scss'

export type TPropertyForm = {
	plotMeters: TAuctionFormInput
	useMeters: TAuctionFormInput
	yearConstruction: TAuctionFormInput
	constructedMeters: TAuctionFormInput
	bedrooms: TAuctionFormInput
	bathrooms: TAuctionFormInput
}

export type TCheckboxItem = {
	id: number
	name: string
	active: boolean
}

export const CharactForm: FC = observer(() => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const step = AuctionState.step
	const [checkList, setCheckList] = useState<TCheckboxItem[]>([
		{
			id: 0,
			name: 'Piscina',
			active: AuctionState.obj.pool,
		},
		{
			id: 1,
			name: 'Garaje',
			active: AuctionState.obj.garage,
		},
		{
			id: 2,
			name: 'Ascensor',
			active: AuctionState.obj.elevator,
		},
		{
			id: 3,
			name: 'Terraza',
			active: AuctionState.obj.terrace,
		},
		{
			id: 4,
			name: 'Jardin',
			active: AuctionState.obj.garden,
		},
	])
	const [form, setForm] = useState<TPropertyForm>({
		plotMeters: {
			name: AuctionState.obj.plotMeters !== 0 ? String(AuctionState.obj.plotMeters) : '',
			error: false,
		},
		useMeters: {
			name: AuctionState.obj.usableMeters !== 0 ? String(AuctionState.obj.usableMeters) : '',
			error: false,
		},
		yearConstruction: {
			name: AuctionState.obj.yearConstruction !== 0 ? String(AuctionState.obj.yearConstruction) : '',
			error: false,
		},
		constructedMeters: {
			name: AuctionState.obj.constructedMeters !== 0 ? String(AuctionState.obj.constructedMeters) : '',
			error: false,
		},
		bedrooms: {
			name: AuctionState.obj.bedrooms !== 0 ? String(AuctionState.obj.bedrooms) : '',
			error: false,
		},
		bathrooms: {
			name: AuctionState.obj.bathrooms !== 0 ? String(AuctionState.obj.bathrooms) : '',
			error: false,
		},
	})
	/*  const [yearData, setYearData] = useState<TAuctionSelectData[]>([
    { id: '2018', name: '2018' },
    { id: '2019', name: '2019' },
    { id: '2020', name: '2020' },
    { id: '2021', name: '2021' },
    { id: '2022', name: '2022' },
    { id: '2023', name: '2023' },
  ])

  const [year, setYear] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  }) */

	/* const [metersData, setMetersData] = useState<TAuctionSelectData[]>([
		{ id: '80', name: '80' },
		{ id: '90', name: '90' },
		{ id: '100', name: '100' },
		{ id: '200', name: '200' },
		{ id: '300', name: '300' },
		{ id: '400', name: '400' },
	])

	const [meters, setMeters] = useState<TAuctionSelectField>({
		value: null,
		error: false,
	}) */

	const [emissionsData, setEmissionsData] = useState<TAuctionSelectData[]>([])

	const [emissions, setEmissions] = useState<TAuctionSelectField>({
		value: null,
		error: false,
	})
	const [consumptionData, setConsumptionData] = useState<TAuctionSelectData[]>([])

	const [consumption, setConsumption] = useState<TAuctionSelectField>({
		value: null,
		error: false,
	})
	const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name
		if (
			name === 'plotMeters' ||
			name === 'useMeters' ||
			name === 'yearConstruction' ||
			name === 'constructedMeters' ||
			name === 'bedrooms' ||
			name === 'bathrooms'
		) {
			setForm({ ...form, [name]: { name: e.currentTarget.value, error: !!true } })
		}
	}
	useEffect(() => {
		getEmissionApi().then((res) => {
			setEmissionsData(res.data)
			if (AuctionState.obj.emissionId !== '') {
				const result = res.data.find((item) => item.id === AuctionState.obj.emissionId)
				setEmissions({ value: result ? result : null, error: false })
			}
		})

		getСonsumptionApi().then((res) => {
			setConsumptionData(res.data)
			if (AuctionState.obj.consumptionId !== '') {
				const result = res.data.find((item) => item.id === AuctionState.obj.consumptionId)
				setConsumption({ value: result ? result : null, error: false })
			}
		})
	}, [])

	const changeSelect = (name: string, item: TAuctionSelectData) => {
		switch (name) {
			case 'emissions':
				setEmissions((prev) => ({ ...prev, value: item, error: false }))
				break
			case 'consumption':
				setConsumption((prev) => ({ ...prev, value: item, error: false }))
				break
		}
	}
	const checkboxList = checkList.map((item) => (
		<div className={styles.item} key={item.id}>
			<CheckBox
				isCheck={item.active}
				label={item.name}
				onChange={() => setCheckList((prev) => prev.map((el) => (item.id === el.id ? { ...el, active: !el.active } : el)))}
			/>
		</div>
	))
	const onSubmit = () => {
		const result = Object.entries(form).map(([key, value]) => [key, { name: value.name, error: value.name === '' ? true : false }])
		setForm(Object.fromEntries(result))

		setEmissions((prev) => ({ ...prev, error: prev.value === null ? true : false }))
		setConsumption((prev) => ({ ...prev, error: prev.value === null ? true : false }))
		const error = Object.values(form).some((item) => item.name !== '')

		if (error) {
			const dataMain = {
				yearConstruction: Number(form.yearConstruction.name),
				constructedMeters: Number(form.constructedMeters.name),
				plotMeters: Number(form.plotMeters.name),
				usableMeters: Number(form.useMeters.name),
				emissionId: emissions.value?.id,
				consumptionId: consumption.value?.id,
				bedrooms: Number(form.bedrooms.name),
				bathrooms: Number(form.bathrooms.name),
				pool: checkList[0].active,
				garage: checkList[1].active,
				elevator: checkList[2].active,
				terrace: checkList[3].active,
				garden: checkList[4].active,
			}

			const dataSelect = {
				emission: emissions.value?.name,
				consumption: consumption.value?.name,
			}

			AuctionState.setSelectObj(dataSelect)
			AuctionState.setObj(dataMain)
			AuctionState.setNextStep()

			console.log(AuctionState.obj)
		}
	}
	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<div className={styles.form}>
				<div className={styles.wrap}>
					<div className={styles.group}>
						<div className={styles.input}>
							<TextField
								type="number"
								label={'Año de construcción*'}
								name="yearConstruction"
								error={form.yearConstruction.error}
								value={form.yearConstruction.name}
								placeholder={'Selecciona año'}
								onChange={changeForm}
							/>
						</div>
						<div className={styles.input}>
							<TextField
								type="number"
								label={'Metros construidos*'}
								name="constructedMeters"
								error={form.constructedMeters.error}
								value={form.constructedMeters.name}
								placeholder={'Introduce metros m2'}
								onChange={changeForm}
							/>
						</div>
					</div>
					<div className={styles.group}>
						<div className={styles.input}>
							<TextField
								type="number"
								label={'Metros parcela*'}
								name="plotMeters"
								error={form.plotMeters.error}
								value={form.plotMeters.name}
								placeholder={'Introduce metros m2'}
								onChange={changeForm}
							/>
						</div>
						<div className={styles.input}>
							<TextField
								type="number"
								label={'Metros útiles*'}
								name="useMeters"
								error={form.useMeters.error}
								value={form.useMeters.name}
								placeholder={'Introduce metros m2'}
								onChange={changeForm}
							/>
						</div>
					</div>
					<div className={styles.group}>
						<div className={styles.input}>
							<SelectField
								label={'Emisiones (CE)'}
								placeholder={'Seleccione rango'}
								list={emissionsData}
								selected={emissions.value}
								changeSelected={changeSelect}
								name="emissions"
								error={false}
							/>
						</div>
						<div className={styles.input}>
							<SelectField
								label={'Consumo (CE)'}
								placeholder={'Seleccione rango'}
								list={consumptionData}
								selected={consumption.value}
								changeSelected={changeSelect}
								name="consumption"
								error={false}
							/>
						</div>
					</div>
					<div className={styles.group}>
						<div className={styles.input}>
							<TextField
								type="number"
								label={'Dormitorios'}
								name="bedrooms"
								error={form.bedrooms.error}
								value={form.bedrooms.name}
								placeholder="Introduce número de habitaciones"
								onChange={changeForm}
							/>
						</div>
						<div className={styles.input}>
							<TextField
								type="number"
								label={'Baños'}
								name="bathrooms"
								error={form.bathrooms.error}
								value={form.bathrooms.name}
								placeholder="Introduce número de baños"
								onChange={changeForm}
							/>
						</div>
					</div>
				</div>
				<div className={styles.bottom}>
					<h4 className={styles.title}>Características</h4>
					<div className={styles.list}>{checkboxList}</div>
				</div>
			</div>
			<div className={styles.navigation}>
				{step > 0 && (
					<span className={styles.prev} onClick={() => AuctionState.setPrevStep()}>
						<RightArrowIcon />
						Volver al paso anterior
					</span>
				)}
				<span className={styles.next} onClick={onSubmit}>
					<Button color="blue">Continuar</Button>
				</span>
			</div>
		</div>
	)
})
