import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TAuctionObj } from '../../../../../api/auctionApi'
import { TUsersObj, getUserByIdApi } from '../../../../../api/usersApi'
import userImg from '../../../../../assets/user/user-avatar.png'
import { HeartEmptyIcon, HomeIconHover, LocationIcon, PlacesIcon, RightArrowIcon, ShowersIcon, SquareIcon } from '../../../../../components/Icons/Icons'
import { urlApi } from '../../../../../config/apiConfig'
import AppState from '../../../../../store/AppState'
import AuthState from '../../../../../store/AuthState'
import { priceFormat } from '../../../../../utils/price.utils'
import styles from './MainSliderItem.module.scss'
import {orderBy} from "lodash";

export const MainSliderItem: FC<{ item: TAuctionObj }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [user, setUser] = useState<TUsersObj>()
  const isAdmin = AuthState.user?.type === 'ADMIN'

  useEffect(() => {
    if (isAdmin) {
      getUserByIdApi(item.userId).then((res) => setUser(res.data))
    }
  }, [])

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.img} style={(item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id) ? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})` } : undefined}></div>
      <div className={styles.content}>
        <h3 className={styles.title}>{item.TypeProperty?.name}</h3>
        <div className={styles.location}>
          <LocationIcon />
          {item.address && <span>{item.address},</span>}
          {item.postcode && <span>{item.postcode},</span>}
          {item.Province !== null && <span>{item.Province.name}, </span>}
          {item.city !== null && <span>{item.city}, </span>}
        </div>
        <div className={styles.price}>
          <div className={styles.start}>
            <span className={styles.value}>{priceFormat(item.auctionStartingPrice)}</span>
            <span className={styles.text}>Precio de salida</span>
          </div>
          <div className={styles.end}>
            <span className={styles.value}>{priceFormat(item.Bid.length === 0 ? 0 : orderBy(item.Bid, 'sum', 'desc')[0].sum)}</span>
            <span className={styles.text}>Última puja</span>
          </div>
        </div>
        <div className={styles.info}>
          <span className={styles.block}>
            <SquareIcon />
            {item.plotMeters}m<sup>2</sup>
          </span>
          <span className={styles.block}>
            <PlacesIcon />
            {item.bedrooms}
          </span>
          <span className={styles.block}>
            <ShowersIcon />
            {item.bathrooms}
          </span>
        </div>
        {AuthState.user?.type === 'ADMIN' && (
          <div className={styles.user}>
            {user?.ProfessionalData && (
              <>
                <span className={styles.icon}>
                  <HomeIconHover />
                </span>
                <div className={styles.content}>{user?.ProfessionalData.company}</div>
              </>
            )}
            {user?.ParticularData && (
              <>
                <span className={styles.img} style={{ backgroundImage: `url(${userImg})` }}></span>
                <div className={styles.content}>
                  <span>{user?.ParticularData.firstSurname}</span>
                  <span>{user?.ParticularData.secondSurname}</span>
                </div>
              </>
            )}
          </div>
        )}
        <Link to={`/auction/${item.id}`} className={styles.bottom}>
          <span className={styles.link}>
            Ver subasta <RightArrowIcon />
          </span>
          <span className={styles.heart}>
            <HeartEmptyIcon />
          </span>
        </Link>
      </div>
    </div>
  )
})
