import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { TUsersObj, getUserByIdApi } from '../../../../api/usersApi'
import { Button } from '../../../../components/Button/Button'
import { CifIcon, DocumentsIcon, HomeIconHover, LocationIcon, PhoneIcon, SendIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import { nameFormat } from '../../../../utils/name.utils'
import styles from './AuctionProperty.module.scss'

export const AuctionProperty: FC<{ id: string; role: 'PROFESSIONAL' | 'PARTICULAR' | 'ADMIN' }> = observer(({ id, role }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [user, setUser] = useState<TUsersObj | null>(null)
  useEffect(() => {
    getUserByIdApi(id).then((res) => setUser(res.data))
  }, [])
  console.log(role)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h4 className={styles.title}>Datos propietario</h4>
      {user?.ParticularData && (
        <>
          <div className={styles.top}>
            <div className={styles.name}>{nameFormat(user.ParticularData.firstSurname)}</div>
            <div className={styles.content}>
              <h5 className={styles.title}>{user?.ParticularData?.firstSurname}</h5>
              <span className={styles.role}>Particular</span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.icon}>
              <PhoneIcon />
              Teléfono
            </div>
            <span className={styles.text}>{user?.ParticularData.phone}</span>
          </div>
          <div className={styles.block}>
            <div className={styles.icon}>
              <SendIcon />
              Email
            </div>
            <span className={styles.text}>{user?.email}</span>
          </div>
          <div className={styles.block}>
            <div className={styles.icon}>
              <LocationIcon />
              Dirección
            </div>
            <div className={styles.text}>
              {user.ParticularData.Province.name && <span>{user.ParticularData.Province.name},</span>}
              {user.ParticularData.address && <span>{user.ParticularData.address},</span>}
              {user.ParticularData.postcode && <span>{user.ParticularData.postcode},</span>}
              {user.ParticularData.city && <span>{user.ParticularData.city}</span>}
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.icon}>
              <CifIcon />
              CIF
            </div>
            <div className={styles.text}>{user.ParticularData.NIF}</div>
          </div>
        </>
      )}
      {user?.ProfessionalData && (
        <>
          <div className={styles.top}>
            <div className={styles.icon}>
              <HomeIconHover />
            </div>
            <div className={styles.content}>
              <h5 className={styles.title}>{user.ProfessionalData.company}</h5>
              <span className={styles.role}>{user.ProfessionalData.contactPerson}</span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.icon}>
              <PhoneIcon />
              Teléfono
            </div>
            <span className={styles.text}>{user.ProfessionalData.phone}</span>
          </div>
          <div className={styles.block}>
            <div className={styles.icon}>
              <SendIcon />
              Email
            </div>
            <span className={styles.text}>{user?.email}</span>
          </div>
          <div className={styles.block}>
            <div className={styles.icon}>
              <LocationIcon />
              Dirección
            </div>
            <div className={styles.text}>
              {user.ProfessionalData.Province.name && <span>{user.ProfessionalData.Province.name},</span>}
              {user.ProfessionalData.address && <span>{user.ProfessionalData.address},</span>}
              {user.ProfessionalData.postcode && <span>{user.ProfessionalData.postcode},</span>}
              {user.ProfessionalData.city && <span>{user.ProfessionalData.city}</span>}
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.icon}>
              <CifIcon />
              CIF
            </div>
            <div className={styles.text}>{user.ProfessionalData.CIF}</div>
          </div>
        </>
      )}
      <div className={styles.block}>
        <div className={styles.icon}>
          <DocumentsIcon />
          Contrato
        </div>
        <div className={styles.text}>
          Pendiente enviar <Button>Enviar contrato</Button>
        </div>
      </div>
    </div>
  )
})
