import { makeAutoObservable } from 'mobx'
import { TUpdateZipCode } from '../api/zipcodeApi'

class CodesState {
  obj: TUpdateZipCode | null = null
  constructor() {
    makeAutoObservable(this)
  }

  setObj(obj: TUpdateZipCode) {
    this.obj = obj
  }
}

export default new CodesState()
