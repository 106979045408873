import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AppState from '../../../store/AppState'
import ModalState from '../../../store/ModalState'
import { FADE_IN } from '../../../utils/animation/fade'
import { ExitIcon } from '../../Icons/Icons'
import styles from './ModalAddBids.module.scss'
import { ModalAddBidsHandler } from './components/ModalAddBidsHandler/ModalAddBidsHandler'
import { ModalAddBidsUser } from './components/ModalAddBidsUser/ModalAddBidsUser'

export const ModalAddBids: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const status = ModalState.modalStatusBids === 'handler'
  const onClose = () => {
    ModalState.changeStatusBids('handler')
    ModalState.toggleModalBids(false)
  }
  return (
    <motion.div {...FADE_IN} className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <button className={styles.close} onClick={onClose}>
        <ExitIcon />
      </button>
      <div className={styles.content}>{status ? <ModalAddBidsHandler /> : <ModalAddBidsUser />}</div>
    </motion.div>
  )
})
