import { makeAutoObservable } from 'mobx'

type TUserPay = {
  name: string
  surname: string
  address: string
  postalCode: string
  phone: string
  // cardNumber: string
  // cardDate: string
  // cardCode: string
  city: string
  provinceId: string
  country: string
}

class PayState {
  constructor() {
    makeAutoObservable(this)
  }
  userPay: TUserPay | null = null

  setPay(obj: TUserPay) {
    localStorage.setItem('userPay', JSON.stringify(obj))
    this.userPay = obj
  }
}

export default new PayState()
