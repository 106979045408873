import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TAuctionFile, updateAuctionApi } from '../../../../../api/auctionApi'
import { urlApi } from '../../../../../config/apiConfig'
import AppState from '../../../../../store/AppState'
import AuctionState from '../../../../../store/AuctionState'
import AuthState from '../../../../../store/AuthState'
import ModalState from '../../../../../store/ModalState'
import { dateFormat } from '../../../../../utils/date.utils'
import { priceFormat } from '../../../../../utils/price.utils'
import { Button } from '../../../../Button/Button'
import { DocumentsIcon, LocationIcon, PenIcon } from '../../../../Icons/Icons'
import styles from './ModalAuctionPreview.module.scss'

export const ModalAuctionPreview: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  /* const { obj, select, documents, images } = AuctionState */
  const role = AuthState.user?.type

  const list = AuctionState.documents.map((item: TAuctionFile) => (
    <div className={styles.item}>
      <DocumentsIcon />
      {item.name}
    </div>
  ))
  const handlerCreate = () => {
    if (ModalState.data) {
      const data = {
        reference: ModalState.data?.reference,
        address: String(ModalState.data?.address),
        postcode: ModalState.data?.postcode,
        plotMeters: Number(ModalState.data?.plotMeters),
        usableMeters: Number(ModalState.data?.usableMeters),
        description: ModalState.data?.description,
        internalObservations: ModalState.data?.internalObservations,
        auctionStartingPrice: Number(ModalState.data?.auctionStartingPrice),
        endAuction: Number(ModalState.data?.endAuction),
        yearConstruction: ModalState.data.yearConstruction,
        constructedMeters: ModalState.data.constructedMeters,
        bedrooms: ModalState.data.bedrooms,
        bathrooms: ModalState.data.bathrooms,
        pool: ModalState.data.pool,
        garage: ModalState.data.garage,
        elevator: ModalState.data.elevator,
        terrace: ModalState.data.terrace,
        garden: ModalState.data.garden,
        typePropertyId: ModalState.data.TypeProperty ? ModalState.data.TypeProperty.id : '',
        city: ModalState.data.city,
        provinceId: ModalState.data.Province ? ModalState.data.Province.id : '',
        propertyStatusId: ModalState.data.PropertyStatus ? ModalState.data.PropertyStatus.id : '',
        emissionId: ModalState.data.Emission ? ModalState.data.Emission.id : '',
        consumptionId: ModalState.data.Consumption ? ModalState.data.Consumption.id : '',
        cadastralReference: ModalState.data.cadastralReference,
        biddingTranche: ModalState.data.biddingTranche ? ModalState.data.biddingTranche : undefined
      }
      if (role === 'PROFESSIONAL') {
        updateAuctionApi(data)
          .then((res) => ModalState.setNextStep())
          .catch((err) => console.log(err))
        /* AuctionState.resetObj() */
      }

      if (role === 'PARTICULAR') {
        updateAuctionApi(data)
          .then((res) => ModalState.setNextStep())
          .catch((err) => console.log(err))
      }
    }
    // если профи, то отправить на создание
    //
    // если партикулар , то как сейчсас
  }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h2 className={styles.title}>Resumen de tu propiedad en subasta</h2>
      <div className={styles.content}>
        <div className={styles.left}>
          {ModalState.data?.AuctionMedia.length !== 0 && <div className={styles.img} style={(ModalState.data && ModalState.data.AuctionMedia.length !== 0 && ModalState.data.AuctionMedia[0].id) ? { backgroundImage: `url(${urlApi}auction/media?id=${ModalState.data?.AuctionMedia[0].id})` } : undefined}></div>}
          <h3 className={styles.subtitle}>Características del inmueble</h3>
          <div className={styles.list}>
            <div className={styles.item}>
              <span className={styles.text}>Tipo vivienda</span>
              <span className={styles.value}>{ModalState.data?.TypeProperty?.name}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Estado vivienda</span>
              <span className={styles.value}>{ModalState.data?.PropertyStatus?.name}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Metros útiles</span>
              <span className={styles.value}>
                {ModalState.data?.usableMeters}m<sup>2</sup>
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Metros parcela</span>
              <span className={styles.value}>
                {ModalState.data?.plotMeters}m<sup>2</sup>
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Dormitorios</span>
              <span className={styles.value}>{ModalState.data?.bedrooms ? ModalState.data?.bedrooms : 0}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Baños</span>
              <span className={styles.value}>{ModalState.data?.bathrooms ? ModalState.data?.bathrooms : 0}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Año de construcción</span>
              <span className={styles.value}>{ModalState.data?.yearConstruction}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Piscina</span>
              <span className={styles.value}>{ModalState.data?.pool ? 'Sí' : 'No'}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Parking</span>
              <span className={styles.value}>{ModalState.data?.garage ? 'Sí' : 'No'}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Terraza</span>
              <span className={styles.value}>{ModalState.data?.terrace ? 'Sí' : 'No'}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Ascensor</span>
              <span className={styles.value}>{ModalState.data?.elevator ? 'Sí' : 'No'}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Jardín</span>
              <span className={styles.value}>{ModalState.data?.garden ? 'Sí' : 'No'}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.text}>Certificado enérgetico</span>
              <span className={styles.value}>{ModalState.data?.consumptionId || ModalState.data?.emissionId ? 'Sí' : 'No'}</span>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <span className={styles.ref}>Referencia: {ModalState.data?.reference}</span>
          <h3 className={styles.name}>{ModalState.data?.TypeProperty?.name}</h3>
          <div className={styles.location}>
            <LocationIcon />
            <div className={styles.address}>
              {ModalState.data?.address && <span>{ModalState.data?.address},</span>}
              <br />
              {ModalState.data?.postcode && <span>{ModalState.data?.postcode},</span>}
              {ModalState.data?.Province && <span>{ModalState.data?.Province.name}, </span>}
              {ModalState.data?.city !== '' && <span>{ModalState.data?.city}. </span>}
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.date}>
              <span className={styles.value}>{dateFormat(Date.now())}</span>
              <span className={styles.text}>Fecha inicio</span>
            </div>
            <div className={styles.date}>
              <span className={styles.value}>{dateFormat(new Date(String(ModalState.data?.endAuction)).getTime())}</span>
              <span className={styles.text}>Fecha fin</span>
            </div>
            <div className={styles.price}>
              <span className={styles.value}>{priceFormat(Number(ModalState.data?.auctionStartingPrice))}</span>
              <span className={styles.text}>Precio de salida</span>
            </div>
          </div>
          <div className={styles.description}>
            <h3 className={styles.title}>Descripción</h3>
            <div className={styles.text}>{ModalState.data?.description}</div>
          </div>
          <div className={styles.description}>
            <h3 className={styles.title}>Observaciones internas</h3>
            <div className={styles.text}>{ModalState.data?.internalObservations}</div>
          </div>
          <div className={styles.documents}>
            <h3 className={styles.title}>Documentos adicionales</h3>
            <div className={styles.list}>{list}</div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <span className={styles.link} onClick={() => AuctionState.setPrevStep()}>
          <PenIcon />
          Editar la subasta
        </span>
        <div className={styles.btn} onClick={handlerCreate}>
          <Button>Publicar subasta</Button>
        </div>
      </div>
    </div>
  )
})
