import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { TAuctionObj } from '../../../../../../api/auctionApi'
import { DownIcon, LocationIcon } from '../../../../../../components/Icons/Icons'
import { urlApi } from '../../../../../../config/apiConfig'
import AppState from '../../../../../../store/AppState'
import { priceFormat } from '../../../../../../utils/price.utils'
import styles from './MainLatestItem.module.scss'
import {orderBy} from "lodash";

export const MainLatestItem: FC<{ item: TAuctionObj }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  console.log(item)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.left}>
        {item.AuctionMedia.length !== null ? (
          <div className={styles.img} style={(item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id) ? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})` } : undefined}></div>
        ) : (
          <div className={styles.img}></div>
        )}
        <div className={styles.content}>
          <h4 className={styles.title}>{item.TypeProperty ? item.TypeProperty.name : 'Test 1'}</h4>
          <div className={styles.location}>
            <LocationIcon />
            {item.address && <span>{item.address},</span>}
            {item.postcode && <span>{item.postcode},</span>}
            {item.Province !== null && <span>{item.Province.name}, </span>}
            {item.city !== null && <span>{item.city}, </span>}
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.price}>
          <span className={`${styles.value} ${styles.gray}`}>{priceFormat(item.auctionStartingPrice)}</span>
          <h5 className={`${styles.title} ${styles.gray}`}>Precio de salida</h5>
        </div>
        <div className={styles.price}>
          <span className={`${styles.value} ${styles.green}`}>{priceFormat(item.Bid.length === 0 ? 0 : orderBy(item.Bid, 'sum', 'desc')[0].sum)}</span>
          <h5 className={`${styles.title} ${styles.green}`}>Última puja</h5>
        </div>
        <Link to="/" className={styles.link}>
          <DownIcon />
        </Link>
      </div>
    </div>
  )
})
