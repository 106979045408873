import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import AppState from '../../../../../store/AppState'
import ModalState from '../../../../../store/ModalState'
import { Button } from '../../../../Button/Button'
import { RightArrowIcon } from '../../../../Icons/Icons'
import { TextareaField } from '../../../../TextareaField/TextareaField'
import { TAuctionFormInput } from '../ModalAuctionProperty/ModalAuctionProperty'
import styles from './ModalAuctionDescription.module.scss'

export type TPropertyForm = {
  description: TAuctionFormInput
  internalObservations: TAuctionFormInput
}

export const ModalAuctionDescription: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const step = ModalState.step
  const [form, setForm] = useState<TPropertyForm>({
    description: {
      name: '',
      error: false,
    },
    internalObservations: {
      name: '',
      error: false,
    },
  })

  useEffect(() => {
    if (ModalState.data) {
      setForm({
        description: {
          name: ModalState.data.description,
          error: false,
        },
        internalObservations: {
          name: ModalState.data.internalObservations,
          error: false,
        },
      })
    }
  }, [ModalState.data])
  const changeForm = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name
    if (name === 'description' || name === 'internalObservations') {
      setForm({ ...form, [name]: { name: e.currentTarget.value, error: !!true } })
    }
  }
  const onSubmit = () => {
    const data = {
      description: form.description.name,
      internalObservations: form.internalObservations.name,
    }

    ModalState.updateEditData(data)
    ModalState.setNextStep()
  }
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.form}>
        <div className={styles.wrap}>
          <div className={styles.group}>
            <div className={styles.input}>
              <TextareaField label={'Descripción'} name="description" error={false} value={form.description.name} placeholder={'Descripción del inmueble'} onChange={changeForm} />
            </div>
            <div className={styles.input}>
              <TextareaField
                label={'Observaciones internas'}
                name="internalObservations"
                error={false}
                value={form.internalObservations.name}
                placeholder={'Observaciones internas del inmueble'}
                onChange={changeForm}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.navigation}>
        {step > 0 && (
          <span className={styles.prev} onClick={() => ModalState.setPrevStep()}>
            <RightArrowIcon />
            Volver al paso anterior
          </span>
        )}
        <span className={styles.next} onClick={onSubmit}>
          <Button color="blue">Continuar</Button>
        </span>
      </div>
    </div>
  )
})
