import { observer } from 'mobx-react-lite'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { TUsersObj, getUsersApi } from '../../../../api/usersApi'
import { Pagination } from '../../../../components/Pagination/Pagination'
import AppState from '../../../../store/AppState'
import UpdateState from '../../../../store/UpdateState'
import { UsersItem } from '../UsersItem/UsersItem'
import styles from './UsersProfessionals.module.scss'

export const UsersProfessionals: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [items, setItems] = useState<TUsersObj[] | null>(null)
  const [len, setLen] = useState(0)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage] = useState(5)

  useEffect(() => {
    getUsersApi({ role: 'PROFESSIONAL', take: postsPerPage, skip: count })
      .then((res) => {
        setLen(res.data.count)
        setItems(res.data.users)
        console.log(res.data.users)
      })
      .catch((err) => console.log(err))
  }, [UpdateState.usersUpdate, count])

  const paginates = (pageNumber: SetStateAction<number>) => {
    setCurrentPage(pageNumber)
    setCount(postsPerPage * Number(pageNumber))
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const previousPage = () => {
    if (currentPage >= 0) {
      setCurrentPage((prev) => prev - 1)
      setCount((prev) => prev - postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const nextPage = () => {
    if (currentPage !== Math.ceil(len / postsPerPage)) {
      setCurrentPage((prev) => prev + 1)
      setCount((prev) => prev + postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  if (!items) {
    return <div>Loader...</div>
  }
  const list = items.map((item) => <UsersItem item={item} key={item.id} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.list}>{list}</div>
      {len > postsPerPage && (
        <div className={styles.pagination}>
          <Pagination postsPerPage={postsPerPage} totalPosts={len} paginate={paginates} previousPage={previousPage} nextPage={nextPage} currentPage={currentPage} />
        </div>
      )}
    </div>
  )
})
