import { apiService } from '../services/apiServices/apiService'

type TPostZipCodeApi = {
  code: string
  city: string
  provinceId: string
}

export type TPostZipCode = TPostZipCodeApi

/* type TUpdateZipCodeApi = {
  id: string
  code: string
  city: string
  provinceId: string
  active: boolean
}

export type TUpdateZipCode = TUpdateZipCodeApi */

export type TGetZipCodeObj = {
  id: string
  code: string
  city: string
  provinceId: string
  active: boolean
  Province: {
    id: string
    name: string
  }
}
type TGetZipCodeApi = {
  count: number
  zipCodes: TGetZipCodeObj[]
}

export type TUpdateZipCode = Omit<TGetZipCodeObj, 'Province'>

export type TGetZipCodeAll = TGetZipCodeApi[]

/* Post*/

export const postZipCodeApi = async (formData: FormData) => {
  return await apiService<TPostZipCodeApi>('post', 'zipCode/create', formData, { headers: formData })
}

export const updateZipCodeApi = async (payload: TUpdateZipCode) => {
  return await apiService<TUpdateZipCode>('post', 'zipCode/update', payload)
}

export const deleteZipCodeApi = async (payload: { id: string }) => {
  return await apiService<TUpdateZipCode>('post', 'zipCode/delete', payload)
}

/* Get */

export const getZipCodeApi = async (payload: { take: number; skip: number }) => {
  return await apiService<TGetZipCodeApi>('get', 'zipCode/', payload)
}
