import { observer } from 'mobx-react-lite'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TAuctionObj, getAuctionAllApi } from '../../api/auctionApi'
import { Pagination } from '../../components/Pagination/Pagination'
import AppState from '../../store/AppState'
import AuthState from '../../store/AuthState'
import styles from './MyDepositsPage.module.scss'
import { MyDepositsItem } from './components/MyDepositsItem/MyDepositsItem'
import {getMyDepositsApi} from '../../api/depositApi'

export const MyDepositsPage: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const navigator = useNavigate()
  const user = AuthState.user?.type
  const [items, setItems] = useState<TAuctionObj[]>([])
  const [len, setLen] = useState(0)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage] = useState(10)

  useEffect(() => {
    getMyDepositsApi({take: postsPerPage, skip: count})
      .then(res => {
        setLen(res.data.count)
        setItems(res.data.auctions.map(i => ({...i.Auction})))
        // console.log(res.data)
      })
  }, [])

  const paginates = (pageNumber: SetStateAction<number>) => {
    setCurrentPage(pageNumber)
    setCount(postsPerPage * Number(pageNumber))
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const previousPage = () => {
    if (currentPage >= 0) {
      setCurrentPage((prev) => prev - 1)
      setCount((prev) => prev - postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const nextPage = () => {
    if (currentPage !== Math.ceil(len / postsPerPage)) {
      setCurrentPage((prev) => prev + 1)
      setCount((prev) => prev + postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  const list = items.map((item) => <MyDepositsItem item={item} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h2 className={styles.title}>Mis depósitos</h2>
      <div className={styles.list}>{list}</div>
      {len > postsPerPage && (
        <div className={styles.pagination}>
          <Pagination postsPerPage={postsPerPage} totalPosts={len} paginate={paginates} previousPage={previousPage} nextPage={nextPage} currentPage={currentPage} />
        </div>
      )}
    </div>
  )
})
