import { FC, useState } from 'react'
import AppState from '../../store/AppState'
import { ArrowDown } from '../Icons/Icons'
import styles from './SelectField.module.scss'

export type TItemSelect = {
  id: string
  name: string
}

type TProps = {
  label: string
  labelShow?: boolean
  placeholder: string
  list: TItemSelect[]
  selected: null | TItemSelect
  changeSelected: (name: string, item: TItemSelect) => void
  name: string
  error?: boolean
  scroll?: boolean
}

export const SelectField: FC<TProps> = ({ label, placeholder, list, selected, error, changeSelected, name, scroll = true, labelShow = true }) => {
  const [isOpen, setIsOpen] = useState(false)
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const handlerSelected = (item: TItemSelect) => {
    changeSelected(name, item)
    setIsOpen(false)
  }

  const styleSelect = isOpen ? { borderRadius: '10px 10px 0 0' } : {}

  const items = list.map((i) => (
    <span key={i.id} className={styles.item} onClick={() => handlerSelected(i)}>
      {i.name}
    </span>
  ))

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      {labelShow && <span className={styles.label}>{label}</span>}
      <div className={`${styles.select} ${error ? styles.error : ''}`} style={styleSelect}>
        <div className={styles.main} onClick={toggleOpen}>
          {!selected && <span className={styles.placeholder}>{placeholder}</span>}
          {selected && <span className={styles.selected}>{selected.name}</span>}
          <div className={styles.arrow}>
            <ArrowDown />
          </div>
        </div>
        {isOpen && <div className={`${styles.list} ${scroll ? styles.scroll : ''}`}>{items}</div>}
      </div>
    </div>
  )
}
