import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import Iframe from 'react-iframe'
import { Button } from '../../../../components/Button/Button'
import { Favorites } from '../../../../components/Favorites/Favorites'
import AppState from '../../../../store/AppState'
import ModalState from '../../../../store/ModalState'
import styles from './AuctionMap.module.scss'

export const AuctionMap: FC<{ link: string }> = observer(({ link }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [like, setLike] = useState(false)
  const onChange = () => {
    ModalState.setModalMap({
      link: link,
      show: true,
    })
  }
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.like}>
        <Favorites />
      </div>
      <div className={styles.content} onClick={onChange}>
        <Iframe url={`${link}`} width="100%" height="100%" styles={{ border: 0 }} className={styles.iframe} />
      </div>
      <div className={styles.btn}>
        <Button color="blue" onClick={() => ModalState.setModalContact()}>
          Contactar propietario
        </Button>
      </div>
    </div>
  )
})
