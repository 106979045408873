import { CategoryScale, Chart as ChartJS, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { TGetStatAllApi, TMonthStat, getStatAllApi } from '../../../../../../api/statApi'
import AppState from '../../../../../../store/AppState'
import styles from './MainStatBidsBar.module.scss'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      border: {
        display: false,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    y: {
      beginAtZero: true,
      border: {
        display: false,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      },
    },
  },
  borderRadius: 10,
  borderSkipped: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      backgroundColor: '#000',
      bodyFontSize: 14,
      yAlign: 'bottom',
      callbacks: {
        label: function (context: { dataset: { label: string }; parsed: { y: number | bigint | null } }) {
          let label = ''

          if (label) {
            label += ': '
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y
          }
          return label
        },
        title: () => {
          return ''
        },
        labelColor: () => {
          return ''
        },
      },
      padding: 10,
    },
  },
}
const month: string[] = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
export const MainStatBidsBar: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [stat, setStat] = useState<TGetStatAllApi | null>(null)
  const [monthData, setMonthData] = useState<TMonthStat[] | null>(null)

  useEffect(() => {
    getStatAllApi().then((res) => setStat(res.data))
  }, [])

  useEffect(() => {
    if (stat) {
      const statAuctionLast = stat?.statisticBid.slice(-4)
      const statAuctionFirst = stat?.statisticBid.slice(0, 8)
      const statAuction = [...statAuctionLast, ...statAuctionFirst]
      const result = statAuction.map((item, i) => ({ month: month[i], count: item.count }))

      setMonthData(result)
    }
  }, [stat])

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.top}>
        <div className={styles.list}>
          <div className={styles.item}>
            <h5 className={styles.title}>Este mes</h5>
            <span className={styles.value}>{stat?.countBidsMonth}</span>
          </div>
          <div className={styles.item}>
            <h5 className={styles.title}>Últimos 6 meses</h5>
            <span className={styles.value}>{stat?.countBids6Month}</span>
          </div>
          <div className={styles.item}>
            <h5 className={styles.title}>Último año</h5>
            <span className={styles.value}>{stat?.countBidsYear}</span>
          </div>
        </div>
      </div>
      <div className={styles.chart}>
        <Bar
          options={options as any}
          data={{
            labels: monthData?.map((item) => item.month),
            datasets: [
              {
                label: 'Dataset 1',
                data: monthData?.map((item) => item.count),
                backgroundColor: '#F4F4F4',
                borderWidth: 0,
                hoverBackgroundColor: '#0093D3',
              },
            ],
          }}
        />
      </div>
    </div>
  )
})
