import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { TAuctionObj } from '../../../../api/auctionApi'
import AppState from '../../../../store/AppState'
import styles from './UsersAuction.module.scss'
import { UsersAuctionItem } from './UsersAuctionItem/UsersAuctionItem'

export const UsersAuction: FC<{ items: TAuctionObj[] }> = observer(({ items }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [len, setLen] = useState(0)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage] = useState(10)

  const list = items.slice(0, 10).map((item) => <UsersAuctionItem item={item} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.list}>{list}</div>
    </div>
  )
})
