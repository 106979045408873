import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FilterBlock } from '../../../../components/FilterBlock/FilterBlock'
import { Switch } from '../../../../components/Switch/Switch'
import AppState from '../../../../store/AppState'
import { UsersIndividuals } from '../UsersIndividuals/UsersIndividuals'
import { UsersProfessionals } from '../UsersProfessionals/UsersProfessionals'
import styles from './Users.module.scss'

const elements: JSX.Element[] = [<UsersProfessionals />, <UsersIndividuals />]
const data: string[] = ['Profesionales', 'Particulares']
const dataFilter: string[] = ['Data 1', 'Data 2', 'Data 3', 'Data 4', 'Data 5']
export const Users: FC = observer(() => {
  const navigator = useNavigate()
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [isActive, setIsActive] = useState(0)
  const onChange = () => {}

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.top}>
        <FilterBlock items={dataFilter} title="Usuarios" full />
      </div>
      <div className={styles.switch}>
        <Switch items={data} index={isActive} onChange={(i) => setIsActive(i)} />
      </div>
      <div className={styles.content}>{elements[isActive]}</div>
    </div>
  )
})
