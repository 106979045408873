import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AppState from '../../store/AppState'
import styles from './Switch.module.scss'

type TUsersSwitchProps = {
  items: string[]
  index: number
  onChange: (i: number) => void
}

export const Switch: FC<TUsersSwitchProps> = observer(({ index, onChange, items }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const list = items.map((item, i) => (
    <span className={`${styles.item} ${index === i ? styles.active : ''}`} onClick={() => onChange(i)} key={i}>
      {item}
    </span>
  ))
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.list}>{list}</div>
    </div>
  )
})
