import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { getProvincesApi } from '../../../../../api/provinceApi'
import { TAuctionSelectData, TAuctionSelectField } from '../../../../../pages/NewAuctionPage/components/PropertyForm/PropertyForm'
import AppState from '../../../../../store/AppState'
import { Button } from '../../../../Button/Button'
import { SelectField } from '../../../../SelectField/SelectField'
import { TextField } from '../../../../TextField/TextField'
import styles from './ModalRolesCreate.module.scss'

type TFormServicesObj = {
  value: string
  error: boolean
}

type TFormServices = {
  name: TFormServicesObj
  lastName: TFormServicesObj
  dni: TFormServicesObj
  phone: TFormServicesObj
  email: TFormServicesObj
}

export const ModalRolesCreate: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  const [form, setForm] = useState<TFormServices>({
    name: {
      value: '',
      error: false,
    },
    lastName: {
      value: '',
      error: false,
    },
    dni: {
      value: '',
      error: false,
    },
    phone: {
      value: '',
      error: false,
    },
    email: {
      value: '',
      error: false,
    },
  })
  const [permissionData, setPermissionData] = useState<TAuctionSelectData[]>([])

  const [permission, setPermission] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'name' || name === 'lastName' || name === 'dni' || name === 'phone' || name === 'email') {
      setForm({ ...form, [name]: { value: e.currentTarget.value, error: !!true } })
    }
  }

  const changeSelect = (name: string, item: TAuctionSelectData) => {
    switch (name) {
      case 'permission':
        setPermission((prev) => ({ ...prev, value: item, error: false }))
        break
    }
  }

  const onSubmit = () => {
    const result = Object.entries(form).map(([key, value]) => [key, { value: value.value, error: value.value === '' ? true : false }])
    setForm(Object.fromEntries(result))
    setPermission((prev) => ({ ...prev, value: prev.value, error: prev.value === null ? true : false }))
    const error = Object.entries(form).some(([key, value]) => value.value === '')

    /* if (!error && permission !== null) {
      const formData = new FormData()
      formData.append('code', form.code.value)
      formData.append('city', form.city.value)
      formData.append('provinceId', String(province.value?.id))

      postZipCodeApi(formData)
        .then((res) => {
          UpdateState.setZipCodesUpdate()
          ModalState.hideModalCodes()
        })
        .catch((err) => console.log(err))
    } */
  }

  useEffect(() => {
    getProvincesApi().then((res) => {
      setPermissionData(res.data)
    })
  }, [])

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h3 className={styles.title}>Nuevo usuario</h3>
      <div className={styles.wrap}>
        <div className={styles.group}>
          <div className={styles.input}>
            <TextField label={'Nombre*'} name="name" error={form.name.error} value={form.name.value} placeholder={'Introduce nombre'} onChange={changeForm} />
          </div>
          <div className={styles.input}>
            <TextField label={'Apellidos*'} name="lastName" error={form.lastName.error} value={form.lastName.value} placeholder={'Introduce apellidos'} onChange={changeForm} />
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.input}>
            <TextField label={'DNI*'} name="dni" error={form.dni.error} value={form.dni.value} placeholder={'Introduce DNI'} onChange={changeForm} />
          </div>
          <div className={styles.input}>
            <TextField label={'Teléfono*'} name="phone" error={form.phone.error} value={form.phone.value} placeholder={'Introduce teléfono'} onChange={changeForm} />
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.input}>
            <TextField label={'Email*'} name="email" error={form.email.error} value={form.email.value} placeholder={'Introduce email'} onChange={changeForm} />
          </div>
          <div className={styles.input}>
            <SelectField
              scroll={true}
              label={'Permiso*'}
              placeholder={'Selecciona permiso'}
              list={permissionData}
              selected={permission.value}
              changeSelected={changeSelect}
              name="permission"
              error={permission.error}
            />
          </div>
        </div>
      </div>
      <div className={styles.btn} onClick={onSubmit}>
        <Button>Crear usuario</Button>
      </div>
    </div>
  )
})
