import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import img from '../../../../assets/auctionPage/energy.png'
import AppState from '../../../../store/AppState'
import styles from './AuctionEnergy.module.scss'

export const AuctionEnergy: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <img src={img} alt="" />
    </div>
  )
})
