import { apiService } from '../services/apiServices/apiService'

export type TUser = {
  id: string
  email: string
  type: null | 'PROFESSIONAL' | 'PARTICULAR' | 'ADMIN'
  ParticularData: null | {
    NIF: string
    address: string
    city: string
    firstSurname: string
    id: string
    name: string
    phone: string
    postcode: string
    provinceId: string
    secondSurname: string
    userId: string
  }
  ProfessionalData: null | {
    CIF: string
    address: string
    city: string
    company: string
    contactPerson: string
    id: string
    logoData: string
    logoName: string
    logoType: string
    phone: string
    postcode: string
    provinceId: string
    tradeName: string
    userId: string
  }
  ProfessionalPlan: null | {
    active: boolean
    id: string
    planId: string
    userId: string
  }
}

type TResLoginApi = {
  accessToken: string
  user: TUser
}

export const loginApi = async (data: { email: string; password: string }) => {
  return await apiService<TResLoginApi>('post', 'auth/login', data)
}

type TResRegistrationApi = {
  accessToken: string
  user: TUser
}

export const registrationUserApi = async (data: { email: string; password: string }) => {
  return await apiService<TResRegistrationApi>('post', 'auth/registration', data)
}

type TResGetUserDataApi = TUser

export const getUserDataApi = async () => {
  return await apiService<TResGetUserDataApi>('get', 'auth/data')
}

type TResAddDataParticularApi = {
  id: string
  email: string
  type: 'PARTICULAR'
  ParticularData: {
    NIF: string
    address: string
    city: string
    firstSurname: string
    id: string
    name: string
    phone: string
    postcode: string
    provinceId: string
    secondSurname: string
    userId: string
  }
  ProfessionalData: null
  ProfessionalPlan: null
}

export const addDataParticularApi = async (data: {
  name: string
  firstSurname: string
  secondSurname: string
  NIF: string
  phone: string
  address: string
  postcode: string
  city: string
  provinceId: string
}) => {
  return await apiService<TResAddDataParticularApi>('post', 'auth/addDataParticular', data)
}

type TResAddDataProfessionalApi = {
  id: string
  email: string
  type: 'PROFESSIONAL'
  ParticularData: null
  ProfessionalData: null | {
    CIF: string
    address: string
    city: string
    company: string
    contactPerson: string
    id: string
    logoData: string
    logoName: string
    logoType: string
    phone: string
    postcode: string
    provinceId: string
    tradeName: string
    userId: string
  }
  ProfessionalPlan: null
}

export const addDataProfessionalApi = async (formData: FormData) => {
  return await apiService<TResAddDataProfessionalApi>('post', 'auth/addDataProfessional', formData, { headers: formData })
}

type TResAddPlanProfessionalApi = {
  id: string
  email: string
  type: 'PROFESSIONAL'
  ParticularData: null
  ProfessionalData: {
    CIF: string
    address: string
    city: string
    company: string
    contactPerson: string
    id: string
    logoData: string
    logoName: string
    logoType: string
    phone: string
    postcode: string
    provinceId: string
    tradeName: string
    userId: string
  }
  ProfessionalPlan: {
    active: false
    id: string
    planId: string
    userId: string
  }
}

export const addPlanProfessionalApi = async (data: { planId: string }) => {
  return await apiService<TResAddPlanProfessionalApi>('post', 'auth/addPlanProfessional', data)
}

type TResPaymentPlanApi = {
  id: string
  email: string
  type: 'PROFESSIONAL'
  ParticularData: null
  ProfessionalData: {
    CIF: string
    address: string
    city: string
    company: string
    contactPerson: string
    id: string
    logoData: string
    logoName: string
    logoType: string
    phone: string
    postcode: string
    provinceId: string
    tradeName: string
    userId: string
  }
  ProfessionalPlan: {
    active: true
    id: string
    planId: string
    userId: string
  }
}

export const paymentPlanApi = async (data: { idPayment: string; userName: string; userSurnames: string; address: string; postalCode: string; phone: string; countryId: string }) => {
  return await apiService<TResPaymentPlanApi>('post', 'auth/paymentPlan', data)
}
