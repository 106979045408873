import { motion } from 'framer-motion'
import { FC, useState } from 'react'
import { TLastFilter } from '../../../../../api/searchApi'
import ModalState from '../../../../../store/ModalState'
import { FADE_IN } from '../../../../../utils/animation/fade'
import { dateFormat } from '../../../../../utils/date.utils'
import { priceFormat } from '../../../../../utils/price.utils'
import {
	ApartmentIcon,
	DownIcon,
	FactoryIcon,
	FarmsIcon,
	GroundIcon,
	HouseIcon,
	OthersIcon,
	PenthouseNewIcon,
	SearchIcon,
	SolarIcon,
	StoresIcon,
	VillasIcon,
} from '../../../../Icons/Icons'
import { TIcons } from '../../ModalSearch'
import styles from './SearchResultItem.module.scss'

export type TSearchResultItem = {
	type: 'Casa' | 'Piso' | 'Ático' | 'Bajo' | 'Chalets' | 'Locales' | 'Naves' | 'Fincas' | 'Solar' | 'Otros' | ''
	name: string
	location: string
	postcode: string
	startDate: string
	endDate: string
	endPrice: number
	price: number
	bedrooms: number
	bathrooms: number
	charat: string[] /* ['Piscina','Garaje','Terraza','Jardin'] */
}

type TSearchResultProps = TSearchResultItem

const dataIcons: TIcons[] = [
	{
		icon: <HouseIcon />,
		name: 'casa',
	},
	{
		icon: <ApartmentIcon />,
		name: 'piso',
	},
	{
		icon: <PenthouseNewIcon />,
		name: 'ático',
	},
	{
		icon: <GroundIcon />,
		name: 'bajo',
	},
	{
		icon: <VillasIcon />,
		name: 'chalets',
	},
	{
		icon: <StoresIcon />,
		name: 'locales',
	},
	{
		icon: <FactoryIcon />,
		name: 'naves',
	},
	{
		icon: <FarmsIcon />,
		name: 'fincas',
	},
	{
		icon: <SolarIcon />,
		name: 'solar',
	},
	{
		icon: <OthersIcon />,
		name: 'otros',
	},
]

type TProps = {
	item: TLastFilter
}
export const SearchResultItem: FC<TProps> = ({ item }) => {
	const [isActive, setIsActive] = useState(false)
	const index = dataIcons.find((el) => el.name === item.typeProperty)
	return (
		<div className={styles.root}>
			<div className={styles.top}>
				{index ? (
					<span className={styles.icon}>{index.icon}</span>
				) : (
					<div className={styles.more}>
						<span></span>
						<span></span>
						<span></span>
					</div>
				)}
				<div className={styles.content}>
					<h3 className={styles.title}>{item.typeProperty ? item.typeProperty : '...'}</h3>
					<div className={styles.text}>
						{item.city ? <span>{item.city},</span> : '...'} {item.postcode ? <span>{item.postcode},</span> : '...'}
					</div>
					<span className={`${styles.drop} ${isActive ? styles.active : ''}`} onClick={() => setIsActive(!isActive)}>
						<DownIcon />
					</span>
				</div>
			</div>
			{isActive && (
				<motion.div {...FADE_IN} className={styles.dropList}>
					<div className={styles.stat}>
						<div className={styles.left}>
							<h4 className={styles.title}>Fechas</h4>
							<div className={styles.text}>
								Del {item.createdAt ? dateFormat(new Date(item.createdAt).getTime()) : '...'} al{' '}
								{item.endAuction ? dateFormat(new Date(item.endAuction).getTime()) : '...'}
							</div>
						</div>
						<div className={styles.right}>
							<div className={styles.block}>
								<h4 className={styles.title}>Última puja</h4>
								<span className={styles.price}>{item.bidMin ? priceFormat(item.bidMin) : '...'}</span>
							</div>
							<div className={styles.block}>
								<h4 className={styles.title}>Precio</h4>
								<span className={styles.price}>{item.priceMin ? priceFormat(item.priceMin) : '...'}</span>
							</div>
						</div>
					</div>
					<div className={styles.center}>
						<div className={styles.block}>
							<h4 className={styles.title}>Número de dormitorios</h4>
							<span className={styles.value}>{item.bedrooms ? item.bedrooms : '...'}</span>
						</div>
						<div className={styles.block}>
							<h4 className={styles.title}>Número de baños</h4>
							<span className={styles.value}>{item.bathrooms ? item.bathrooms : '...'}</span>
						</div>
					</div>
					<div className={styles.charat}>
						<h4 className={styles.title}>Caracteristicas</h4>
						<div className={styles.list}>
							{item.garage && <div className={styles.item}>garage</div>}
							{item.garden && <div className={styles.item}>garden</div>}
							{item.pool && <div className={styles.item}>pool</div>}
							{item.terrace && <div className={styles.item}>terrace</div>}
						</div>
					</div>
					<div className={styles.bottom} onClick={() => ModalState.showModalSearch()}>
						<SearchIcon />
						Realizar busqueda
					</div>
				</motion.div>
			)}
		</div>
	)
}
