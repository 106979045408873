import { FC } from 'react'
import AppState from '../../store/AppState'
import { CheckIcon } from '../Icons/Icons'
import styles from './CheckBox.module.scss'

type TProps = {
  isCheck: boolean
  onChange: () => void
  label: string
}

export const CheckBox: FC<TProps> = ({ isCheck, onChange, label }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`} onClick={onChange}>
      {isCheck ? (
        <span className={styles.check}>
          <CheckIcon />
        </span>
      ) : (
        <span className={styles.noCheck} />
      )}
      <span className={styles.label}>{label}</span>
    </div>
  )
}
