import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useState } from 'react'
import { Button } from '../../../../components/Button/Button'
import { RightArrowIcon } from '../../../../components/Icons/Icons'
import { TextareaField } from '../../../../components/TextareaField/TextareaField'
import AppState from '../../../../store/AppState'
import AuctionState from '../../../../store/AuctionState'
import { TAuctionFormInput } from '../PropertyForm/PropertyForm'
import styles from './DescriptionForm.module.scss'

export type TPropertyForm = {
  description: TAuctionFormInput
  internalObservations: TAuctionFormInput
}

export const DescriptionForm: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const step = AuctionState.step
  const [form, setForm] = useState<TPropertyForm>({
    description: {
      name: AuctionState.obj.description !== '' ? AuctionState.obj.description : '',
      error: false,
    },
    internalObservations: {
      name: AuctionState.obj.internalObservations !== '' ? AuctionState.obj.internalObservations : '',
      error: false,
    },
  })

  const changeForm = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name
    if (name === 'description' || name === 'internalObservations') {
      setForm({ ...form, [name]: { name: e.currentTarget.value, error: !!true } })
    }
  }
  const onSubmit = () => {
    const data = {
      description: form.description.name,
      internalObservations: form.internalObservations.name,
    }
    AuctionState.setObj(data)
    AuctionState.setNextStep()

    console.log(AuctionState.obj)
  }
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.form}>
        <div className={styles.wrap}>
          <div className={styles.group}>
            <div className={styles.input}>
              <TextareaField label={'Descripción'} name="description" error={false} value={form.description.name} placeholder={'Descripción del inmueble'} onChange={changeForm} />
            </div>
            <div className={styles.input}>
              <TextareaField
                label={'Observaciones internas'}
                name="internalObservations"
                error={false}
                value={form.internalObservations.name}
                placeholder={'Observaciones internas del inmueble'}
                onChange={changeForm}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.navigation}>
        {step > 0 && (
          <span className={styles.prev} onClick={() => AuctionState.setPrevStep()}>
            <RightArrowIcon />
            Volver al paso anterior
          </span>
        )}
        <span className={styles.next} onClick={onSubmit}>
          <Button color="blue">Continuar</Button>
        </span>
      </div>
    </div>
  )
})
