import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TAuctionObj } from '../../../../../api/auctionApi'
import { LocationIcon } from '../../../../../components/Icons/Icons'
import { urlApi } from '../../../../../config/apiConfig'
import AppState from '../../../../../store/AppState'
import { dateFormatFull } from '../../../../../utils/date.utils'
import { priceFormat } from '../../../../../utils/price.utils'
import styles from './UsersBidsItem.module.scss'
import {orderBy, sortBy} from "lodash";

type TProps = {
  item: TAuctionObj
  status: 'APPROVED' | 'REJECTED' | 'UNDER_CONSIDERATION' | 'PROCESS'

  userId: string
}

export const UsersBidsItem: FC<TProps> = observer(({ item, status, userId }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.img} style={(item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id) ? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})` } : undefined}></div>
      <div className={styles.text}>
        <span className={styles.ref}>Ref: {item.reference}</span>
        <h4 className={styles.title}>{item.TypeProperty && item.TypeProperty.name}</h4>
        <div className={styles.location}>
          <LocationIcon />
          <div className={styles.wrap}>
            {item.city && <span>{item.city},</span>} {item.Province && <span>{item.Province.name},</span>} <span>{item.address && item.address}</span>
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.block}>
          <h4 className={styles.title}>{priceFormat(item.auctionStartingPrice)}</h4>
          <span className={styles.text}>Precio de salida</span>
        </div>
        <div className={styles.block}>
          <h4 className={styles.title}>{priceFormat(orderBy(item.Bid, 'sum', 'desc')[0].sum)}</h4>
          <span className={styles.text}>Última puja</span>
        </div>
        <div className={styles.block}>
          <h4 className={styles.title}>{dateFormatFull(orderBy(item.Bid, 'sum', 'desc')[0].createdAt)}</h4>
          <span className={styles.text}>Fecha última puja</span>
        </div>
        <div className={styles.block}>
          <h4 className={styles.title}>{priceFormat(orderBy(item.Bid.filter(i => i.userId === userId), 'sum', 'desc')[0].sum)}</h4>
          <span className={styles.text}>Mi última puja</span>
        </div>
        <div className={styles.block}>
          <h4 className={styles.title}>{dateFormatFull(orderBy(item.Bid.filter(i => i.userId === userId), 'sum', 'desc')[0].createdAt)}</h4>
          <span className={styles.text}>Fecha de su última puja</span>
        </div>
      </div>
      {status === 'APPROVED' && <span className={`${styles.status} ${styles.green}`}>ActivA</span>}
      {status === 'REJECTED' && <span className={`${styles.status} ${styles.red}`}>Cerrada</span>}
      {status === 'UNDER_CONSIDERATION' && <span className={`${styles.status} ${styles.blue}`}>Adjudicada</span>}
    </div>
  )
})
