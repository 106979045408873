import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { TAuctionObj, getAuctionAllApi } from '../../../../api/auctionApi'
import AppState from '../../../../store/AppState'
import { MainLatest } from '../MainLatest/MainLatest'
import { MainSlider } from '../MainSlider/MainSlider'
import { MainStat } from '../MainStat/MainStat'
import { MainValidate } from '../MainValidate/MainValidate'
import styles from './MainAdmin.module.scss'
import {getBidsApi} from '../../../../api/bidApi'

export const MainAdmin: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [mainList, setMainList] = useState<TAuctionObj[]>([])
  const [latestList, setLatestList] = useState<TAuctionObj[]>([])
  const [validList, setValidList] = useState<TAuctionObj[]>([])
  useEffect(() => {
    getAuctionAllApi({ take: 5, skip: 0 }).then((res) => setMainList(res.data.auctions))


    getAuctionAllApi({ take: 2, skip: 0, which: 'underConsideration' }).then((res) => setValidList(res.data.auctions))

    getBidsApi({take: 5, skip: 0, type: 'active'}).then(res => setLatestList(res.data.auctions))
  }, [])
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.slider}>
        <MainSlider items={mainList} />
      </div>
      <div className={styles.validate}>
        <MainValidate items={validList} />
      </div>
      <div className={styles.latest}>
        <MainLatest items={latestList} />
      </div>
      <div className={styles.stat}>
        <MainStat />
      </div>
    </div>
  )
})
