import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import AppState from '../../store/AppState'
import { ArrowDown } from '../Icons/Icons'
import styles from './Filter.module.scss'

type TFilterProps = {
  items: string[]
  defaultValue: string
  onChange: (item: string) => void
}
export const Filter: FC<TFilterProps> = observer(({ items, onChange, defaultValue }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [select, setSelect] = useState<string>(defaultValue)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    onChange(select)
  }, [select])

  const onActive = (item: string) => {
    setSelect(item)
    setIsActive(false)
  }

  const list = items.map((item) => (
    <div className={styles.item} onClick={() => onActive(item)} key={item}>
      {item}
    </div>
  ))

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.select} onClick={() => setIsActive(!isActive)}>
        <span className={styles.value}>{select}</span>
        <div className={`${styles.icon} ${isActive ? styles.active : ''}`}>
          <ArrowDown />
        </div>
      </div>
      <div className={`${styles.list} ${isActive ? styles.active : ''}`}>{list}</div>
    </div>
  )
})
