import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TAccessObj } from '../../../../api/accessApi'
import { HomeIconHover } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import { dateFormatFull, dateTime } from '../../../../utils/date.utils'
import { nameFormat } from '../../../../utils/name.utils'
import styles from './AccessItem.module.scss'

export const AccessItem: FC<{ item: TAccessObj; role: 'PROFESSIONAL' | 'PARTICULAR' }> = observer(({ item, role }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.left}>
        {item.ProfessionalData && (
          <>
            <div className={styles.icon}>
              <HomeIconHover />
            </div>
            <div className={styles.content}>
              <h3 className={styles.title}>{item.ProfessionalData.company}</h3>
              <span className={styles.text}>CIF: {item.ProfessionalData.CIF}</span>
            </div>
          </>
        )}
        {item.ParticularData && (
          <>
            <div className={styles.name}>{nameFormat(`${item.ParticularData.name} ${item.ParticularData.firstSurname}`)}</div>
            <div className={styles.content}>
              <h3 className={styles.title}>{item.ParticularData.name} {item.ParticularData.firstSurname} {item.ParticularData.secondSurname}</h3>
              <span className={styles.text}>DNI: {item.ParticularData.NIF}</span>
            </div>
          </>
        )}
      </div>
      <div className={styles.right}>
        <div className={styles.date}>
          {dateFormatFull(item.accessControl)} · {dateTime(item.accessControl)}
        </div>
        <span className={styles.text}>Last connection</span>
      </div>
    </div>
  )
})
