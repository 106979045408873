import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Switch } from '../../components/Switch/Switch'
import AppState from '../../store/AppState'
import styles from './AccessPage.module.scss'
import { AccessPart } from './components/AccessPart/AccessPart'
import { AccessProff } from './components/AccessProff/AccessProff'
const dataSwitch: string[] = ['Professionals', 'Particulares']
const elements: JSX.Element[] = [<AccessProff />, <AccessPart />]
export const AccessPage: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [isActive, setIsActive] = useState(0)

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h2 className={styles.title}>Accesos</h2>
      <div className={styles.switch}>
        <Switch items={dataSwitch} index={isActive} onChange={(i) => setIsActive(i)} />
      </div>
      <div className={styles.content}>{elements[isActive]}</div>
    </div>
  )
})
