import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { getUserDataApi } from './api/authApi'
import { useWindowDimensions } from './hooks/useWindowDimensions'
import { checkAuthLocalStorage } from './services/localStorageServices/authLocalStorageService'
import { RoutesService } from './services/mainServices/RoutesService'
import AppState from './store/AppState'
import AuthState from './store/AuthState'

export const App: FC = observer(() => {
  const w = AppState.widthWindow
  const isMobile = w <= 768
  const [isReady, setIsReady] = useState(false)
  const { width } = useWindowDimensions()

  useEffect(() => {
    const accessToken = checkAuthLocalStorage()
    if (accessToken) {
      AuthState.setAccessToken(accessToken)
      getUserDataApi().then((res) => {
        AuthState.setUser(res.data)
        if (res.data.type === 'PARTICULAR') {
          AuthState.setIsAuth(true)
        }
        if (res.data.type === 'PROFESSIONAL' && res.data.ProfessionalPlan && res.data.ProfessionalPlan.active === true) {
          AuthState.setIsAuth(true)
        }

        if (res.data.type === 'ADMIN') {
          AuthState.setIsAuth(true)
        }

        setIsReady(true)
      })
        .catch(err => {
          if (err && err.response.status === 401) {
            AuthState.logout()
          }
        })
    } else {
      setIsReady(true)
    }
  }, [])

  useEffect(() => {
    AppState.setWidthWindow(width)
  }, [width])

  if (isReady) {
    return <RoutesService />
  }

  return <h1 style={{ fontSize: isMobile ? '30px' : '150px' }}>LOADING</h1>
})
