import { ChangeEvent, FC } from 'react'
import AppState from '../../store/AppState'
import styles from './TextareaField.module.scss'

type TProps = {
  label: string
  type?: string
  value?: string
  name?: string
  placeholder?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  error?: boolean
}

export const TextareaField: FC<TProps> = ({ label, name, value, placeholder, error, onChange, type = 'text' }) => {
  const id = String(Math.floor(Math.random() * 100000))
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <textarea id={id} name={name} value={value} className={`${styles.input} ${value === '' && error ? styles.error : ''}`} placeholder={placeholder} onChange={onChange} />
    </div>
  )
}
