import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import imgHover1 from '../../../../../assets/bids/bids-img1-hover.png'
import img1 from '../../../../../assets/bids/bids-img1.png'
import imgHover2 from '../../../../../assets/bids/bids-img2-hover.png'
import img2 from '../../../../../assets/bids/bids-img2.png'
import AppState from '../../../../../store/AppState'
import ModalState from '../../../../../store/ModalState'
import styles from './ModalAddBidsHandler.module.scss'

export const ModalAddBidsHandler: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  const onModal = () => {
    ModalState.toggleModalLegal(true)
    ModalState.toggleModalBids(false)
  }

  const onUser = () => {
    ModalState.changeStatusBids('user')
  }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h2 className={styles.title}>¿Cómo vas a pujar?</h2>
      <div className={styles.content}>
        <div className={styles.block}>
          <div className={styles.wrap} onClick={onModal}>
            <span className={styles.img} style={{ backgroundImage: `url(${img1})` }}></span>
            <span className={styles.img} style={{ backgroundImage: `url(${imgHover1})` }}></span>
          </div>
          <p className={styles.text}>Yo mismo</p>
        </div>
        <div className={styles.block}>
          <div className={styles.wrap} onClick={onUser}>
            <span className={styles.img} style={{ backgroundImage: `url(${img2})` }}></span>
            <span className={styles.img} style={{ backgroundImage: `url(${imgHover2})` }}></span>
          </div>
          <p className={styles.text}>Por otra persona</p>
        </div>
      </div>
    </div>
  )
})
