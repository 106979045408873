import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TAuctionObj } from '../../../../../api/auctionApi'
import { urlApi } from '../../../../../config/apiConfig'
import AppState from '../../../../../store/AppState'
import { dateFormatFull } from '../../../../../utils/date.utils'
import { priceFormat } from '../../../../../utils/price.utils'
import styles from './UsersDepositsItem.module.scss'
import {orderBy} from "lodash";

type TProps = {
  item: TAuctionObj
  userId: string
}

export const UsersDepositsItem: FC<TProps> = observer(({ item, userId }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const status = item.status

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.wrap}>
        <div className={styles.img} style={(item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id) ? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})` } : undefined}></div>
        <div className={styles.text}>
          <h4 className={styles.title}>{item.TypeProperty && item.TypeProperty.name}</h4>
          <span className={styles.ref}>Referencia: {item.reference}</span>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.block}>
          <h4 className={styles.title}>{priceFormat(item.auctionStartingPrice)}</h4>
          <span className={styles.text}>Precio de salida</span>
        </div>
        <div className={styles.block}>
          <h4 className={styles.title}>{priceFormat(orderBy(item.Bid, 'sum', 'desc')[0].sum)}</h4>
          <span className={styles.text}>Precio actual</span>
        </div>
        <div className={styles.block}>
          <h4 className={styles.title}>{dateFormatFull(item.Deposit.filter(i => i.userId === userId)[0].createdAt)}</h4>
          <span className={styles.text}>Fecha depósito</span>
        </div>
      </div>
      {status === 'APPROVED' && <span className={`${styles.status} ${styles.green}`}>ActivA</span>}
      {status === 'REJECTED' && <span className={`${styles.status} ${styles.red}`}>Cerrada</span>}
      {status === 'UNDER_CONSIDERATION' && <span className={`${styles.status} ${styles.blue}`}>Adjudicada</span>}
      <div className={styles.price}>
        <h4 className={styles.title}>{priceFormat(item.Deposit.filter(i => i.userId === userId)[0].price)}</h4>
        <span className={styles.text}>Depósito constituido</span>
      </div>
    </div>
  )
})
