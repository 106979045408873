import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TLastFilter } from '../../../../../api/searchApi'
import AppState from '../../../../../store/AppState'
import { SearchResultItem } from '../SearchResultItem/SearchResultItem'
import styles from './SearchResult.module.scss'

type TProps = {
  filters: TLastFilter[]
}

export const SearchResult: FC<TProps> = observer(({ filters }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  const list = filters.map((item, i) => <SearchResultItem item={item} key={i} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h3 className={styles.title}>Mis últimas {filters.length} búsquedas</h3>
      <div className={styles.list}>{list}</div>
    </div>
  )
})
