import { makeAutoObservable } from 'mobx'

class AppState {
  widthWindow: number = 0
  constructor() {
    makeAutoObservable(this)
  }

  setWidthWindow = (width: number) => {
    return (this.widthWindow = width)
  }
}

export default new AppState()
