import { FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { DownIcon } from '../../../../Icons/Icons'
import styles from './Menu.module.scss'
import UserState from '../../../../../store/UserState'

export type TMenuDrop = {
  title: string
  path: string
}

export type TMenu = {
  title: string
  path: string
  dropList?: TMenuDrop[]
  innerDrop?: TMenuDrop
}
export const Menu: FC<{ items: TMenu[] }> = ({ items }) => {
  const { pathname } = useLocation()
  const list = items.map((item) => (
    <NavLink onClick={item.path === '/users' ? () => UserState.reset() : undefined} to={item.path} className={`${styles.item} ${pathname === item.path ? styles.active : ''} ${item.dropList ? styles.drop : ''}`} key={item.title}>
      {item.title}
      {item.dropList && (
        <span className={styles.drop}>
          {item.dropList.map((el, i) => (
            <NavLink to={el.path} className={styles.link} key={el.title}>
              {el.title}
              <DownIcon />
            </NavLink>
          ))}
        </span>
      )}
      {item.dropList && (
        <span className={styles.icon}>
          <DownIcon />
        </span>
      )}
    </NavLink>
  ))
  return (
    <nav className={styles.root}>
      <div className={styles.list}>{list}</div>
    </nav>
  )
}
