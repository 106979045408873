import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { getProvincesApi } from '../../../../../api/provinceApi'
import { postZipCodeApi } from '../../../../../api/zipcodeApi'
import { TAuctionSelectData, TAuctionSelectField } from '../../../../../pages/NewAuctionPage/components/PropertyForm/PropertyForm'
import AppState from '../../../../../store/AppState'
import ModalState from '../../../../../store/ModalState'
import UpdateState from '../../../../../store/UpdateState'
import { Button } from '../../../../Button/Button'
import { SelectField } from '../../../../SelectField/SelectField'
import { TextField } from '../../../../TextField/TextField'
import styles from './ModalCodesCreate.module.scss'

type TFormServicesObj = {
  value: string
  error: boolean
}

type TFormServices = {
  code: TFormServicesObj
  city: TFormServicesObj
}

export const ModalCodesCreate: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  const [form, setForm] = useState<TFormServices>({
    code: {
      value: '',
      error: false,
    },
    city: {
      value: '',
      error: false,
    },
  })
  const [provinceData, setProvinceData] = useState<TAuctionSelectData[]>([])

  const [province, setProvince] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'code' || name === 'city') {
      setForm({ ...form, [name]: { value: e.currentTarget.value, error: !!true } })
    }
  }

  const changeSelect = (name: string, item: TAuctionSelectData) => {
    switch (name) {
      case 'province':
        setProvince((prev) => ({ ...prev, value: item, error: false }))
        break
    }
  }

  const onSubmit = () => {
    const result = Object.entries(form).map(([key, value]) => [key, { value: value.value, error: value.value === '' ? true : false }])
    setForm(Object.fromEntries(result))
    setProvince((prev) => ({ ...prev, value: prev.value, error: prev.value === null ? true : false }))
    const error = Object.entries(form).some(([key, value]) => value.value === '')

    if (!error && province !== null) {
      const formData = new FormData()
      formData.append('code', form.code.value)
      formData.append('city', form.city.value)
      formData.append('provinceId', String(province.value?.id))

      postZipCodeApi(formData)
        .then((res) => {
          UpdateState.setZipCodesUpdate()
          ModalState.hideModalCodes()
        })
        .catch((err) => console.log(err))
    }
  }

  useEffect(() => {
    getProvincesApi().then((res) => {
      setProvinceData(res.data)
    })
  }, [])

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h3 className={styles.title}>Nuevo código postal</h3>
      <div className={styles.wrap}>
        <div className={styles.group}>
          <div className={styles.input}>
            <TextField type="number" label={'Código postal*'} name="code" error={form.code.error} value={form.code.value} placeholder={'Introduce código postal'} onChange={changeForm} />
          </div>
          <div className={styles.input}>
            <TextField label={'Ciudad*'} name="city" error={form.city.error} value={form.city.value} placeholder={'Intoduce ciudad'} onChange={changeForm} />
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.input}>
            <SelectField
              scroll={true}
              label={'Provincia*'}
              placeholder={'Selecciona provincia'}
              list={provinceData}
              selected={province.value}
              changeSelected={changeSelect}
              name="province"
              error={province.error}
            />
          </div>
        </div>
      </div>
      <div className={styles.btn} onClick={onSubmit}>
        <Button>Crear código posta</Button>
      </div>
    </div>
  )
})
