import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Button } from '../../../../components/Button/Button'
import { AcortarIcon, CalendarIcon, ExitIcon, RightArrowIcon } from '../../../../components/Icons/Icons'
import { SelectField } from '../../../../components/SelectField/SelectField'
import { TextField } from '../../../../components/TextField/TextField'
import AuctionState from '../../../../store/AuctionState'
import { TAuctionFormInput, TAuctionSelectData, TAuctionSelectField } from '../PropertyForm/PropertyForm'

import 'react-datepicker/dist/react-datepicker.css'

import { postUploadFileApi, TAuctionFileApi } from '../../../../api/auctionApi'
import { CheckBox } from '../../../../components/CheckBox/CheckBox'
import AppState from '../../../../store/AppState'
import { ImageFile } from '../ImageFile/ImageFile'
import styles from './AuctionForm.module.scss'

export type TPropertyForm = {
	plotMeters: TAuctionFormInput
	useMeters: TAuctionFormInput
}

export type TDate = {
	name: Date | null
	error: boolean
}

export const AuctionForm: FC = observer(() => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const [showCalendar, setShowCalendar] = useState(false)
	const ref = AuctionState.obj.reference
	const step = AuctionState.step
	const size = String(Math.floor(Math.random() * 1000))
	const [file, setFile] = useState<null | File[]>(null)
	const [result, setResult] = useState<TAuctionFileApi[]>([])
	const [check, setCheck] = useState(true)
	const [startPrice, setStartPrice] = useState({
		name: AuctionState.obj.auctionStartingPrice !== 0 ? AuctionState.obj.auctionStartingPrice : '',
		error: false,
	})
	const [date, setDate] = useState<TDate>({ name: AuctionState.obj.endAuction !== 0 ? new Date(AuctionState.obj.endAuction) : null, error: false })
	const [trancheData, setTrancheData] = useState<TAuctionSelectData[]>([
		{ id: '100', name: '100' },
		{ id: '250', name: '250' },
		{ id: '500', name: '500' },
		{ id: '750', name: '750' },
		{ id: '900', name: '900' },
		{ id: '1000', name: '1000' },
	])
	const [tranche, setTranche] = useState<TAuctionSelectField>({
		value: null,
		error: false,
	})

	useEffect(() => {
		if (file) {
			const formData = new FormData()
			formData.append('reference', String(ref))
			for (let i = 0; i < file.length; i++) {
				formData.append('doc', file[i])
			}

			// formData.append('doc', file)
			postUploadFileApi(formData)
				.then((res) => {
					const data = res.data
					setResult((prev) => [...prev].concat(res.data))
					// setResult((prev) => [...prev, { ...data, size: size }])
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [file])

	useEffect(() => {
		AuctionState.setDoc(result)
	}, [result])

	const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
		setStartPrice({ name: e.currentTarget.value, error: !!true })
	}

	const changeSelect = (name: string, item: TAuctionSelectData) => {
		switch (name) {
			case 'tranche':
				setTranche((prev) => ({
					...prev,
					value: item,
					error: false,
				}))
				break
		}
	}
	const changeFile = (file: File[]) => {
		setFile(file)
	}

	const removeFile = (id: string) => {
		console.log(id)
		setResult((prev) => prev.filter((el) => el.id !== id))
	}
	const handleDateChange = (date: any) => {
		setDate((prev) => ({ ...prev, name: date, error: false }))
		setShowCalendar(false)
	}
	const onSubmit = () => {
		setTranche((prev) => ({ ...prev, error: prev.value === null ? true : false }))
		setStartPrice((prev) => ({ ...prev, error: prev.name === '' ? true : false }))
		setDate((prev) => ({ ...prev, error: prev.name === null ? true : false }))

		if (date.name !== null) {
			const data = {
				auctionStartingPrice: Number(startPrice.name),
				endAuction: new Date(String(date.name)).getTime(),
				biddingTranche: tranche.value !== null ? tranche.value.name : undefined,
			}
			AuctionState.setObj(data)
			AuctionState.setNextStep()
		}
	}
	const list = result.map((item) => (
		<div className={styles.item} key={item.id}>
			{item.name.split('.')[1] === 'png' ||
			item.name.split('.')[1] === 'jpeg' ||
			item.name.split('.')[1] === 'jpg' ||
			item.name.split('.')[1] === 'pdf' ? (
				<span className={styles.loader}>100%</span>
			) : (
				<span className={styles.acortar}>
					<AcortarIcon />
				</span>
			)}
			<div className={styles.info}>
				<h5 className={styles.title}>{item.name}</h5>
				{item.name.split('.')[1] === 'png' ||
				item.name.split('.')[1] === 'jpeg' ||
				item.name.split('.')[1] === 'jpg' ||
				item.name.split('.')[1] === 'pdf' ? (
					<span className={styles.size}>
						{item.size}Kb/{item.size}Kb
					</span>
				) : (
					<span className={styles.size}>{item.size}</span>
				)}
			</div>
			<span className={styles.remove} onClick={() => removeFile(item.id)}>
				<ExitIcon />
			</span>
		</div>
	))
	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<div className={styles.form}>
				<div className={styles.wrap}>
					<div className={styles.single}>
						<div className={styles.input}>
							<TextField
								type="number"
								label={'Precio salida de subasta'}
								name="startPrice"
								error={false}
								value={String(startPrice.name)}
								placeholder={'Introduce una cantidad'}
								onChange={changeForm}
							/>
						</div>
					</div>
					<div className={styles.group}>
						<div className={styles.date}>
							<h4 className={styles.title}>Fin subasta*</h4>
							<label htmlFor="date" className={`${date.error ? styles.error : ''}`}>
								<DatePicker locale="es" dateFormat="dd/MM/yyyy" minDate={new Date()} selected={date.name} onChange={handleDateChange} />
								{/* {showCalendar && (
									<DatePicker locale="es" dateFormat="dd/MM/yyyy" minDate={new Date()} selected={date.name} onChange={handleDateChange} />
								)} */}
								{/* <span onClick={() => setShowCalendar(true)}> */}
								<CalendarIcon />
								{/* </span> */}
							</label>
						</div>
						<div className={styles.input}>
							<SelectField
								label={'Tramo de puja'}
								placeholder={'Selecciona un tramo'}
								list={trancheData}
								selected={tranche.value}
								changeSelected={changeSelect}
								name="tranche"
								error={false}
							/>
						</div>
					</div>
				</div>
				<div className={styles.bottom}>
					<h4 className={styles.title}>Documentos</h4>
					<p className={styles.text}>Recuerda adjuntar: nota simple, recibo de IBI, copia DNI, certificado energético.</p>
				</div>
				<div className={styles.content}>
					<div className={styles.left}>
						<div className={styles.file}>
							<ImageFile title="Sube aquí tus documentos" changeFile={changeFile} />
						</div>
					</div>
					<div className={styles.right}>
						<div className={styles.top}>
							<h4 className={styles.title}>Documentos subidos</h4>
							<span className={styles.length}>{result.length}</span>
						</div>
						<div className={styles.list}>{list}</div>
					</div>
				</div>
				<div className={styles.checkbox}>
					<CheckBox isCheck={check} label="Acepto la política de privacidad y las condiciones." onChange={() => setCheck(!check)} />
				</div>
			</div>
			<div className={styles.navigation}>
				{step > 0 && (
					<span className={styles.prev} onClick={() => AuctionState.setPrevStep()}>
						<RightArrowIcon />
						Volver al paso anterior
					</span>
				)}
				<span className={styles.next} onClick={onSubmit}>
					<Button color="blue">Continuar</Button>
				</span>
			</div>
		</div>
	)
})
