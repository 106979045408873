import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import AppState from '../../store/AppState'
import { Filter } from '../Filter/Filter'
import { FilterIcon, SearchIcon } from '../Icons/Icons'
import styles from './FilterBlock.module.scss'
import { FilterBlockForm } from './components/FilterBlockForm/FilterBlockForm'

type TFilterBlockProps = {
  items: string[]
  title: string
  full?: boolean
}

export const FilterBlock: FC<TFilterBlockProps> = observer(({ items, title, full = false }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [isActive, setIsActive] = useState(false)
  const onChange = () => {}
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.block}>
        <div className={styles.select}>
          <Filter items={items} defaultValue="Ordenar por última conexión" onChange={onChange} />
        </div>
        {full && (
          <div className={styles.btn}>
            <span className={styles.search}>
              <SearchIcon />
            </span>
            <span className={`${styles.filter} ${isActive ? styles.active : ''}`} onClick={() => setIsActive(!isActive)}>
              <FilterIcon />
            </span>
          </div>
        )}
        {isActive && <FilterBlockForm />}
      </div>
    </div>
  )
})
