import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useState } from 'react'
import { TAuctionSelectData, TAuctionSelectField } from '../../../pages/NewAuctionPage/components/PropertyForm/PropertyForm'
import AppState from '../../../store/AppState'
import ModalState from '../../../store/ModalState'
import { FADE_IN } from '../../../utils/animation/fade'
import { Button } from '../../Button/Button'
import { ExitIcon } from '../../Icons/Icons'
import { InputFile } from '../../InputFile/InputFile'
import { SelectField, TItemSelect } from '../../SelectField/SelectField'
import { TextField } from '../../TextField/TextField'
import { TextareaField } from '../../TextareaField/TextareaField'
import styles from './ModalServices.module.scss'

type TFormServicesObj = {
  value: string
  error: boolean
}

type TFormServices = {
  serviceName: TFormServicesObj
  price: TFormServicesObj
  description: TFormServicesObj
}

const cityData: TItemSelect[] = [
  {
    id: String(Math.floor(Math.random() * 100)),
    name: 'City 1',
  },
  {
    id: String(Math.floor(Math.random() * 100)),
    name: 'City 2',
  },
  {
    id: String(Math.floor(Math.random() * 100)),
    name: 'City 3',
  },
  {
    id: String(Math.floor(Math.random() * 100)),
    name: 'City 4',
  },
  {
    id: String(Math.floor(Math.random() * 100)),
    name: 'City 5',
  },
]

const provinceData: TItemSelect[] = [
  {
    id: String(Math.floor(Math.random() * 100)),
    name: 'province 1',
  },
  {
    id: String(Math.floor(Math.random() * 100)),
    name: 'province 2',
  },
  {
    id: String(Math.floor(Math.random() * 100)),
    name: 'province 3',
  },
  {
    id: String(Math.floor(Math.random() * 100)),
    name: 'province 4',
  },
  {
    id: String(Math.floor(Math.random() * 100)),
    name: 'province 5',
  },
]

export const ModalServices: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const isActive = ModalState.modalServices

  const [form, setForm] = useState<TFormServices>({
    serviceName: {
      value: '',
      error: false,
    },
    price: {
      value: '',
      error: false,
    },
    description: {
      value: '',
      error: false,
    },
  })

  const [typeServiceData, setTypeServiceData] = useState<TAuctionSelectData[]>([
    { id: 'type 1', name: 'type 1' },
    { id: 'type 2', name: 'type 2' },
    { id: 'type 3', name: 'type 3' },
  ])

  const [typeService, setTypeService] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })
  const [icon, setIcon] = useState<null | File>(null)
  const [selectIcontypeError, setSelectIcontypeError] = useState<boolean>(false)

  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'serviceName' || name === 'price') {
      setForm({ ...form, [name]: { value: e.currentTarget.value, error: !!true } })
    }
  }

  const changeFormTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name
    if (name === 'description') {
      setForm({ ...form, [name]: { value: e.currentTarget.value, error: !!true } })
    }
  }

  const changeSelect = (name: string, item: TAuctionSelectData) => {
    switch (name) {
      case 'typeService':
        setTypeService((prev) => ({ ...prev, value: item, error: false }))
        break
    }
  }
  const changeLogotype = (file: File) => {
    if (icon === null) {
      setIcon(file)
      setSelectIcontypeError(false)
    } else {
      setSelectIcontypeError(true)
    }
  }

  const onSubmit = () => {
    const result = Object.entries(form).map(([key, value]) => [key, { value: value.value, error: value.value === '' ? true : false }])
    setForm(Object.fromEntries(result))
    setTypeService((prev) => ({ ...prev, error: prev.value === null ? true : false }))
    const error = Object.entries(form).some(([key, value]) => (key === 'phone' ? false : value.value === ''))

    if (!error && typeService !== null) {
      const formData = new FormData()
      const data = { ...form, typeService, icon }
    }
  }
  return (
    <motion.div {...FADE_IN} className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <button className={styles.close} onClick={() => ModalState.hideModalService()}>
        <ExitIcon />
      </button>
      <div className={styles.content}>
        <h3 className={styles.title}>Nuevo servicio</h3>
        <div className={styles.wrap}>
          <div className={styles.group}>
            <div className={styles.input}>
              <TextField label="Nombre servicio*" name="serviceName" error={form.serviceName.error} value={form.serviceName.value} placeholder="Introduce nombre servicio" onChange={changeForm} />
            </div>
            <div className={styles.input}>
              <SelectField
                label={'Tipo servicio*'}
                error={typeService.error}
                placeholder={'Selecciona tipo servicio'}
                list={typeServiceData}
                name="typeService"
                changeSelected={changeSelect}
                selected={typeService.value}
              />
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.input}>
              <TextField label="Precio*" name="price" error={form.price.error} value={form.price.value} placeholder="Intoduce precio" onChange={changeForm} />
            </div>
            <div className={styles.input}>
              <InputFile error={false} changeFile={changeLogotype} label="Icono" placeholder="Sube un icono" />
            </div>
          </div>
          <div className={styles.textarea}>
            <TextareaField
              label={'Descripción'}
              name="description"
              error={form.description.error}
              value={form.description.value}
              placeholder={'Introduce descripción del servicio'}
              onChange={changeFormTextarea}
            />
          </div>
        </div>
        <div className={styles.btn} onClick={onSubmit}>
          <Button>Crear servicio</Button>
        </div>
      </div>
    </motion.div>
  )
})
