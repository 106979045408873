import {apiService} from '../services/apiServices/apiService'
import {TAuctionObj} from './auctionApi'

export type TResCreateDepositApi = {
  formData: {
    Country: {id: "", name: ""}
    address: string
    countryId: string
    id: string
    phone: string
    postalCode: string
    userId: string
    userName: string
    userSurnames: string
  }
  payData: {
    clientSecret: string
    product: {
      description: string
      idStripe: string
      name: string
      price: string
    }
  }
}

export const createDepositApi = async (data: {auctionId: string}) => {
  return apiService<TResCreateDepositApi>('post', 'deposit/create', data)
}

export const depositConfirmationApi = async (data: {auctionId: string, idPayment: string, otherUserId?: string}) => {
  return apiService('post', 'deposit/depositConfirmation', data)
}

type TResGetMyDepositsApi = {
  count: number
  auctions: {
    auctionId: string
    id: string
    status: boolean
    userId: string
    Auction: TAuctionObj
  }[]
}

export const getMyDepositsApi = async (data: {take: number, skip: number}) => {
  return apiService<TResGetMyDepositsApi>('get', 'deposit/getMyDeposits', data)
}


export const createOtherUserApi = async (data: {email: string, password: string, name: string, firstSurname: string, secondSurname: string, NIF: string, phone: string, address: string, postcode: string, city: string, provinceId: string }) => {
  return apiService<TResCreateDepositApi>('post', 'deposit/createOtherUser', data)
}