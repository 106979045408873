import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { TGetStatAllApi, getStatAllApi } from '../../../../api/statApi'
import { BuildIcon, CreditIcon, GavelIcon, UserIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import styles from './MainStat.module.scss'
import { MainStatAuction } from './components/MainStatAuctions/MainStatAuctions'
import { MainStatBids } from './components/MainStatBids/MainStatBids'

const dataSwitch: string[] = ['Subastas', 'Pujas']
const elements: JSX.Element[] = [<MainStatAuction />, <MainStatBids />]
export const MainStat: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [index, setIndex] = useState(0)
  const [stat, setStat] = useState<TGetStatAllApi | null>(null)

  useEffect(() => {
    getStatAllApi().then((res) => setStat(res.data))
  }, [])

  const switchList = dataSwitch.map((item, i) => (
    <div className={`${styles.item} ${index === i ? styles.active : ''}`} onClick={() => setIndex(i)} key={i}>
      {item}
    </div>
  ))
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h3 className={styles.title}>Estadísticas</h3>
      <div className={styles.list}>
        <div className={styles.item}>
          <span className={styles.icon}>
            <GavelIcon />
          </span>
          <span className={styles.count}>{stat?.countAuctions}</span>
          <h4 className={styles.title}>Subastas</h4>
        </div>
        <div className={styles.item}>
          <span className={styles.icon}>
            <CreditIcon />
          </span>
          <span className={styles.count}>{stat?.countBids}</span>
          <h4 className={styles.title}>Pujas</h4>
        </div>
        <div className={styles.item}>
          <span className={styles.icon}>
            <UserIcon />
          </span>
          <span className={styles.count}>{stat?.countParticular}</span>
          <h4 className={styles.title}>Usuarios particulares</h4>
        </div>
        <div className={styles.item}>
          <span className={styles.icon}>
            <BuildIcon />
          </span>
          <span className={styles.count}>{stat?.countProfessional}</span>
          <h4 className={styles.title}>Usuarios profesionales</h4>
        </div>
      </div>
      <div className={styles.chart}>
        <div className={styles.switch}>
          <div className={styles.list}>{switchList}</div>
        </div>
        <div className={styles.content}>{elements[index]}</div>
      </div>
    </div>
  )
})
