import { apiService } from '../services/apiServices/apiService'

export type TServicesApi = {
  description: string
  id: string
  name: string
  price: number
}

export const getServicesAllApi = async () => {
  return await apiService<TServicesApi[]>('get', 'service/')
}



export type TResGetPaymentDataServiceApi = {
  formData: {
    id: string
    userName: string
    userSurnames: string
    address: string
    postalCode: string
    phone: string
    userId: string
    countryId: string
    Country: {
      id: string
      name: string
    }
  },
  payData: {
    clientSecret: string
    product: {
      id: string
      description: string
      name: string
      price: {
        unit_amount: number
        unit_amount_decimal: string
      }
    }
  }
}

export const getPaymentDataServiceApi = async (data: {productId: string}) => {
  return await apiService<TResGetPaymentDataServiceApi>('get', 'service/getPaymentDataService', data)
}


export const deleteProductApi = async (data: {id: string}) => {
  return await apiService<TServicesApi[]>('post', 'service/delete', data)
}