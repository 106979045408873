import { FC, SetStateAction, useEffect, useState } from 'react'
import { TAuctionObj } from '../../../../api/auctionApi'
import { Pagination } from '../../../../components/Pagination/Pagination'
import { BidsItem } from '../BidsItem/BidsItem'
import styles from './BidsSuccess.module.scss'
import {getBidsApi} from '../../../../api/bidApi'

export const BidsSuccess: FC = () => {
  const [items, setItems] = useState<TAuctionObj[]>([])
  const [len, setLen] = useState(0)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage] = useState(10)

  useEffect(() => {
    // getAuctionAllApi({ take: postsPerPage, skip: count })
    //   .then((res) => {
    //     setLen(res.data.count)
    //     setItems(res.data.auctions)
    //     console.log(res.data)
    //   })
    //   .catch((err) => console.log(err))
    getBidsApi({take: postsPerPage, skip: count, type: 'awarded'})
      .then(res => {
        setLen(res.data.count)
        setItems(res.data.auctions)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const paginates = (pageNumber: SetStateAction<number>) => {
    setCurrentPage(pageNumber)
    setCount(postsPerPage * Number(pageNumber))
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const previousPage = () => {
    if (currentPage >= 0) {
      setCurrentPage((prev) => prev - 1)
      setCount((prev) => prev - postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const nextPage = () => {
    if (currentPage !== Math.ceil(len / postsPerPage)) {
      setCurrentPage((prev) => prev + 1)
      setCount((prev) => prev + postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const list = items.map((item) => <BidsItem item={item} status="success" key={item.id} />)

  return (
    <div className={styles.root}>
      <div className={styles.list}>{list}</div>
      {len > postsPerPage && (
        <div className={styles.pagination}>
          <Pagination postsPerPage={postsPerPage} totalPosts={len} paginate={paginates} previousPage={previousPage} nextPage={nextPage} currentPage={currentPage} />
        </div>
      )}
    </div>
  )
}
