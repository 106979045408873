import {observer} from 'mobx-react-lite'
import {FC, useEffect, useState} from 'react'
import {TServicesApi, getServicesAllApi} from '../../api/servicesApi'
import {Button} from '../../components/Button/Button'
import AppState from '../../store/AppState'
import ModalState from '../../store/ModalState'
import styles from './ServicesPage.module.scss'
import {ServicesItem, TServicesItem} from './components/ServicesItem/ServicesItem'
import {OrderForm} from './components/OrderForm/OrderForm'
import {loadStripe} from '@stripe/stripe-js'
import {stripePublicKey} from '../../config/stripeConfig'
import {Elements} from '@stripe/react-stripe-js'
import AuthState from "../../store/AuthState";


export const ServicesPage: FC = observer(() => {
  const [items, setItems] = useState<TServicesApi[] | null>(null)
  const [payItem, setPayItem] = useState<null | string>(null)
  const isAdmin = AuthState.user?.type === 'ADMIN'

  const width = AppState.widthWindow
  const isMobile = width <= 768


  useEffect(() => {
    getServicesAllApi().then((res) => setItems(res.data))
  }, [])

  const handlerOpenPay = (productId: string | null) => {
    setPayItem(productId)
  }

  const handlerCorrectedPayment = () => {
    setPayItem(null)
  }

  const handlerErrorPayment = () => {
    alert('Error payment')
  }

  const updateItems = (items: TServicesApi[]) => {
    setItems(items)
  }


  const list = items?.map((item) => <ServicesItem updateItems={updateItems} handlerOpenPay={handlerOpenPay} item={item} key={item.id}/>)

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.top}>
        <h3 className={styles.title}>Servicios</h3>
        {isAdmin && (
          <div className={styles.btn} onClick={() => ModalState.showModalService()}>
            <Button>Nuevo servicio</Button>
          </div>
        )}
      </div>
      <div className={styles.list}>{list}</div>

      {payItem && (
        <div className={styles.modal}>
          <Elements stripe={loadStripe(stripePublicKey)}>
            <OrderForm productId={payItem} closeOrderForm={() => handlerOpenPay(null)} setIsCorrectedPayment={handlerCorrectedPayment}
                       setError={handlerErrorPayment}/>
          </Elements>
        </div>
      )}
    </div>
  )
})
