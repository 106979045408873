import { makeAutoObservable } from 'mobx'
import { TUser } from '../api/authApi'
import { clearAuthLocalStorage } from '../services/localStorageServices/authLocalStorageService'

class AuthState {
  constructor() {
    makeAutoObservable(this)
  }
  accessToken = ''
  user: TUser | null = null

  isAuth = false

  setAccessToken = (token: string) => {
    this.accessToken = token
  }

  setUser = (user: TUser) => {
    this.user = user
  }

  setIsAuth = (value: boolean) => {
    this.isAuth = value
  }

  logout = () => {
    clearAuthLocalStorage()
    this.user = null
    this.accessToken = ''
    this.isAuth = false
  }
}

export default new AuthState()
