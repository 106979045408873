import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { TAuctionFormInput, TAuctionSelectData, TAuctionSelectField } from '../ModalAuctionProperty/ModalAuctionProperty'

import 'react-datepicker/dist/react-datepicker.css'

import {TAuctionEditFile, TAuctionObj, postUploadFileApi, deleteFileApi} from '../../../../../api/auctionApi'
import { ImageFile } from '../../../../../pages/NewAuctionPage/components/ImageFile/ImageFile'
import AppState from '../../../../../store/AppState'
import ModalState from '../../../../../store/ModalState'
import { Button } from '../../../../Button/Button'
import { CheckBox } from '../../../../CheckBox/CheckBox'
import { AcortarIcon, CalendarIcon, ExitIcon, RightArrowIcon } from '../../../../Icons/Icons'
import { SelectField } from '../../../../SelectField/SelectField'
import { TextField } from '../../../../TextField/TextField'
import styles from './ModalAuctionForm.module.scss'

export type TPropertyForm = {
  plotMeters: TAuctionFormInput
  useMeters: TAuctionFormInput
}

export type TDate = {
  name: Date | null
  error: boolean
}

export const ModalAuctionForm: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const ref = ModalState.data?.reference
  const step = ModalState.step
  const [auction, setAuction] = useState<TAuctionObj | null>(null)
  const size = String(Math.floor(Math.random() * 1000))
  const [file, setFile] = useState<null | File[]>(null)
  const [newFileResult, setNewFileResult] = useState<TAuctionEditFile[]>([])
  const [oldFileResult, setOldFileResult] = useState<TAuctionEditFile[]>([])
  const [check, setCheck] = useState(true)
  const [startPrice, setStartPrice] = useState({ name: '', error: false })
  const [date, setDate] = useState<TDate>({ name: null, error: false })
  const [trancheData, setTrancheData] = useState<TAuctionSelectData[]>([
    { id: '100', name: '100' },
    { id: '250', name: '250' },
    { id: '500', name: '500' },
    { id: '750', name: '750' },
    { id: '900', name: '900' },
    { id: '1000', name: '1000' },
  ])
  const [tranche, setTranche] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  useEffect(() => {
    if (file) {
      const formData = new FormData()
      formData.append('reference', String(ref))
      for (let i = 0; i < file.length; i++) {
        formData.append('doc', file[i])
      }

      postUploadFileApi(formData)
        .then((res) => {
          setNewFileResult((prev) => [...prev].concat(res.data))
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [file])

  useEffect(() => {
    ModalState.setDocuments(newFileResult)
  }, [newFileResult])

  useEffect(() => {
    if (ModalState.data) {
      ModalState.data.AuctionDoc.forEach((item) =>
        setOldFileResult((prev) => [
          ...prev,
          {
            auctionId: item.id,
            id: item.id,
            name: 'loaded.pdf',
          },
        ])
      )

      setStartPrice({ name: String(ModalState.data.auctionStartingPrice), error: false })
      setDate({ name: new Date(ModalState.data.endAuction), error: false })
      setTranche({
        value: {
          id: String(ModalState.data.biddingTranche),
          name: String(ModalState.data.biddingTranche),
        },
        error: false,
      })
    }
  }, [ModalState.data])

  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    setStartPrice({ name: e.currentTarget.value, error: !!true })
  }

  const changeSelect = (name: string, item: TAuctionSelectData) => {
    switch (name) {
      case 'tranche':
        setTranche((prev) => ({
          ...prev,
          value: item,
          error: false,
        }))
        break
    }
  }
  const changeFile = (file: File[]) => {
    setFile(file)
  }

  const removeFile = (id: string) => {
    deleteFileApi({id}).then(() => {
      setOldFileResult((prev) => prev.filter((el) => el.id !== id))
      setNewFileResult((prev) => prev.filter((el) => el.id !== id))
    })
  }

  const onSubmit = () => {
    setTranche((prev) => ({ ...prev, error: prev.value === null ? true : false }))
    setStartPrice((prev) => ({ ...prev, error: prev.name === '' ? true : false }))
    setDate((prev) => ({ ...prev, error: prev.name === null ? true : false }))

    if (date.name !== null) {
      const data = {
        auctionStartingPrice: startPrice.name,
        endAuction: date.name,
        biddingTranche: tranche.value !== null ? tranche.value.name : undefined,
      }
      ModalState.updateEditData(data)
      ModalState.setNextStep()
    }
  }
  const oldFileList = oldFileResult.map((item) => (
    <div className={styles.item} key={item.id}>
      {item.name.split('.')[1] === 'png' || item.name.split('.')[1] === 'jpeg' || item.name.split('.')[1] === 'jpg' || item.name.split('.')[1] === 'pdf' ? (
        <span className={styles.loader}>100%</span>
      ) : (
        <span className={styles.acortar}>
          <AcortarIcon />
        </span>
      )}
      <div className={styles.info}>
        <h5 className={styles.title}>{item.name}</h5>
        {item.name.split('.')[1] === 'png' || item.name.split('.')[1] === 'jpeg' || item.name.split('.')[1] === 'jpg' || item.name.split('.')[1] === 'pdf' ? (
          <span className={styles.size}>10Kb/10Kb</span>
        ) : (
          <span className={styles.size}></span>
        )}
      </div>
      <span className={styles.remove} onClick={() => removeFile(item.id)}>
        <ExitIcon />
      </span>
    </div>
  ))

  const newFileList = newFileResult.map((item) => (
    <div className={styles.item} key={item.id}>
      {item.name.split('.')[1] === 'png' || item.name.split('.')[1] === 'jpeg' || item.name.split('.')[1] === 'jpg' || item.name.split('.')[1] === 'pdf' ? (
        <span className={styles.loader}>100%</span>
      ) : (
        <span className={styles.acortar}>
          <AcortarIcon />
        </span>
      )}
      <div className={styles.info}>
        <h5 className={styles.title}>{item.name}</h5>
        {item.name.split('.')[1] === 'png' || item.name.split('.')[1] === 'jpeg' || item.name.split('.')[1] === 'jpg' || item.name.split('.')[1] === 'pdf' ? (
          <span className={styles.size}>10Kb/10Kb</span>
        ) : (
          <span className={styles.size}></span>
        )}
      </div>
      <span className={styles.remove} onClick={() => removeFile(item.id)}>
        <ExitIcon />
      </span>
    </div>
  ))
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.form}>
        <div className={styles.wrap}>
          <div className={styles.single}>
            <div className={styles.input}>
              <TextField
                disabled={auction?.Deposit.length !== 0}
                type="number"
                label={'Precio salida de subasta'}
                name="startPrice"
                error={false}
                value={String(startPrice.name)}
                placeholder={'Introduce una cantidad'}
                onChange={changeForm}
              />
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.date}>
              <h4 className={styles.title}>Fin subasta*</h4>
              <label htmlFor="date" className={`${date.error ? styles.error : ''}`}>
                <DatePicker
                  disabled={auction?.Deposit.length !== 0}
                  locale="es"
                  dateFormat="dd/MM/yyyy"
                  minDate={ModalState.data?.endAuction ? new Date(ModalState.data.endAuction) : new Date()}
                  showDisabledMonthNavigation
                  id="date"
                  selected={ModalState.data?.endAuction ? new Date(ModalState.data.endAuction) : new Date()}
                  onChange={(date: any) => setDate((prev) => ({ ...prev, name: date, error: false }))}
                />
                <CalendarIcon />
              </label>
            </div>
            <div className={styles.input}>
              <SelectField label={'Tramo de puja'} placeholder={'Selecciona un tramo'} list={trancheData} selected={tranche.value} changeSelected={changeSelect} name="tranche" error={false} />
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <h4 className={styles.title}>Documentos</h4>
          <p className={styles.text}>Recuerda adjuntar: nota simple, recibo de IBI, copia DNI, certificado energético.</p>
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.file}>
              <ImageFile title="Sube aquí tus documentos" changeFile={changeFile} />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.top}>
              <h4 className={styles.title}>Documentos subidos</h4>
              <span className={styles.length}>{oldFileList.length}</span>
            </div>
            <div className={styles.list}>{oldFileList}</div>
            <div className={styles.list}>{newFileList}</div>
          </div>
        </div>
        <div className={styles.checkbox}>
          <CheckBox isCheck={check} label="Acepto la política de privacidad y las condiciones." onChange={() => setCheck(!check)} />
        </div>
      </div>
      <div className={styles.navigation}>
        {step > 0 && (
          <span className={styles.prev} onClick={() => ModalState.setPrevStep()}>
            <RightArrowIcon />
            Volver al paso anterior
          </span>
        )}
        <span className={styles.next} onClick={onSubmit}>
          <Button color="blue">Continuar</Button>
        </span>
      </div>
    </div>
  )
})
