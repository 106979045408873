import { makeAutoObservable } from 'mobx'

class UserState {
  constructor() {
    makeAutoObservable(this)
  }

  userLink: boolean = false
  id: string = ''
  firstSignIn = true

  setHandlerUserLink(id: string) {
    this.id = id
    this.userLink = true
  }

  setHandlerId(id: string) {
    this.id = id
  }

  changeFirstSignIn = () => {
    this.firstSignIn = false
  }

  reset() {
    this.userLink = false
  }
}

export default new UserState()
