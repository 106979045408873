import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import error from '../../../../assets/authPage/error.svg'
import { Button } from '../../../../components/Button/Button'
import { ExitIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import styles from './Error.module.scss'

type TProps = {
  closeError: () => void
}

export const Error: FC<TProps> = observer(({ closeError }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <div className={styles.exit} onClick={closeError}>
        <ExitIcon />
      </div>
      <h1 className={styles.title}>¡Oops, tenemos problemas!</h1>
      <img className={styles.image} src={error} alt={'accountUserCreated'} />

      <p className={styles.text}>Parece que no se ha podido realizar el pago correctamente</p>
      <p className={styles.description}>Puedes volver a realizar el pago</p>

      <div className={styles.button}>
        <Button onClick={closeError}>Volver a intentarlo</Button>
      </div>
    </div>
  )
})
