import { Dispatch, FC, SetStateAction } from 'react'
import { ArrowDown } from '../Icons/Icons'
import styles from './Pagination.module.scss'

type IPagProps = {
  postsPerPage: number
  totalPosts: number
  paginate: Dispatch<SetStateAction<number>>
  previousPage: () => void
  nextPage: () => void
  currentPage: number
}

export const Pagination: FC<IPagProps> = ({ postsPerPage, totalPosts, paginate, currentPage, previousPage, nextPage }) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i)
  }
  const paginateList = pageNumbers.map((num, i) => (
    <div key={num} className={`${styles.item} ${currentPage === i ? styles.active : ''}`} onClick={() => paginate(i)}>
      {num}
    </div>
  ))

  return (
    <div className={styles.root}>
      <button className={styles.prev} onClick={previousPage} disabled={currentPage === 0}>
        <ArrowDown />
      </button>
      <div className={styles.list}>{paginateList}</div>
      <button className={styles.next} onClick={nextPage} disabled={Math.ceil(totalPosts / postsPerPage) <= currentPage + 1}>
        <ArrowDown />
      </button>
    </div>
  )
}
