import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useState } from 'react'
import { Button } from '../../../../components/Button/Button'
import { MailIcon } from '../../../../components/Icons/Icons'
import { Input } from '../../../../components/Input/Input'
import AppState from '../../../../store/AppState'
import styles from './ForgotForm.module.scss'

type ForgotFormProps = {
  onChange?: () => void
}

export const ForgotForm: FC<ForgotFormProps> = observer(({ onChange }) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') {
      setError(true)
    } else {
      setError(false)
    }

    setEmail(e.currentTarget.value)
  }

  const onSubmit = () => {
    email === '' ? setError(true) : setError(false)
  }

  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <div className={`${styles.email} ${error ? styles.error : ''}`}>
        <Input color={'grey'} iconLeft={<MailIcon />} type={'email'} placeholder={'Email'} name={'email'} value={email} onChange={changeEmail} />
      </div>
      {error && <div className={styles.errors}>No valid email</div>}
      <div className={styles.button} onClick={onSubmit}>
        <Button>Enviar correo</Button>
      </div>
      {isMobile && (
        <button className={styles.link} onClick={onChange}>
          Iniciar sesión
        </button>
      )}
    </div>
  )
})
