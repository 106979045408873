import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import AppState from '../../../../store/AppState'
import styles from './UsersDeposits.module.scss'
import { UsersDepositsItem } from './UsersDepositsItem/UsersDepositsItem'
import {TUserDeposit} from '../../../../api/usersApi'

type TProps = {
  items: TUserDeposit[]
}

export const UsersDeposits: FC<TProps> = observer(({items}) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [len, setLen] = useState(0)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage] = useState(10)

  const list = items.map((item) => <UsersDepositsItem item={item.Auction} userId={item.userId} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.list}>{list}</div>
    </div>
  )
})
