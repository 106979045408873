import { FC, ReactNode } from 'react'
import AppState from '../../store/AppState'
import styles from './Button.module.scss'

type TProps = {
  children: ReactNode
  color?: 'blue' | 'gray'
  onClick?: () => void
  disabled?: boolean
}

export const Button: FC<TProps> = ({ children, color = 'blue', onClick, disabled = false }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  return (
    <button className={`${isMobile ? styles.wrapperMobile : styles.wrapper}  ${styles[color]}`} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  )
}
