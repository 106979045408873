import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Button } from '../../../../../components/Button/Button'
import { LocationIcon } from '../../../../../components/Icons/Icons'
import AppState from '../../../../../store/AppState'
import { priceFormat } from '../../../../../utils/price.utils'
import styles from './MainBidsItem.module.scss'
import {urlApi} from '../../../../../config/apiConfig'
import {useNavigate} from 'react-router-dom'
import {SINGLE_AUCTIONS_PATH} from '../../../../../services/mainServices/RoutesService'

type TBidsPrice = {
  title: string
  value: number
}

export type TBidsProps = {
  id: string
  img: string
  title: string
  location: string
  price: TBidsPrice[]
}

export const MainBidsItem: FC<{ item: TBidsProps }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const navigate = useNavigate()

  const openAuction = () => {
    navigate(`auction/${item.id}`)
  }

  const list = item.price.map((el, i) => (
    <div className={styles.item} key={el.title}>
      <span className={`${styles.price} ${i !== 2 ? '' : el.value > item.price[1].value ? styles.green : styles.red}`}>{priceFormat(el.value)}</span>
      <span className={styles.title}>{el.title}</span>
    </div>
  ))
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.img} style={(item && item.img) ? { backgroundImage: `url(${urlApi}auction/media?id=${item.img})` } : undefined}></div>
      <div className={styles.content}>
        <h4 className={styles.title}>{item.title}</h4>
        <div className={styles.location}>
          <LocationIcon />
          {item.location}
        </div>
        <div className={styles.bottom}>
          <div className={styles.list}>{list}</div>
          <div className={styles.btn}>
            <Button onClick={openAuction}>Ver subasta</Button>
          </div>
        </div>
      </div>
    </div>
  )
})
