import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import ModalState from '../../store/ModalState'
import { ModalAddBids } from '../Modal/ModalAddBids/ModalAddBids'
import { ModalAuctionDelete } from '../Modal/ModalAuctionDelete/ModalAuctionDelete'
import { ModalAuctionEdit } from '../Modal/ModalAuctionEdit/ModalAuctionEdit'
import { ModalCodes } from '../Modal/ModalCodes/ModalCodes'
import { ModalContact } from '../Modal/ModalContact/ModalContact'
import { ModalFoto } from '../Modal/ModalFoto/ModalFoto'
import { ModalLegal } from '../Modal/ModalLegal/ModalLegal'
import { ModalMap } from '../Modal/ModalMap/ModalMap'
import { ModalPay } from '../Modal/ModalPay/ModalPay'
import { ModalRoles } from '../Modal/ModalRoles/ModalRoles'
import { ModalSearch } from '../Modal/ModalSearch/ModalSearch'
import { ModalServices } from '../Modal/ModalServices/ModalServices'
import { ModalUserDelete } from '../Modal/ModalUserDelete/ModalUserDelete'
import { ModalVideo } from '../Modal/ModalVideo/ModalVideo'
import styles from './MainLayout.module.scss'
import { Footer } from './components/Footer/Footer'
import { Header } from './components/Header/Header'

export const MainLayout: FC = observer(() => {
  return (
    <div className={styles.root}>
      <Header />
      <main className={styles.main}>
        <div className="container">
          <Outlet />
        </div>
      </main>
      <Footer />
      {ModalState.modalVideo.show && <ModalVideo />}
      {ModalState.modalFoto.show && <ModalFoto />}
      {ModalState.modalContact && <ModalContact />}
      {ModalState.modalMap.show && <ModalMap />}
      {ModalState.modalSearch && <ModalSearch />}
      {ModalState.modalUserDelete && <ModalUserDelete />}
      {ModalState.modalServices && <ModalServices />}
      {ModalState.modalCodes && <ModalCodes />}
      {ModalState.modalPay && <ModalPay />}
      {ModalState.modalRoles && <ModalRoles />}
      {ModalState.modalLegal && <ModalLegal />}
      {ModalState.modalAddBids && <ModalAddBids />}
      {ModalState.modalAuctionDelete && <ModalAuctionDelete />}
      {ModalState.modalAuctionEdit && <ModalAuctionEdit />}
    </div>
  )
})
