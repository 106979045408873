import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { postAuctionApi } from '../../../api/auctionApi'
import { stripePublicKey } from '../../../config/stripeConfig'
import { ServicePay } from '../../../pages/ServicesPage/components/ServicePay/ServicePay'
import AppState from '../../../store/AppState'
import AuctionState from '../../../store/AuctionState'
import ModalState from '../../../store/ModalState'
import { FADE_IN } from '../../../utils/animation/fade'
import { ExitIcon } from '../../Icons/Icons'
import styles from './ModalPay.module.scss'

export const ModalPay: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [isOrderForm, setIsOrderForm] = useState(false)
  const [isCorrectPayment, setIsCorrectPayment] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (isCorrectPayment) {
      postAuctionApi(AuctionState.obj)
        .then((res) => console.log(res.data))
        .catch((err) => console.log(err))
      AuctionState.setNextStep()
      /* AuctionState.resetObj() */
    }
  }, [isCorrectPayment])
  const closeOrderForm = () => {
    setIsOrderForm(false)
  }
  const setCorrectedPayment = () => {
    setIsOrderForm(false)
    setIsCorrectPayment(true)
    console.log(isCorrectPayment)
  }
  const setError = () => {
    setIsOrderForm(false)
    setIsError(true)
  }

  return (
    <motion.div {...FADE_IN} className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.content}>
        <button className={styles.close} onClick={() => ModalState.hideModalPay()}>
          <ExitIcon />
        </button>
        <Elements stripe={loadStripe(stripePublicKey)}>
          <ServicePay closeOrderForm={closeOrderForm} setIsCorrectedPayment={setCorrectedPayment} setError={setError} icon={false} />
        </Elements>
      </div>
    </motion.div>
  )
})
