import { apiService } from '../services/apiServices/apiService'
import { TAuctionObj } from './auctionApi'

type TProvince = {
  id: string
  name: string
}

type TCountry = {
  id: string
  name: string
}

type TPlan = {
  description: string
  id: string
  idStripe: string
  limit: number
  name: string
  payment: string
  price: number
}

export type TProfessionalData = {
  CIF: string
  address: string
  company: string
  contactPerson: string
  id: string
  logoData: string
  logoName: string
  logoType: string
  phone: string
  postcode: string
  provinceId: string
  tradeName: string
  userId: string
  city: string
  Province: TProvince
}

export type TParticularData = {
  address: 'asdasd'
  firstSurname: 'dasda'
  id: '50c4406b-41b7-4b16-ad34-bc20623ea480'
  name: 'asdas'
  phone: 'adsasd'
  postcode: 'asdasd'
  provinceId: 'a3981802-748f-4608-a7d9-6050936076e7'
  secondSurname: 'sdasd'
  userId: 'ee31faff-1ba9-466a-8191-6e4b070f7f93'
  NIF: 'asdasd'
  city: ''
  Province: TProvince
}

type TProfessionalPlan = {
  Plan: TPlan
  active: boolean
  id: string
  planId: string
  userId: string
}

type TProfessionalPlanOrder = {
  Country: TCountry[]
  address: string
  countryId: string
  id: string
  phone: string
  postalCode: string
  userId: string
  userName: string
  userSurnames: string
}

export type TUserBid = {
  auctionId: string
  bid: number
  createdAt: string
  id: string
  sum: number
  userId: string
  Auction: TAuctionObj
}

export type TUserDeposit = {
  auctionId: string
  createdAt: string
  id: string
  price: number
  status: true
  userId: string
  Auction: TAuctionObj
}

export type TUsersObj = {
  Auction: TAuctionObj[] | null
  Bid?: TUserBid[]
  Awarded?: TAuctionObj[] | null
  Active?: TAuctionObj[] | null
  ParticularData: TParticularData | null
  ProfessionalData: TProfessionalData | null
  ProfessionalPlan: TProfessionalPlan
  ProfessionalPlanOrder: TProfessionalPlanOrder[]
  email: string
  id: string
  type: string
  isBlocked: boolean
  Deposit: TUserDeposit[]
  accessControl: string
}

export type TUsersApi = {
  count: number
  users: TUsersObj[]
}

export const getUsersApi = async (payload: { role: string; take: number; skip: number }) => {
  return await apiService<TUsersApi>('get', 'user/', payload)
}

export const getUserByIdApi = async (id: string) => {
  return await apiService<TUsersObj>('get', 'user/', { id: id })
}

export const patchUserByIdApi = async (payload: { userId: string; isBlocked: boolean }) => {
  return await apiService('post', 'user/isBlocked', payload)
}

export const deleteUserByIdApi = async (id: string) => {
  return await apiService('post', 'user/delete', { userId: id })
}
