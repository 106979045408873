import { apiService } from '../services/apiServices/apiService'
import { TProfessionalData } from './usersApi'

export type getRolesAllApi = {
  ProfessionalData: TProfessionalData
  accessControl: string
  createdAt: string
  email: string
  id: string
  isBlocked: boolean
  password: string
  type: 'ADMIN' | 'PROFESSIONAL'
}

export type TUpdateRolesApi = {
  userId: string
  tradeName: string
  CIF: string
  address: string
  contactPerson: string
  company: string
  phone: string
  postcode: string
  city: string
  provinceId: string
  type: string
}
export const getRolesAllApi = async (payload: { take: number; skip: number }) => {
  return await apiService('get', 'user/getForRoles', payload)
}

export const updateRolesAllApi = async (payload: TUpdateRolesApi) => {
  return await apiService('post', 'user/updateUserData', payload)
}
