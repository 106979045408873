import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import correctPayment from '../../../../assets/authPage/correctPayment.svg'
import { Button } from '../../../../components/Button/Button'
import { ExitIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import styles from './CorrectPayment.module.scss'

type TProps = {
  closeCorrectPayment: () => void
}

export const CorrectPayment: FC<TProps> = observer(({ closeCorrectPayment }) => {
  const setIsAuth = () => {
    AuthState.setIsAuth(true)
  }
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <div className={styles.exit} onClick={closeCorrectPayment}>
        <ExitIcon />
      </div>
      <h1 className={styles.title}>Tu pago se ha realizado correctamente</h1>
      <img className={styles.image} src={correctPayment} alt={'accountUserCreated'} />

      <p className={styles.text}>Recibirá un email de conformación de su compra en su correo electrónico</p>
      <p className={styles.description}>¡Muchas gracias por su compra!</p>

      <div className={styles.button}>
        <Button onClick={setIsAuth}>Ir a mi panel de control</Button>
      </div>
    </div>
  )
})
