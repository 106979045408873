import { AnimatePresence, motion } from 'framer-motion'
import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { DownIcon } from '../../../../Icons/Icons'
import { TMenu } from '../Menu/Menu'
import styles from './MobileMenu.module.scss'

type TMobileMenuProps = TMenu[]
type TDropItem = {
  status: boolean
}
export const MobileMenu: FC<{ items: TMobileMenuProps; isActive: boolean; onClose: () => void }> = ({ items, isActive, onClose }) => {
  const [index, setIndex] = useState<number | null>(null)

  const onChange = (i: number) => {
    index === i ? setIndex(null) : setIndex(i)
  }

  const list = items.map((item, i) => (
    <div className={styles.item} key={i}>
      <div className={styles.block}>
        <Link to={item.path} onClick={onClose}>
          {item.title}
        </Link>
        {item.dropList && (
          <button className={`${styles.btn} ${index !== null && index === i ? styles.active : ''}`} onClick={() => onChange(i)}>
            <DownIcon />
          </button>
        )}
      </div>

      {index !== null && index === i && (
        <AnimatePresence>
          <motion.div
            className={styles.drop}
            initial={{
              height: 0,
              opacity: 0,
            }}
            animate={{
              height: 'auto',
              opacity: 1,
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: { duration: 1, ease: 'easeIn' },
            }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
          >
            {item.dropList?.map((el) => (
              <Link className={styles.item} to={el.path}>
                {el.title}
              </Link>
            ))}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  ))
  return (
    <div className={`${styles.root} ${isActive ? styles.active : ''}`}>
      <div className={styles.list}>{list}</div>
    </div>
  )
}
