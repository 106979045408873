import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useState } from 'react'
import DatePicker from 'react-datepicker'
import AppState from '../../../../store/AppState'
import { FADE_IN } from '../../../../utils/animation/fade'
import { Button } from '../../../Button/Button'
import { CalendarFilterIcon } from '../../../Icons/Icons'
import { TextField } from '../../../TextField/TextField'
import styles from './FilterBlockForm.module.scss'

type TFilterBlockFormItem = {
  value: string
  error: boolean
}
type TFilterBlockForm = {
  raised: TFilterBlockFormItem
  awarded: TFilterBlockFormItem
  actives: TFilterBlockFormItem
  bids: TFilterBlockFormItem
}
export const FilterBlockForm: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [form, setForm] = useState<TFilterBlockForm>({
    raised: {
      value: '',
      error: false,
    },
    awarded: {
      value: '',
      error: false,
    },
    actives: {
      value: '',
      error: false,
    },
    bids: {
      value: '30',
      error: false,
    },
  })
  const [date, setDate] = useState<Date | null>(null)
  const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    if (name === 'raised' || name === 'awarded' || name === 'actives' || name === 'bids') {
      setForm({ ...form, [name]: { value: e.currentTarget.value } })
    }
  }
  return (
    <motion.div {...FADE_IN} className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h4 className={styles.title}>Filtros</h4>
      <div className={styles.form}>
        <div className={styles.single}>
          <div className={styles.input}>
            <TextField label={'Subastas subidas'} name="raised" error={form.raised.error} value={form.raised.value} placeholder={'Número de subastas'} onChange={changeForm} />
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.input}>
            <TextField label={'S. Adjudicadas'} name="awarded" error={form.awarded.error} value={form.awarded.value} placeholder={'S. Adjudicadas'} onChange={changeForm} />
          </div>
          <div className={styles.input}>
            <TextField label={'S. Activas'} name="actives" error={form.actives.error} value={form.actives.value} placeholder={'actives'} onChange={changeForm} />
          </div>
        </div>
        <div className={styles.single}>
          <div className={styles.input}>
            <TextField label={'Pujas realizadas'} name="bids" error={form.bids.error} value={form.bids.value} placeholder="" onChange={changeForm} />
          </div>
        </div>
        <div className={styles.date}>
          <h4 className={styles.title}>Última conexión</h4>
          <label htmlFor="date" className={styles.block}>
            <DatePicker
              locale="es"
              placeholderText="Selecciona una fecha"
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              showDisabledMonthNavigation
              id="date"
              selected={date}
              onChange={(date: any) => setDate(date)}
            />
            <CalendarFilterIcon />
          </label>
        </div>
        <div className={styles.bottom}>
          <Button>Aplicar filtros</Button>
          <span className={styles.link}>Limpiar filtros</span>
        </div>
      </div>
    </motion.div>
  )
})
