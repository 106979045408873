import { observer } from 'mobx-react-lite'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { getRolesAllApi } from '../../api/rolesApi'
import { Pagination } from '../../components/Pagination/Pagination'
import AppState from '../../store/AppState'
import styles from './RolesPage.module.scss'
import { RolesItem } from './components/RolesItem'

/* const data: TRolesItem[] = [
  {
    fullName: 'Juan Alonso Martinez',
    dni: '6543278Y',
    phone: '623 887 765',
    email: 'j.a.martinez@gmail.com',
    role: 'Admin',
  },
  {
    fullName: 'Andrea García Saz',
    dni: '5563638C',
    phone: '673 587 762',
    email: 'andreagarcia.s@gmail.com',
    role: 'Particular',
  },
  {
    fullName: 'Sandra Márquez Vic',
    dni: '6543278Y',
    phone: '623 887 765',
    email: 'sandra.m.v@gmail.com',
    role: 'Admin',
  },
] */
export const RolesPage: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [len, setLen] = useState(0)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage] = useState(3)
  const [items, setItems] = useState<getRolesAllApi[] | null>(null)

  useEffect(() => {
    getRolesAllApi({ take: postsPerPage, skip: count }).then((res) => {
      setLen(res.data.count)
      setItems(res.data.users)
      console.log(res.data)
    })
  }, [count])

  const paginates = (pageNumber: SetStateAction<number>) => {
    setCurrentPage(pageNumber)
    setCount(postsPerPage * Number(pageNumber))
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const previousPage = () => {
    if (currentPage >= 0) {
      setCurrentPage((prev) => prev - 1)
      setCount((prev) => prev - postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const nextPage = () => {
    if (currentPage !== Math.ceil(len / postsPerPage)) {
      setCurrentPage((prev) => prev + 1)
      setCount((prev) => prev + postsPerPage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  const list = items?.map((item) => <RolesItem item={item} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.top}>
        <h2 className={styles.title}>Roles</h2>
      </div>
      <div className={styles.list}>{list}</div>
      {len > postsPerPage && (
        <div className={styles.pagination}>
          <Pagination postsPerPage={postsPerPage} totalPosts={len} paginate={paginates} previousPage={previousPage} nextPage={nextPage} currentPage={currentPage} />
        </div>
      )}
    </div>
  )
})
