import { observer } from 'mobx-react-lite'
import {FC} from 'react'
import UserState from '../../store/UserState'
import { Users } from './components/Users/Users'
import { UsersDetails } from './components/UsersDetails/UsersDetails'
import styles from './UsersPage.module.scss'

export const UsersPage: FC = observer(() => {
  const step = UserState.userLink


  return <div className={styles.root}>{step ? <UsersDetails /> : <Users />}</div>
})
