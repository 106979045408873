import { apiService } from '../services/apiServices/apiService'
import { TAuction } from '../store/AuctionState'

type TId = Omit<TAuctionApi, 'name'>

export type TPagData = {
  role: 'PROFESSIONAL' | 'PARTICULAR' | 'ADMIN'
  take: number
  skip: number
}
export type TAuctionEditFile = {
  auctionId: string
  id: string
  name: string
}
export type TAuctionLinkVideo = {
  id: string
  link?: string
  auctionId: string
}
export type TAuctionImages = {
  id: string
}
export type TAuctionObj = {
  id: string
  reference: 1
  address: string
  postcode: string
  cadastralReference: string
  plotMeters: number
  usableMeters: number
  description: string
  internalObservations: string
  auctionStartingPrice: number
  endAuction: string
  yearConstruction: number
  constructedMeters: number
  bedrooms: number
  bathrooms: number
  pool: boolean
  garage: boolean
  elevator: boolean
  terrace: boolean
  garden: boolean
  typePropertyId: string
  city: string
  provinceId: string
  propertyStatusId: string
  emissionId: string
  consumptionId: string
  userId: string
  createdAt: string
  biddingTranche: number
  TypeProperty: TAuctionApi | null
  AuctionDoc: TId[]
  AuctionMedia: TAuctionImages[]
  Province: TAuctionApi | null
  Emission: TAuctionApi | null
  AuctionMediaLink: TAuctionLinkVideo[]
  Consumption: TAuctionApi | null
  PropertyStatus: TAuctionApi | null
  Deposit: { id: string; userId: string; auctionId: string; status: boolean; price: number; createdAt: string }[]
  Bid: { auctionId: string; bid: number; id: string; userId: string; sum: number; createdAt: string }[]
  status: 'PROCESS' | 'UNDER_CONSIDERATION' | 'APPROVED' | 'REJECTED'
}
export type TAuctionAllApi = {
  skip?: number
  take?: number
  count: number
  auctions: TAuctionObj[]
}
export type TReferenceApi = { reference: number }

export type TAuctionApi = { id: string; name: string }

export type TAuctionFileApi = { id: string; type?: 'file' | 'link'; size?: string; name: string; auctionId: string }

export type TAuctionFile = { id: string; name: string; auctionId: string }

/* Get */
export const getReferenceApi = async () => {
  return await apiService<TReferenceApi>('get', 'auction/getReference')
}

export const getTypePropertyApi = async () => {
  return await apiService<TAuctionApi[]>('get', 'typeProperty')
}

export const getPropertyStatusApi = async () => {
  return await apiService<TAuctionApi[]>('get', 'propertyStatus')
}

export const getEmissionApi = async () => {
  return await apiService<TAuctionApi[]>('get', 'emission')
}

export const getСonsumptionApi = async () => {
  return await apiService<TAuctionApi[]>('get', 'consumption')
}
export const getAuctionAllApi = async (params: { take: number; skip: number; which?: 'all' | 'underConsideration' | 'approved' | 'rejected' | 'final' }) => {
  return await apiService<TAuctionAllApi>('get', 'auction/', params)
}
export const getAuctionByIdApi = async (id: string) => {
  return await apiService<TAuctionObj>('get', 'auction/', { id })
}

export const getMyAuctionAllApi = async (payload: TPagData) => {
  return await apiService<TAuctionAllApi>('get', 'auction/my', payload)
}

/* Post */
export const postUploadFileApi = async (data: any) => {
  return await apiService<TAuctionFile[]>('post', 'auction/uploadFile', data)
}
export const postUploadLinkApi = async (data: { reference: number; link: string }) => {
  return await apiService<TAuctionLinkVideo>('post', 'auction/addLink', data)
}
export const postAuctionApi = async (data: TAuction) => {
  return await apiService<TAuction>('post', 'auction/', data)
}

export const updateAuctionApi = async (data: TAuction) => {
  return await apiService<TAuction>('post', 'auction/updateAuction', data)
}

export const deleteAuctionById = async (id: string) => {
  return await apiService('post', 'auction/deleteAuction', { id })
}

type TResGetPaymentDataCreateAuctionApi = {
  formData: {
    address: string
    countryId: string
    id: string
    phone: string
    postalCode: string
    userId: string
    userName: string
    userSurnames: string
    Country: {
      id: string
      name: string
    }
  }
  payData: {
    clientSecret: string
    product: {
      description: string
      idStripe: string
      name: string
      price: string
    }
  }
}

export const getPaymentDataCreateAuctionApi = async () => {
  return await apiService<TResGetPaymentDataCreateAuctionApi>('get', 'auction/getPaymentDataCreateAuction')
}

export const paymentCreateAuctionApi = async (data: { idPayment: string }) => {
  return await apiService('post', 'auction/paymentCreateAuction', data)
}

export const updateStatusApi = async (data: { id: string; status: 'APPROVED' | 'REJECTED' }) => {
  return await apiService<{ message: 'Ok' }>('post', 'auction/updateStatus', data)
}



export const deleteFileApi = async (data: {id: string}) => {
  return await apiService('post', 'auction/deleteFile', data)
}