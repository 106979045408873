import es from 'date-fns/locale/es'
import { registerLocale } from 'react-datepicker'
registerLocale('es', es)

export const dateFormat = (date: number) => {
  const dateInMs = date
  const res = new Date(dateInMs).toString()
  return new Intl.DateTimeFormat('en-GB').format(new Date(res))
}

export const dateFormatFull = (date: string) => {
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' }
  return new Intl.DateTimeFormat('en-GB', options).format(new Date(date))
}

export const isWeekday = (date: Date) => {
  const day = date.getDay()
  return day !== 0 && day !== 6
}

export const dateTime = (date: string) => {
  const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: false }

  return new Intl.DateTimeFormat('es', options).format(new Date(date))
}
