import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CameraIcon, CheckCircleIcon, ExitIcon, GlobalIcon, LoaderIcon, MachineIcon, MoneyIcon, NotesIcon } from '../../components/Icons/Icons'
import AppState from '../../store/AppState'
import AuctionState from '../../store/AuctionState'
import AuthState from '../../store/AuthState'
import styles from './NewAuctionPage.module.scss'
import { AuctionForm } from './components/AuctionForm/AuctionForm'
import { CharactForm } from './components/CharactForm/CharactForm'
import { Complete } from './components/Complete/Complete'
import { Conditions } from './components/Conditions/Conditions'
import { DescriptionForm } from './components/DescriptionForm/DescriptionForm'
import { MediaForm } from './components/MediaForm/MediaForm'
import { PayForm } from './components/PayForm/PayForm'
import { Preview } from './components/Preview/Preview'
import { PropertyForm } from './components/PropertyForm/PropertyForm'

type TStep = {
  icon: ReactNode
  text: string
}

const dataStep: TStep[] = [
  {
    icon: <GlobalIcon />,
    text: 'Inmueble',
  },
  {
    icon: <NotesIcon />,
    text: 'Características',
  },
  {
    icon: <MachineIcon />,
    text: 'Descripción',
  },
  {
    icon: <CameraIcon />,
    text: 'Medios',
  },
  {
    icon: <MoneyIcon />,
    text: 'Subasta',
  },
]

const dataFormParticular: JSX.Element[] = [<PropertyForm />, <CharactForm />, <DescriptionForm />, <MediaForm />, <AuctionForm />, <Preview />, <Conditions />, <PayForm />, <Complete />]
const dataFormProfessional: JSX.Element[] = [<PropertyForm />, <CharactForm />, <DescriptionForm />, <MediaForm />, <AuctionForm />, <Preview />, <Complete />]

export const NewAuctionPage: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const navigate = useNavigate()
  const step = AuctionState.step

  useEffect(() => {
    if (AuthState.user?.type !== 'PROFESSIONAL' && AuthState.user?.type !== 'PARTICULAR') {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    return () => {
      AuctionState.resetObj()
    }
  }, [])

  const list = dataStep.map((item, i) => (
    <div className={`${styles.item} ${i === step ? styles.active : ''} ${i < step ? styles.ready : ''}`} key={i}>
      <span className={styles.icon}>{item.icon}</span>
      <div className={styles.status}>
        {i === step && (
          <span className={styles.loader}>
            <LoaderIcon />
          </span>
        )}
        {i > step && <span className={styles.default}></span>}
        {i < step && (
          <span className={styles.check}>
            <CheckCircleIcon />
          </span>
        )}
      </div>
      <span className={styles.text}>{item.text}</span>
    </div>
  ))

  const dataForm = AuthState.user?.type === 'PROFESSIONAL' ? dataFormProfessional[step] : AuthState.user?.type === 'PARTICULAR' ? dataFormParticular[step] : null

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      {step === 0 ? (
        <Link to="/" className={styles.exit} onClick={() => AuctionState.setPrevStep()}>
          <ExitIcon />
        </Link>
      ) : (
        <div className={styles.exit} onClick={() => AuctionState.setPrevStep()}>
          <ExitIcon />
        </div>
      )}
      {step < 5 && (
        <div className={styles.top}>
          <h2 className={styles.title}>Nueva subasta</h2>
          <div className={styles.list}>{list}</div>
        </div>
      )}

      {dataForm}
    </div>
  )
})
