import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { deleteUserByIdApi } from '../../../api/usersApi'
import AppState from '../../../store/AppState'
import ModalState from '../../../store/ModalState'
import UpdateState from '../../../store/UpdateState'
import UserState from '../../../store/UserState'
import { FADE_IN } from '../../../utils/animation/fade'
import { Button } from '../../Button/Button'
import { ExitIcon } from '../../Icons/Icons'
import styles from './ModalUserDelete.module.scss'

export const ModalUserDelete: FC = observer(() => {
  const [isOpenDeposit, setIsOpenDeposit] = useState(false)

  const width = AppState.widthWindow
  const isMobile = width <= 768

  const onDelete = () => {
    deleteUserByIdApi(UserState.id)
      .then((res) => {
        UpdateState.setUsersUpdate()
        ModalState.hideModalUserDelete()
      })
      .catch((err) => {
        if (err.response?.data?.message === 'Open Deposits') {
          setIsOpenDeposit(true)
        }
      })
  }

  const closeModal = () => {
    setIsOpenDeposit(false)
    ModalState.hideModalUserDelete()
  }

  return (
    <motion.div {...FADE_IN} className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <button className={styles.close} onClick={closeModal}>
        <ExitIcon />
      </button>
      <div className={styles.content}>
        <h2 className={styles.title}>
          {!isOpenDeposit ? '¿Está seguro de que desea eliminar el usuario?' : 'You can not delete a user with active deposits.'}
        </h2>
        <div className={styles.btn}>
          {!isOpenDeposit ? (
            <>
              <Button onClick={onDelete}>Sí</Button>
              <Button onClick={closeModal}>No</Button>
            </>
          ) : <Button onClick={closeModal}>Cerca</Button>}
        </div>
      </div>
    </motion.div>
  )
})
