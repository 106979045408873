import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import ModalState from '../../../store/ModalState'
import styles from './ModalFoto.module.scss'

export const ModalFoto: FC = observer(() => {
  const { img, show } = ModalState.modalFoto
  const [thumb, setThumb] = useState(true)

  useEffect(() => {
    ModalState.resetModalFoto()
  }, [])

  const onClose = () => {
    setThumb(false)
    ModalState.hideModalFoto()
  }

  return (
    <div className={`${styles.root} ${show ? styles.active : ''}`}>
      <div className={`${styles.content} ${thumb ? styles.active : ''}`}>
        <div className={styles.img} style={{ backgroundImage: `url(${img})` }}>
          <div className={styles.overlay}>
            <button className={styles.close} onClick={onClose}></button>
          </div>
        </div>
      </div>
    </div>
  )
})
