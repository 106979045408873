import { makeAutoObservable } from 'mobx'
import { TAuctionEditFile, TAuctionObj } from '../api/auctionApi'
import { TResCreateDepositApi } from '../api/depositApi'

export type TModalVideo = {
  type: 'video'
  img?: string
  linkVideo?: string
  show?: boolean
}

export type TModalFoto = {
  img: string
  show?: boolean
}
export type TModalMap = {
  link: string
  show?: boolean
}
class ModalState {
  auctionId: string = ''
  data: TAuctionObj | null = null
  images: TAuctionEditFile[] | null = null
  documents: TAuctionEditFile[] | null = null
  step: number = 0
  modalAuctionEdit: boolean = false
  modalAuctionDelete: boolean = false
  modalAddBids: boolean = false
  modalStatusBids: 'handler' | 'user' = 'handler'
  modalPay: boolean = false
  modalCodes: boolean = false
  modalCodesType: 'create' | 'update' = 'create'
  modalRoles: boolean = false
  modalRolesType: 'create' | 'update' = 'create'
  modalServices: boolean = false
  modalUserDelete: boolean = false
  modalSearch: boolean = false
  modalPassword: boolean = false
  modalAccount: boolean = false
  modalVideo: TModalVideo = { type: 'video', img: '', linkVideo: '', show: false }
  modalFoto: TModalFoto = { img: '', show: false }
  modalMap: TModalMap = { link: '', show: false }
  modalContact: boolean = false
  modalLegal: boolean = false

  legalStatus: boolean = false
  otherUserData: null | TResCreateDepositApi = null

  constructor() {
    makeAutoObservable(this)
  }

  setModalEditData(data: TAuctionObj) {
    this.data = data
  }

  updateEditData(obj: any) {
    if (this.data) {
      let copy = Object.assign(this.data, obj)

      this.data = copy
    }
  }

  setImages(images: TAuctionEditFile[]) {
    this.images = images
  }

  setDocuments(doc: TAuctionEditFile[]) {
    this.documents = doc
  }

  setAuctionId(id: string) {
    this.auctionId = id
  }

  toggleModalAuctionEdit(val: boolean) {
    this.modalAuctionEdit = val
  }

  toggleModalAuctionDelete(val: boolean) {
    this.modalAuctionDelete = val
  }

  toggleModalBids(val: boolean) {
    this.modalAddBids = val
  }

  changeOtherUserData = (data: TResCreateDepositApi | null) => {
    this.otherUserData = data
  }

  changeStatusBids(val: 'handler' | 'user') {
    this.modalStatusBids = val
  }
  showModalPay() {
    this.modalPay = true
  }

  toggleModalLegal = (value: boolean) => {
    this.modalLegal = value
  }

  changeLegalStatus = (value: boolean) => {
    this.legalStatus = value
  }

  hideModalPay() {
    this.modalPay = false
  }

  setModalCodesType(type: 'create' | 'update') {
    this.modalRolesType = type
  }

  showModalRoles() {
    this.modalRoles = true
  }

  hideModalRoles() {
    this.modalRoles = false
  }

  setModalRolesType(type: 'create' | 'update') {
    this.modalCodesType = type
  }

  showModalCodes() {
    this.modalCodes = true
  }

  hideModalCodes() {
    this.modalCodes = false
  }

  showModalService() {
    this.modalServices = true
  }

  hideModalService() {
    this.modalServices = false
  }

  showModalUserDelete() {
    this.modalUserDelete = true
  }

  hideModalUserDelete() {
    this.modalUserDelete = false
  }

  showModalSearch() {
    this.modalSearch = true
  }

  hideModalSearch() {
    this.modalSearch = false
  }

  setModalPassword() {
    this.modalPassword = !this.modalPassword
  }

  setModalAccount() {
    this.modalAccount = !this.modalAccount
  }

  setModalVideo(obj: TModalVideo) {
    this.modalVideo = obj
    this.modalVideo.show = true
  }

  resetModalVideo() {
    this.modalVideo = { type: 'video', img: '', linkVideo: '', show: false }
  }

  hideModalVideo() {
    this.modalVideo.show = false
  }

  setModalFoto(obj: TModalFoto) {
    this.modalFoto = obj
    this.modalFoto.show = true
  }

  resetModalFoto() {
    this.modalFoto = { img: '', show: false }
  }

  hideModalFoto() {
    this.modalFoto.show = false
  }

  setModalMap(obj: TModalMap) {
    this.modalMap = obj
    this.modalMap.show = true
  }

  resetModalMap() {
    this.modalMap = { link: '', show: false }
  }

  hideModalMap() {
    this.modalMap.show = false
  }

  setModalContact() {
    this.modalContact = !this.modalContact
  }

  setPrevStep() {
    if (this.step > 0) {
      this.step = this.step - 1
    }
  }

  setNextStep() {
    this.step = this.step + 1
  }

  resetStep() {
    this.step = 0
  }
}

export default new ModalState()
