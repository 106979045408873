import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import img from '../../../assets/other/modal-image.png'
import AppState from '../../../store/AppState'
import ModalState from '../../../store/ModalState'
import { Button } from '../../Button/Button'
import styles from './ModalPassword.module.scss'

export const ModalPassword: FC = observer(() => {
  const isActive = ModalState.modalPassword
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const onHandler = () => {}
  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper} ${isActive ? styles.active : ''}`}>
      <div className={styles.content}>
        <button className={styles.close} onClick={() => ModalState.setModalPassword()}></button>
        <h3 className={styles.title}>Tu contraseña se ha actualizado correctamente</h3>
        <span className={styles.img}>
          <img src={img} alt="" />
        </span>
        <div className={styles.btn}>
          <Button onClick={onHandler}>Iniciar sesión</Button>
        </div>
      </div>
    </div>
  )
})
