import { apiService } from '../services/apiServices/apiService'
import {TAuctionObj} from "./auctionApi";

export type TMonthStat = {
  month: string
  count: number
}

export type TMonthStatUser = {
  month: string
  particular: number
  professional: number
}

export type TGetStatAllApi = {
  countAuctions: number
  countAuctions6Month: number
  countAuctionsMonth: number
  countAuctionsYear: number
  countBids: number
  countBids6Month: number
  countBidsMonth: number
  countBidsYear: number
  countParticular: number
  countProfessional: number
  statisticAuction: TMonthStat[]
  statisticBid: TMonthStat[]
  statisticUsers: TMonthStatUser[]
}

export const getStatAllApi = async () => {
  return await apiService('get', 'statistic/all')
}


export type TGetStatisticUserApi = {
  sumDeposits6Month: number
  sumDepositsMonth: number
  sumDepositsYear: number
  statisticDeposits: {month: string, sum: number}[]
  auctionsBid: TAuctionObj[]
}

export const getStatisticUserApi = async () => {
  return await apiService<TGetStatisticUserApi>('get', 'statistic/userStatistic')
}