import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AppState from '../../../../store/AppState'
import { MainChart } from './MainChart/MainChart'
import styles from './MainDeposit.module.scss'
import {TGetStatisticUserApi} from '../../../../api/statApi'

type TProps = {
  userStatistic: TGetStatisticUserApi
}

export const MainDeposit: FC<TProps> = observer(({userStatistic}) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h3 className={styles.title}>Mis depósitos</h3>
      <MainChart userStatistic={userStatistic} />
    </div>
  )
})
