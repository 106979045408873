import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import userImg from '../../../../assets/user/user.png'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import ModalState from '../../../../store/ModalState'
import SearchState from '../../../../store/SearchState'
import { DeleteWhiteIcon, DownIcon, NotifIcon, SearchIcon } from '../../../Icons/Icons'
import { Logo } from '../../../Logo/Logo'
import styles from './Header.module.scss'
import { Menu, TMenu } from './Menu/Menu'
import { MobileMenu } from './MobileMenu/MobileMenu'

const menuMainData: TMenu[] = [
	{
		title: 'Inicio',
		path: '/',
	},
	{
		title: 'Subastas',
		path: '/auctions',
	},
	{
		title: 'Mis subastas',
		path: '/my-auctions',
	},
	{
		title: 'Mis búsquedas',
		path: '/my-searches',
	},
	{
		title: 'Mis depósitos',
		path: '/my-deposits',
	},
	{
		title: 'Mis pujas',
		path: '/my-bids',
	},
	{
		title: 'Servicios',
		path: '/services',
	},
]

const menuAdminData: TMenu[] = [
	{
		title: 'Inicio',
		path: '/',
	},
	{
		title: 'Usuarios',
		path: '/users',
	},
	{
		title: 'Subastas',
		path: '/auctions',
	},
	{
		title: 'Pujas',
		path: '/bids',
	},
	{
		title: 'Depósitos',
		path: '/deposits',
	},
	{
		title: 'Servicios',
		path: '/services',
	},
	{
		title: 'Mis búsquedas',
		path: '/my-searches',
	},
	{
		title: 'Configuración',
		path: '',
		dropList: [
			{
				title: 'Roles',
				path: '/roles',
			},
			{
				title: 'Accesos',
				path: '/access',
			},
			// {
			//   title: 'Leads',
			//   path: '/',
			// },
			// {
			//   title: 'SMTP',
			//   path: '/',
			// },
		],
	},
	{
		title: 'Gestión',
		path: '',
		dropList: [
			{
				title: 'Códigos postales',
				path: '/postcodes',
			},
			// {
			//   title: 'Gestión cupones',
			//   path: '/',
			// },
			// {
			//   title: 'Gestión cupones',
			//   path: '/',
			// },
			// {
			//   title: 'Gestión stripe',
			//   path: '/',
			// },
		],
	},
]
export const Header: FC = observer(() => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const user = AuthState.user
	const navigate = useNavigate()
	const [isActive, setIsActive] = useState(false)
	const [isMenu, setIsMenu] = useState(false)
	const plan = AuthState.user?.type === 'PROFESSIONAL' || AuthState.user?.type === 'PARTICULAR'
	const onClick = () => {
		AuthState.logout()
		navigate('/auth')
	}

	const deleteSearch = () => {
		SearchState.setAuctions([])
		SearchState.setIsSearch(false)
	}

	const onClose = () => {
		setIsMenu(false)
	}

	return (
		<header className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<div className="container">
				<div className={styles.wrap}>
					<div className={styles.top}>
						<NavLink to="/" className={styles.logo}>
							{isMobile ? <Logo width="150px" height="50px" /> : <Logo />}
						</NavLink>
						<div className={styles.form}>
							{SearchState.isSearch && (
								<button className={styles.search} onClick={() => deleteSearch()}>
									<DeleteWhiteIcon />
								</button>
							)}
							<button className={styles.search} onClick={() => ModalState.showModalSearch()}>
								<SearchIcon />
							</button>
							{plan && (
								<>
									<NavLink to="/new-auction">
										<button className={styles.add}>
											<span></span>
											Nueva subasta
										</button>
									</NavLink>
									<div className={styles.notif}>
										<NotifIcon />
										<span className={styles.count}>23</span>
									</div>
								</>
							)}
							<div className={styles.user}>
								<span className={`${styles.drop} ${isActive ? styles.active : ''}`}>
									<DownIcon />
								</span>
								<span className={styles.img} style={{ backgroundImage: `url(${userImg})` }} onClick={() => setIsActive(!isActive)}></span>
								<div className={`${styles.menu} ${isActive ? styles.active : ''}`}>
									<NavLink to="/profile" className={styles.link}>
										My profile
									</NavLink>
									<button className={styles.link} onClick={onClick}>
										Log out
									</button>
								</div>
								{isMobile && (
									<>
										<div className={`${styles.hamburg} ${isMenu ? styles.active : ''}`} onClick={() => setIsMenu(!isMenu)}>
											<span className={styles.top}></span>
											<span className={styles.middle}></span>
											<span className={styles.bottom}></span>
										</div>
									</>
								)}
							</div>
							{user?.type === 'ADMIN' && isMobile ? (
								<MobileMenu onClose={onClose} isActive={isMenu} items={menuAdminData} />
							) : (
								<MobileMenu onClose={onClose} isActive={isMenu} items={menuMainData} />
							)}
						</div>
					</div>
					{user?.type === 'ADMIN' ? !isMobile && <Menu items={menuAdminData} /> : !isMobile && <Menu items={menuMainData} />}
				</div>
			</div>
		</header>
	)
})
