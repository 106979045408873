import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import particularProfile from '../../../../assets/authPage/particularProfile.svg'
import particularProfileActive from '../../../../assets/authPage/particularProfileActive.svg'
import professionalProfile from '../../../../assets/authPage/professionalProfile.svg'
import professionalProfileActive from '../../../../assets/authPage/professionalProfileActive.svg'
import { ExitIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import styles from './ProfileSelection.module.scss'

export type TProfile = 'professional' | 'particular'

type TProps = {
  closeProfileSelection: () => void
  changeSelectedProfile: (profile: TProfile) => void
}

export const ProfileSelection: FC<TProps> = observer(({ closeProfileSelection, changeSelectedProfile }) => {
  const [profile, setProfile] = useState<null | TProfile>(null)

  const changeProfile = (profile: TProfile) => {
    setProfile(profile)
    changeSelectedProfile(profile)
  }
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <div className={styles.exit} onClick={closeProfileSelection}>
        <ExitIcon />
      </div>
      <h1 className={styles.title}>¿Cuál es tu perfil?</h1>
      <div className={styles.body}>
        <div className={styles.block} onClick={() => changeProfile('professional')}>
          <div className={`${styles.card} ${profile === 'professional' ? styles.cardActive : ''}`}>
            <img src={profile === 'professional' ? professionalProfileActive : professionalProfile} alt={'professionalProfile'} />
          </div>
          <p className={styles.name}>Profesional</p>
        </div>
        <div className={styles.block} onClick={() => changeProfile('particular')}>
          <div className={`${styles.card} ${profile === 'particular' ? styles.cardActive : ''}`}>
            <img src={profile === 'particular' ? particularProfileActive : particularProfile} alt={'particularProfile'} />
          </div>
          <p className={styles.name}>Particular</p>
        </div>
      </div>
    </div>
  )
})
