import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { TAuctionFileApi, postUploadFileApi, postUploadLinkApi } from '../../../../api/auctionApi'
import { Button } from '../../../../components/Button/Button'
import { AcortarIcon, ExitIcon, RightArrowIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import AuctionState from '../../../../store/AuctionState'
import { ImageFile } from '../ImageFile/ImageFile'
import styles from './MediaForm.module.scss'

export const MediaForm: FC = observer(() => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const ref = AuctionState.obj.reference
	const step = AuctionState.step
	const size = String(Math.floor(Math.random() * 1000))
	const [file, setFile] = useState<null | File[]>(null)
	const [result, setResult] = useState<TAuctionFileApi[]>([])
	const [link, setLink] = useState<string>('')
	const [nameFile, setNameFile] = useState<string | null>(null)

	useEffect(() => {
		if (file) {
			const formData = new FormData()
			formData.append('reference', String(ref))

			console.log(file.length)
			for (let i = 0; i < file.length; i++) {
				formData.append('media', file[i])
			}
			// formData.append('media', file)
			postUploadFileApi(formData)
				.then((res) => {
					const data = res.data
					console.log(data)
					data.forEach((item) => {
						const exist = result.some((el) => el.name === item.name)

						if (!exist) {
							setResult((prev) => [...prev].concat(item))
						}
					})
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [file])

	useEffect(() => {
		setResult(AuctionState.images)
	}, [])

	useEffect(() => {
		AuctionState.setImg(result)
	}, [result])

	const changeFile = (file: File[]) => {
		setFile(file)
	}

	const changeLink = () => {
		const id = String(Math.floor(Math.random() * 100000))
		if (link !== '') {
			setResult((prev) => [...prev, { id: id, type: 'link', name: 'Link externo', size: link, auctionId: id }])
			postUploadLinkApi({ reference: ref, link: link })
				.then((res) => {
					console.log(res)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	const removeFile = (id: string) => {
		setResult((prev) => prev.filter((el) => el.id !== id))
	}

	const onSubmit = () => {
		AuctionState.setNextStep()
	}
	const list = result.map((item) => (
		<div className={styles.item} key={item.id}>
			{item.name.split('.')[1] === 'png' ||
			item.name.split('.')[1] === 'jpeg' ||
			item.name.split('.')[1] === 'jpg' ||
			item.name.split('.')[1] === 'pdf' ? (
				<span className={styles.loader}>100%</span>
			) : (
				<span className={styles.acortar}>
					<AcortarIcon />
				</span>
			)}
			<div className={styles.info}>
				<h5 className={styles.title}>{item.name}</h5>
				{item.name.split('.')[1] === 'png' ||
				item.name.split('.')[1] === 'jpeg' ||
				item.name.split('.')[1] === 'jpg' ||
				item.name.split('.')[1] === 'pdf' ? (
					<span className={styles.size}>
						{item.size}Kb/{item.size}Kb
					</span>
				) : (
					<span className={styles.size}>{item.size}</span>
				)}
			</div>
			<span className={styles.remove} onClick={() => removeFile(item.id)}>
				<ExitIcon />
			</span>
		</div>
	))
	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<div className={styles.content}>
				<div className={styles.left}>
					<div className={styles.file}>
						<ImageFile title="Sube aquí tus imágenes" changeFile={changeFile} />
						{nameFile}
					</div>
					<div className={styles.link}>
						<h5 className={styles.title}>Añade un link a un video o 360º</h5>
						<div className={styles.block}>
							<input
								type="text"
								className={styles.input}
								value={link}
								placeholder="Pega aquí el link"
								onChange={(e) => setLink(e.currentTarget.value)}
							/>
							<button className={styles.add} onClick={changeLink}>
								<RightArrowIcon />
							</button>
						</div>
					</div>
				</div>
				<div className={styles.right}>
					<div className={styles.top}>
						<h4 className={styles.title}>Imágenes subidas</h4>
						<span className={styles.length}>{result.length}</span>
					</div>
					<div className={styles.list}>{list}</div>
				</div>
			</div>
			<div className={styles.navigation}>
				{step > 0 && (
					<span className={styles.prev} onClick={() => AuctionState.setPrevStep()}>
						<RightArrowIcon />
						Volver al paso anterior
					</span>
				)}
				<span className={styles.next} onClick={onSubmit}>
					<Button color="blue">Continuar</Button>
				</span>
			</div>
		</div>
	)
	/* const width = AppState.widthWindow
  const isMobile = width <= 768
  const ref = AuctionState.obj.reference
  const step = AuctionState.step
  const size = String(Math.floor(Math.random() * 1000))
  const [files, setFiles] = useState<null | File[]>(null)
  const [result, setResult] = useState<TFileApi[]>([])
  const [link, setLink] = useState<string>('')
  const [nameFile, setNameFile] = useState<string | null>(null)

  useEffect(() => {
    if (files) {
      const formData = new FormData()
      formData.append('reference', String(ref))
      formData.append('media', files[0])

      postUploadFileApi(formData)
        .then((res) => {
          const arr = files.map((item: any) => ({
            path: item.path,
            lastModified: item.lastModified,
            lastModifiedDate: item.lastModifiedDate,
            name: item.name,
            size: item.size,
            type: item.type,
            webkitRelativePath: item.webkitRelativePath,
          }))
          setResult((prev) => [...prev, ...arr])
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [files])

  useEffect(() => {
    AuctionState.setImg(result)
  }, [result])

  const changeFile = (files: any) => {
    setFiles(files)
  }

  const changeLink = () => {
    postUploadLinkApi({ reference: ref, link: link })
      .then((res) => {
        setResult((prev) => [...prev, { path: '', lastModified: String(res.data.id), lastModifiedDate: '', name: String(res.data.link), size: 0, type: 'link', webkitRelativePath: '' }])
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const removeFile = (id: string) => {
    setResult((prev) => prev.filter((el) => el.id !== id))
  }

  const onSubmit = () => {
    AuctionState.setNextStep()
  }
  const list = files?.map((item) => (
    <div className={styles.item} key={item.lastModified}>
      {item.type === 'image/png' || item.type === 'application/pdf' || item.type === 'image/jpeg' ? (
        <span className={styles.loader}>100%</span>
      ) : (
        <span className={styles.acortar}>
          <AcortarIcon />
        </span>
      )}
      <div className={styles.info}>
        <h5 className={styles.title}>{item.name}</h5>
        {item.type === 'image/png' || item.type === 'application/pdf' || item.type === 'image/jpeg' ? (
          <span className={styles.size}>
            {item.size}Kb/{item.size}Kb
          </span>
        ) : (
          <span className={styles.size}>{item.size}</span>
        )}
      </div>
      <span className={styles.remove}>
        <ExitIcon />
      </span>
    </div>
  ))
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.file}>
            <ImageFile title="Sube aquí tus imágenes" changeFile={changeFile} />
            {nameFile}
          </div>
          <div className={styles.link}>
            <h5 className={styles.title}>Añade un link a un video o 360º</h5>
            <div className={styles.block}>
              <input type="text" className={styles.input} value={link} placeholder="Pega aquí el link" onChange={(e) => setLink(e.currentTarget.value)} />
              <button className={styles.add} onClick={changeLink}>
                <RightArrowIcon />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.top}>
            <h4 className={styles.title}>Imágenes subidas</h4>
            <span className={styles.length}>{result.length}</span>
          </div>
          <div className={styles.list}>{list}</div>
        </div>
      </div>
      <div className={styles.navigation}>
        {step > 0 && (
          <span className={styles.prev} onClick={() => AuctionState.setPrevStep()}>
            <RightArrowIcon />
            Volver al paso anterior
          </span>
        )}
        <span className={styles.next} onClick={onSubmit}>
          <Button color="blue">Continuar</Button>
        </span>
      </div>
    </div>
  ) */
})
