import { FC } from 'react'
import { priceFormat } from '../../../utils/price.utils'
import { PlacesIcon, ShowersIcon, SquareIcon } from '../../Icons/Icons'
import styles from './SidebarItem.module.scss'

export type TSidebarItemProps = {
  id: number
  img: string
  title: string
  location: string
  price: number
  square: number
  places: number
  showers: number
}

export const SidebarItem: FC<{ item: TSidebarItemProps }> = ({ item }) => {
  return (
    <div className={styles.root}>
      <span className={styles.img} style={{ backgroundImage: `url(${item.img})` }}></span>
      <div className={styles.content}>
        <h4 className={styles.title}>{item.title}</h4>
        <span className={styles.location}>{item.location}</span>
        <div className={styles.price}>
          <span className={styles.text}>Última puja:</span>
          <span className={styles.value}>{priceFormat(item.price)}</span>
        </div>
        <div className={styles.info}>
          <span className={styles.block}>
            <SquareIcon />
            {item.square}m<sup>2</sup>
          </span>
          <span className={styles.block}>
            <PlacesIcon />
            {item.places}
          </span>
          <span className={styles.block}>
            <ShowersIcon />
            {item.showers}
          </span>
        </div>
      </div>
    </div>
  )
}
