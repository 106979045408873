import { ChangeEvent, FC } from 'react'
import AppState from '../../store/AppState'
import styles from './TextField.module.scss'

type TProps = {
  label: string
  type?: string
  value?: string
  name?: string
  disabled?: boolean
  placeholder?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  error?: boolean
}

export const TextField: FC<TProps> = ({ label, name, value, placeholder, error, onChange, type = 'text', disabled }) => {
  const id = String(Math.floor(Math.random() * 100000))
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        id={id}
        name={name}
        value={value}
        className={`${styles.input} ${value === '' && error ? styles.error : ''}`}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  )
}
