import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AppState from '../../../../store/AppState'
import { dateFormatFull } from '../../../../utils/date.utils'
import styles from './AuctionControl.module.scss'

type TAuctionControlItem = {
	title: string
	text: string
	status: boolean
}
const data: TAuctionControlItem[] = [
	{
		title: 'Revisión subasta',
		text: 'Recuerda revisar bien todos los aspectos de la subasta antes de enviar la documentación.',
		status: true,
	},
	{
		title: 'Pendiente documentación',
		text: 'Test',
		status: false,
	},
	{
		title: 'Validación subasta',
		text: 'Test',
		status: false,
	},
]

type TAuctionControlProps = {
	created: string
	update: string
}
export const AuctionControl: FC<TAuctionControlProps> = observer(({ created, update }) => {
	const width = AppState.widthWindow
	const isMobile = width <= 768

	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<h4 className={styles.title}>Control subasta</h4>
			<div className={styles.wrap}>
				<div className={styles.block}>
					<span className={styles.value}>{dateFormatFull(created)}</span>
					<span className={styles.text}>Validación subasta</span>
				</div>
				<div className={styles.block}>
					<span className={styles.value}>{dateFormatFull(update)}</span>
					<span className={styles.text}>Última actualización</span>
				</div>
			</div>
		</div>
	)
})
