import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { TAuctionObj, deleteAuctionById, getAuctionAllApi, getAuctionByIdApi } from '../../../api/auctionApi'
import AppState from '../../../store/AppState'
import AuctionState from '../../../store/AuctionState'
import ModalState from '../../../store/ModalState'
import UpdateState from '../../../store/UpdateState'
import { FADE_IN } from '../../../utils/animation/fade'
import { Button } from '../../Button/Button'
import { ExitIcon } from '../../Icons/Icons'
import styles from './ModalAuctionDelete.module.scss'

export const ModalAuctionDelete: FC = observer(() => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const [id, setId] = useState('')
	const [auction, setAuction] = useState<TAuctionObj | null>(null)
	const [items, setItems] = useState<TAuctionObj[] | null>(null)

	const onDelete = () => {
		if (auction?.Deposit.length === 0 || !auction?.Deposit) {
			deleteAuctionById(AuctionState.auctionId)
				.then((res) => {
					ModalState.toggleModalAuctionDelete(false)
					UpdateState.setAuctionUpdate()
				})
				.catch((err) => console.log(err))
		} else {
			alert('No se puede eliminar la subasta con depósitos!')
		}
	}

	useEffect(() => {
		if (AuctionState.auctionId) {
			getAuctionByIdApi(AuctionState.auctionId).then((res) => setAuction(res.data))
		}
		getAuctionAllApi({ take: 1500, skip: 0 }).then((res) => setItems(res.data.auctions))
	}, [])

	return (
		<motion.div {...FADE_IN} className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<button className={styles.close} onClick={() => ModalState.toggleModalAuctionDelete(false)}>
				<ExitIcon />
			</button>
			<div className={styles.content}>
				<h2 className={styles.title}>¿Está seguro de que desea eliminar el Subasta?</h2>
				<div className={styles.btn}>
					<Button onClick={onDelete}>Sí</Button>
					<Button onClick={() => ModalState.toggleModalAuctionDelete(false)}>No</Button>
				</div>
			</div>
		</motion.div>
	)
})
