import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import {TAuctionObj, getAuctionAllApi, getMyAuctionAllApi} from '../../../../api/auctionApi'
import { Sidebar } from '../../../../components/Sidebar/Sidebar'
import AppState from '../../../../store/AppState'
import { MainAuction } from '../MainAuction/MainAuction'
import { MainBids } from '../MainBids/MainBids'
import { MainDeposit } from '../MainDeposit/MainDeposit'
import { MainSearches } from '../MainSearches/MainSearches'
import {AUCTIONS_PATH} from '../../../../services/mainServices/RoutesService'
import { MainSlider } from '../MainSlider/MainSlider'
import styles from './MainDefault.module.scss'
import {getStatisticUserApi, TGetStatisticUserApi} from '../../../../api/statApi'
import UserState from '../../../../store/UserState'
import {useNavigate} from 'react-router-dom'
import AuthState from "../../../../store/AuthState";

export const MainDefault: FC = observer(() => {
  const width = AppState.widthWindow
  const firstSignIn = UserState.firstSignIn
  const navigate = useNavigate()
  const isMobile = width <= 768
  const [auctionList, setAuctionList] = useState<TAuctionObj[]>([])
  const [userStatistic, setUserStatistic] = useState<TGetStatisticUserApi>({sumDepositsYear: 0, sumDeposits6Month: 0, sumDepositsMonth: 0, statisticDeposits: [], auctionsBid: []})

  // useEffect(() => {
  //   getMyAuctionAllApi({ role: AuthState.user?.type === 'PARTICULAR' ? 'PARTICULAR' : 'PROFESSIONAL', take: 3, skip: 0 })
  //     .then((res) => {
  //       setItems(res.data.auctions)
  //     })
  //     .catch((err) => console.log(err))
  //   // if (firstSignIn) {
  //   //   navigate(AUCTIONS_PATH)
  //   // }
  // }, [])

  useEffect(() => {
    getAuctionAllApi({ take: 5, skip: 0 }).then((res) => setAuctionList(res.data.auctions))
    getStatisticUserApi()
      .then(async (res) => {
        setUserStatistic(res.data)

      if (UserState.firstSignIn) {
        getMyAuctionAllApi({ role: AuthState.user?.type === 'PARTICULAR' ? 'PARTICULAR' : 'PROFESSIONAL', take: 3, skip: 0 })
          .then((result) => {
            if (result.data.auctions.length === 0 && res.data.auctionsBid.length === 0) {
              UserState.changeFirstSignIn()
              navigate(AUCTIONS_PATH)
            }
          })
      }
      })
  }, [])
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.slider}>
        <MainSlider items={auctionList} />
      </div>
      <div className={styles.center}>
        <div className={styles.rates}>
          <MainBids userStatistic={userStatistic} />
        </div>
        <div className={styles.deposit}>
          <MainDeposit userStatistic={userStatistic}/>
        </div>
      </div>
      {/*<div className={styles.block}>*/}
      {/*  <MainSearches />*/}
      {/*</div>*/}
      <div className={styles.bottom}>
        <div className={styles.left}>
          <MainAuction />
        </div>
        {/*<div className={styles.right}>*/}
        {/*  <Sidebar title="Mis subastas favoritas" />*/}
        {/*</div>*/}
      </div>
    </div>
  )
})
