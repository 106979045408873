import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TAuctionObj, getAuctionByIdApi, updateStatusApi } from '../../api/auctionApi'
import { addBidApi } from '../../api/bidApi'
import { CloseIcon, PasteIcon } from '../../components/Icons/Icons'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import { stripePublicKey } from '../../config/stripeConfig'
import AppState from '../../store/AppState'
import AuctionState from '../../store/AuctionState'
import AuthState from '../../store/AuthState'
import ModalState from '../../store/ModalState'
import styles from './AuctionPage.module.scss'
import { AuctionCharact } from './components/AuctionCharact/AuctionCharact'
import { AuctionContent } from './components/AuctionContent/AuctionContent'
import { AuctionControl } from './components/AuctionControl/AuctionControl'
import { AuctionEnergy } from './components/AuctionEnergy/AuctionEnergy'
import { AuctionGallery } from './components/AuctionGallery/AuctionGallery'
import { AuctionHistory, TAuctionHistoryItem } from './components/AuctionHistory/AuctionHistory'
import { AuctionMap } from './components/AuctionMap/AuctionMap'
import { AuctionPrice } from './components/AuctionPrice/AuctionPrice'
import { AuctionProperty } from './components/AuctionProperty/AuctionProperty'
import { AuctionStatus } from './components/AuctionStatus/AuctionStatus'
import { AuctionTimer } from './components/AuctionTimer/AuctionTimer'
import { OrderForm } from './components/OrderForm/OrderForm'

const bidsData: TAuctionHistoryItem[] = [
	{
		id: 1,
		name: 'Juan Sánchez',
		price: 250000,
		date: '2023-08-14T08:33:36.820Z',
	},
	{
		id: 2,
		name: 'Marta Galán',
		price: 230000,
		date: '2023-08-16T08:33:36.820Z',
	},
	{
		id: 3,
		name: 'Sandra Castro',
		price: 220000,
		date: '2023-08-17T08:33:36.820Z',
	},
	{
		id: 4,
		name: 'Adrián Sanz',
		price: 210000,
		date: '2023-08-18T08:33:36.820Z',
	},
]
// const switchTitle: string[] = ['Otras características', 'Calificación energética']
export const AuctionPage: FC = observer(() => {
	const [switchTitle, setSwitchTitle] = useState(['Otras características'])
	const [bidDeposit, setBidDeposit] = useState<number>(0)
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const { id } = useParams()
	const [item, setItem] = useState<TAuctionObj>()
	const [switchIndex, setSwitchIndex] = useState(0)
	const [isDeposit, setIsDeposit] = useState(false)
	const [isOpenPayForm, setIsOpenPayForm] = useState(false)
	const [isComplete, setIsComplete] = useState<number[]>([])
	const [timeUpdateStatus, setTimeUpdateStatus] = useState<Date>(new Date())
	const userId = AuthState.user?.id
	const role = AuthState.user?.type
	const legalStatus = ModalState.legalStatus
	const otherUserData = ModalState.otherUserData

	const switchList = switchTitle.map((item, i) => (
		<button key={i} className={`${styles.item} ${i === switchIndex ? styles.active : ''}`} onClick={() => setSwitchIndex(i)}>
			{item}
		</button>
	))

	useEffect(() => {
		if (id) {
			getAuctionByIdApi(id).then((res) => {
				console.log(res.data)
				setItem(res.data)
				setIsDeposit(res.data.Deposit.some((i) => i.userId === userId))
				if (res.data.Emission || res.data.Consumption) {
					setSwitchTitle(['Otras características', 'Calificación energética'])
				}
			})
		}
	}, [timeUpdateStatus])

	useEffect(() => {
		if (legalStatus) {
			setIsOpenPayForm(true)
		}
	}, [legalStatus])

	useEffect(() => {
		if (otherUserData) {
			ModalState.toggleModalLegal(true)
		}
	}, [otherUserData])

	if (!item) {
		return <div>Loader...</div>
	}

	const handlerAddDeposit = (bid: number) => {
		setBidDeposit(bid)
		if (role === 'PARTICULAR') {
			return ModalState.toggleModalLegal(true)
		}
		if (role === 'PROFESSIONAL') {
			return ModalState.toggleModalBids(true)
		}
	}

	const handlerDepositCorrectedPayment = async () => {
		setIsOpenPayForm(false)
		if (id) {
			await addBidApi({ auctionId: id, bid: bidDeposit, otherUserId: otherUserData ? otherUserData.formData.userId : undefined })
			getAuctionByIdApi(id).then((res) => {
				setItem(res.data)
				setIsDeposit(res.data.Deposit.some((i) => i.userId === userId))
				const idMessage = new Date().getTime() + 5000

				AuctionState.setBidMessage(idMessage)

				if (otherUserData) {
					ModalState.changeOtherUserData(null)
				}
			})
		}
	}

	const handlerBid = async (amount: number) => {
		if (id) {
			addBidApi({ auctionId: id, bid: amount }).then(() => {
				getAuctionByIdApi(id)
					.then((res) => {
						setItem(res.data)
						setIsDeposit(res.data.Deposit.some((i) => i.userId === userId))
						const idMessage = new Date().getTime() + 5000

						AuctionState.setBidMessage(idMessage)
					})
					.catch(() => {
						alert('Error')
					})
			})
		}
	}

	const getLastBid = () => {
		if (item.Bid.length === 0) {
			return 0
		}

		let amount = item.auctionStartingPrice

		for (let i = 0; i < item.Bid.length; i++) {
			amount = amount + item.Bid[i].bid
		}
		return amount
	}

	const updateStatus = (status: 'APPROVED' | 'REJECTED') => {
		updateStatusApi({ id: item.id, status }).then(() => {
			setTimeUpdateStatus(new Date())
		})
	}

	return (
		<>
			{role === 'ADMIN' && (
				<div className={`${isMobile ? styles.topMobile : styles.top}`}>
					<h2 className={styles.title}>
						Subasta <span>{item.reference}</span>
					</h2>
					<div className={styles.block}>
						{/*<button className={styles.edit}>Editar</button>*/}
						{item.status !== 'REJECTED' && (
							<button className={styles.cancel} onClick={() => updateStatus('REJECTED')}>
								Rechazar subasta <CloseIcon />
							</button>
						)}
						{item.status !== 'APPROVED' && (
							<button className={styles.send} onClick={() => updateStatus('APPROVED')}>
								Enviar acuerdo de subasta <PasteIcon />
							</button>
						)}
					</div>
				</div>
			)}
			<div style={isOpenPayForm ? { maxHeight: '50vh' } : undefined} className={`${isMobile ? styles.rootMobile : styles.root}`}>
				<div className={styles.left}>
					<div className={styles.gallery}>
						{item?.AuctionMedia.length !== null && <AuctionGallery images={item?.AuctionMedia} video={item?.AuctionMediaLink} />}
					</div>
					<div className={styles.wrap}>
						<div className={styles.block}>
							<div className={styles.content}>
								<AuctionContent item={item} />
							</div>
							<div className={styles.switch}>
								<div className={styles.list}>{switchList}</div>
								{switchIndex === 0 ? <AuctionCharact item={item} /> : <AuctionEnergy />}
							</div>
						</div>
						<div className={styles.map}>
							<AuctionMap
								link={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24284.09230419263!2d-3.880684471534255!3d40.46394692982692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4184ecb204803f%3A0xcafdc908f78db4ef!2z0JzQsNGF0LDQtNCw0L7QvdC00LAsINCc0LDQtNGA0LjQtCwg0JjRgdC_0LDQvdC40Y8!5e0!3m2!1sen!2sen!4v1679550393542!5m2!1sen!2sen&zoom=0&scaleControl=false`}
							/>
						</div>
					</div>
				</div>
				<div className={styles.right}>
					<div className={styles.timer}>
						<AuctionTimer date={item.endAuction} />
					</div>
					{role !== 'ADMIN' && (
						<>
							{item.userId !== userId && (
								<div className={styles.price}>
									<AuctionPrice
										biddingTranche={item.biddingTranche}
										startPrice={item.auctionStartingPrice}
										lastBid={getLastBid()}
										isDeposit={isDeposit}
										handlerAddDeposit={handlerAddDeposit}
										handlerBind={handlerBid}
									/>
								</div>
							)}

							<div className={styles.aside}>
								<Sidebar title="También te puede interesar" />
							</div>
						</>
					)}

					{role === 'ADMIN' && (
						<>
							<div className={styles.status}>
								<AuctionStatus price={item.auctionStartingPrice} />
							</div>
							<div className={styles.control}>
								<AuctionControl created={item.createdAt} update={item.endAuction} />
							</div>
							<div className={styles.history}>
								<AuctionHistory items={bidsData} />
							</div>
							<div className={styles.property}>
								<AuctionProperty id={item.userId} role={role} />
							</div>
						</>
					)}
				</div>

				{isOpenPayForm && (
					<div style={{ position: 'absolute', top: 0, left: 0, zIndex: 10 }}>
						<Elements stripe={loadStripe(stripePublicKey)}>
							<OrderForm
								auctionId={item.id}
								closeOrderForm={() => setIsOpenPayForm(false)}
								setIsCorrectedPayment={handlerDepositCorrectedPayment}
								setError={() => {}}
							/>
						</Elements>
					</div>
				)}
			</div>
		</>
	)
})
