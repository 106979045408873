import { makeAutoObservable } from 'mobx'

class UpdateState {
  zipCodesUpdate: boolean = false
  usersUpdate: boolean = false
  updateAuction: boolean = false
  constructor() {
    makeAutoObservable(this)
  }

  setZipCodesUpdate = () => {
    this.zipCodesUpdate = !this.zipCodesUpdate
  }

  setUsersUpdate = () => {
    this.usersUpdate = !this.usersUpdate
  }

  setAuctionUpdate = () => {
    this.updateAuction = !this.updateAuction
  }
}

export default new UpdateState()
