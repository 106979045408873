import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import bgAuth from '../../assets/authPage/bgAuth.jpg'
import { Logo } from '../../components/Logo/Logo'
import { stripePublicKey } from '../../config/stripeConfig'
import AppState from '../../store/AppState'
import AuthState from '../../store/AuthState'
import styles from './AuthPage.module.scss'
import { AccountUserCreated } from './components/AccountUserCreated/AccountUserCreated'
import { CorrectPayment } from './components/CorrectPayment/CorrectPayment'
import { Error } from './components/Error/Error'
import { ForgotForm } from './components/ForgotForm/ForgotForm'
import { OrderForm } from './components/OrderForm/OrderForm'
import { ProfileSelection, TProfile } from './components/ProfileSelection/ProfileSelection'
import { RegistrationProfessional } from './components/RegistrationProfessional/RegistrationProfessional'
import { RegistrationUser } from './components/RegistrationUser/RegistrationUser'
import { SelectPlan } from './components/SelectPlan/SelectPlan'
import { SignInForm } from './components/SignInForm/SignInForm'
import { SignUpForm } from './components/SignUpForm/SignUpForm'

export type TFormName = 'signIn' | 'signUp'

export const AuthPage: FC = observer(() => {
  const [activeForm, setActiveForm] = useState<TFormName>('signIn')
  const [isForgot, setIsForgot] = useState(false)
  const [isProfileSelection, setIsProfileSelection] = useState(false)
  const [isRegistrationUser, setIsRegistrationUser] = useState(false)
  const [isAccountUserCreated, setIsAccountUserCreated] = useState(false)
  const [isRegistrationProfessional, setIsRegistrationProfessional] = useState(false)
  const [isSelectPlan, setIsSelectPlan] = useState(false)
  const [isOrderForm, setIsOrderForm] = useState(false)
  const [isCorrectPayment, setIsCorrectPayment] = useState(false)
  const [isError, setIsError] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState<null | TProfile>(null)

  const user = AuthState.user
  const userType = AuthState.user?.type

  useEffect(() => {
    if (user && !userType) {
      setIsProfileSelection(true)
    }
  }, [userType, user])

  useEffect(() => {
    if (user && userType && userType === 'PROFESSIONAL') {
      if (!user.ProfessionalPlan) {
        setIsSelectPlan(true)
      }
      if (user.ProfessionalPlan && !user.ProfessionalPlan.active) {
        setIsOrderForm(true)
      }
    }
  }, [user, userType])

  useEffect(() => {
    if (selectedProfile === 'particular') {
      setIsRegistrationUser(true)
      setIsProfileSelection(false)
    }
    if (selectedProfile === 'professional') {
      setIsRegistrationProfessional(true)
      setIsProfileSelection(false)
    }
  }, [selectedProfile])

  const changeActiveForm = (nameForm: TFormName) => {
    setActiveForm(nameForm)
    if (isForgot) {
      setIsForgot(false)
    }
  }

  const changeForgot = (value: boolean) => {
    setIsForgot(value)
  }

  const closeProfileSelection = () => {
    setIsProfileSelection(false)
  }

  const closeRegistrationUser = () => {
    setIsRegistrationUser(false)
    setSelectedProfile(null)
    setIsProfileSelection(true)
  }

  const closeAccountUserCreated = () => {
    setIsAccountUserCreated(false)
    if (AuthState.user?.type === 'PARTICULAR') {
      AuthState.setIsAuth(true)
    }
  }

  const closeRegistrationProfessional = () => {
    setIsRegistrationProfessional(false)
    setSelectedProfile(null)
    setIsProfileSelection(true)
  }

  const closeSelectPlan = () => {
    if (!user) {
      setIsSelectPlan(false)
      setIsRegistrationProfessional(true)
    }
  }

  const closeOrderForm = () => {
    setIsOrderForm(false)
    setIsSelectPlan(true)
  }

  const changeSelectedProfile = (profile: TProfile) => {
    setSelectedProfile(profile)
  }

  const setAccountUserCreated = () => {
    setIsRegistrationUser(false)
    setIsAccountUserCreated(true)
  }

  const setSelectedPlan = () => {
    setIsRegistrationProfessional(false)
    setIsSelectPlan(true)
  }

  const setOrderForm = () => {
    setIsSelectPlan(false)
    setIsOrderForm(true)
  }

  const setCorrectedPayment = () => {
    setIsOrderForm(false)
    setIsCorrectPayment(true)
  }

  const setError = () => {
    setIsOrderForm(false)
    setIsError(true)
  }

  const closeCorrectPayment = () => {
    setIsCorrectPayment(false)
    AuthState.setIsAuth(true)
  }

  const closeError = () => {
    setIsError(false)
    setIsOrderForm(true)
  }
  const width = AppState.widthWindow
  const isMobile = width <= 768

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <div className={styles.left} style={{ backgroundImage: `url(${bgAuth})` }}>
        <div className={styles.buttons}>
          <button onClick={() => changeActiveForm('signIn')} className={activeForm === 'signIn' ? styles.activeButton : styles.inactiveButton}>
            Inicia sesión
          </button>
          <button onClick={() => changeActiveForm('signUp')} className={activeForm === 'signUp' ? styles.activeButton : styles.inactiveButton}>
            ¿Aún no tienes cuenta?
          </button>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <Logo width={'143px'} height={'45px'} />
          </div>
          <h1 className={styles.title}>
            {activeForm === 'signIn' && !isForgot ? 'Inicia\nsesión' : activeForm === 'signUp' && !isForgot ? 'Crea\ntu cuenta' : isForgot ? '¿Olvidaste\ntu contraseña?' : ''}
          </h1>
          {isForgot && <p className={styles.forgotDescription}>{'Introduce tu email, te mandaremos\nun email de confirmación.'}</p>}
        </div>
        <div className={styles.form}>
          {activeForm === 'signIn' && !isForgot && <SignInForm handlerForm={() => changeActiveForm('signUp')} changeForgot={changeForgot} />}
          {activeForm === 'signUp' && !isForgot && <SignUpForm changeActiveForm={changeActiveForm} />}
          {isForgot && <ForgotForm onChange={() => changeActiveForm('signIn')} />}
        </div>
      </div>
      {isProfileSelection && (
        <div className={styles.modal}>
          <ProfileSelection closeProfileSelection={closeProfileSelection} changeSelectedProfile={changeSelectedProfile} />
        </div>
      )}
      {isRegistrationUser && (
        <div className={styles.modal}>
          <RegistrationUser closeRegistrationUser={closeRegistrationUser} setAccountUserCreated={setAccountUserCreated} />
        </div>
      )}
      {isAccountUserCreated && (
        <div className={styles.modal}>
          <AccountUserCreated closeAccountUserCreated={closeAccountUserCreated} />
        </div>
      )}
      {isRegistrationProfessional && (
        <div className={styles.modal}>
          <RegistrationProfessional closeRegistrationProfessional={closeRegistrationProfessional} setSelectedPlan={setSelectedPlan} />
        </div>
      )}
      {isSelectPlan && (
        <div className={styles.modal}>
          <SelectPlan closeSelectPlan={closeSelectPlan} setOrderForm={setOrderForm} />
        </div>
      )}
      {isOrderForm && (
        <div className={styles.modal}>
          <Elements stripe={loadStripe(stripePublicKey)}>
            <OrderForm closeOrderForm={closeOrderForm} setIsCorrectedPayment={setCorrectedPayment} setError={setError} />
          </Elements>
        </div>
      )}
      {isCorrectPayment && (
        <div className={styles.modal}>
          <CorrectPayment closeCorrectPayment={closeCorrectPayment} />
        </div>
      )}
      {isError && (
        <div className={styles.modal}>
          <Error closeError={closeError} />
        </div>
      )}
    </div>
  )
})
