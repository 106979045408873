import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Button } from '../../../../components/Button/Button'
import { CheckBox } from '../../../../components/CheckBox/CheckBox'
import AppState from '../../../../store/AppState'
import AuctionState from '../../../../store/AuctionState'
import styles from './Conditions.module.scss'

export const Conditions: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [check, setCheck] = useState(true)
  const onSubmit = () => {
    if (check) {
      AuctionState.setNextStep()
    }
  }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h2 className={styles.title}>Condiciones y cobro de 100€ de tasas</h2>
      <div className={styles.text}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque feugiat nisl in malesuada maximus. Nunc id diam dapibus, sodales lectus ut, hendrerit quam. Ut in mollis nulla, eget vestibulum
        ligula. In quis neque consectetur, ornare sem ut, facilisis sem. Etiam iaculis metus eget nibh varius molestie. Aliquam est ex, mollis vel facilisis vitae, lacinia at ante. Nullam purus magna,
        hendrerit eu ullamcorper aliquam, interdum quis quam. Pellentesque finibus fringilla erat, eu vestibulum velit pretium vitae. Suspendisse et dignissim neque. Mauris porttitor enim non felis
        mattis iaculis. Nunc id ex eu ante facilisis mollis. Maecenas lectus purus, laoreet in felis sit amet, iaculis varius sapien. Proin efficitur augue et diam congue, sed dictum odio pulvinar.
        Nunc non diam ac elit porttitor maximus. Nullam purus magna, hendrerit eu ullamcorper aliquam, interdum quis quam. Pellentesque finibus fringilla erat, eu vestibulum velit pretium vitae.
        Suspendisse et dignissim neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque feugiat nisl in malesuada maximus. Nunc id diam dapibus, sodales lectus ut, hendrerit quam. Ut
        in mollis nulla, eget vestibulum ligula. In quis neque consectetur, ornare sem ut, facilisis sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque feugiat nisl in malesuada
        maximus. Nunc id diam dapibus, sodales lectus ut, hendrerit quam. Ut in mollis nulla, eget vestibulum ligula. In quis neque consectetur, ornare sem ut, facilisis sem. Etiam iaculis metus eget
        nibh varius molestie. Aliquam est ex, mollis vel facilisis vitae, lacinia at ante. Nullam purus magna, hendrerit eu ullamcorper aliquam, interdum quis quam. Pellentesque finibus fringilla
        erat, eu vestibulum velit pretium vitae. Suspendisse et dignissim neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque feugiat nisl in malesuada maximus. Nunc id diam
        dapibus, sodales lectus ut, hendrerit quam. Ut in mollis nulla, eget vestibulum ligula. In quis neque consectetur, ornare sem ut, facilisis sem. Etiam iaculis metus eget nibh varius molestie.
        Aliquam est ex, mollis vel facilisis vitae, lacinia at ante. Nullam purus magna, hendrerit eu ullamcorper aliquam, interdum quis quam. Pellentesque finibus fringilla erat, eu vestibulum velit
        pretium vitae. Suspendisse et dignissim neque. Mauris porttitor enim non felis mattis iaculis. Nunc id ex eu ante facilisis mollis. Maecenas lectus purus, laoreet in felis sit amet, iaculis
        varius sapien. Proin efficitur augue et diam congue, sed dictum odio pulvinar. Nunc non diam ac elit porttitor maximus. Nullam purus magna, hendrerit eu ullamcorper aliquam, interdum quis
        quam. Pellentesque finibus fringilla erat, eu vestibulum velit pretium vitae. Suspendisse et dignissim neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque feugiat nisl in
        malesuada maximus. Nunc id diam dapibus, sodales lectus ut, hendrerit quam. Ut in mollis nulla, eget vestibulum ligula. In quis neque consectetur, ornare sem ut, facilisis sem. Lorem ipsum
        dolor sit amet, consectetur adipiscing elit. Quisque feugiat nisl in malesuada maximus. Nunc id diam dapibus, sodales lectus ut, hendrerit quam. Ut in mollis nulla, eget vestibulum ligula. In
        quis neque consectetur, ornare sem ut, facilisis sem. Etiam iaculis metus eget nibh varius molestie. Aliquam est ex, mollis vel facilisis vitae, lacinia at ante. Nullam purus magna, hendrerit
        eu ullamcorper aliquam, interdum quis quam. Pellentesque finibus fringilla erat, eu vestibulum velit pretium vitae. Suspendisse et dignissim neque.
      </div>
      <div className={styles.checkbox}>
        <CheckBox isCheck={check} label="He leído y acepto las condiciones descritas en el cuadro anterior y el cobro de 100€." onChange={() => setCheck(!check)} />
      </div>

      <div className={styles.bottom}>
        <div className={styles.btn} onClick={onSubmit}>
          <Button>Aceptar y pagar</Button>
        </div>
      </div>
    </div>
  )
})
