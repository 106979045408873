import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getAuctionByIdApi } from '../../../api/auctionApi'
import AppState from '../../../store/AppState'
import AuthState from '../../../store/AuthState'
import ModalState from '../../../store/ModalState'
import { CameraIcon, CheckCircleIcon, ExitIcon, GlobalIcon, LoaderIcon, MachineIcon, MoneyIcon, NotesIcon } from '../../Icons/Icons'
import styles from './ModalAuctionEdit.module.scss'
import { ModalAuctionCharact } from './components/ModalAuctionCharact/ModalAuctionCharact'
import { ModalAuctionComplete } from './components/ModalAuctionComplete/ModalAuctionComplete'
import { ModalAuctionConditions } from './components/ModalAuctionConditions/ModalAuctionConditions'
import { ModalAuctionDescription } from './components/ModalAuctionDescription/ModalAuctionDescription'
import { ModalAuctionForm } from './components/ModalAuctionForm/ModalAuctionForm'
import { ModalAuctionMedia } from './components/ModalAuctionMedia/ModalAuctionMedia'
import { ModalAuctionPay } from './components/ModalAuctionPay/ModalAuctionPay'
import { ModalAuctionPreview } from './components/ModalAuctionPreview/ModalAuctionPreview'
import { ModalAuctionProperty } from './components/ModalAuctionProperty/ModalAuctionProperty'

type TStep = {
  icon: ReactNode
  text: string
}

const dataStep: TStep[] = [
  {
    icon: <GlobalIcon />,
    text: 'Inmueble',
  },
  {
    icon: <NotesIcon />,
    text: 'Características',
  },
  {
    icon: <MachineIcon />,
    text: 'Descripción',
  },
  {
    icon: <CameraIcon />,
    text: 'Medios',
  },
  {
    icon: <MoneyIcon />,
    text: 'Subasta',
  },
]

const dataFormParticular: JSX.Element[] = [
  <ModalAuctionProperty />,
  <ModalAuctionCharact />,
  <ModalAuctionDescription />,
  <ModalAuctionMedia />,
  <ModalAuctionForm />,
  <ModalAuctionPreview />,
  // <ModalAuctionConditions />,
  // <ModalAuctionPay />,
  <ModalAuctionComplete />,
]
const dataFormProfessional: JSX.Element[] = [
  <ModalAuctionProperty />,
  <ModalAuctionCharact />,
  <ModalAuctionDescription />,
  <ModalAuctionMedia />,
  <ModalAuctionForm />,
  <ModalAuctionPreview />,
  <ModalAuctionComplete />,
]

export const ModalAuctionEdit: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const step = ModalState.step
  const dataForm = AuthState.user?.type === 'PROFESSIONAL' ? dataFormProfessional[step] : AuthState.user?.type === 'PARTICULAR' ? dataFormParticular[step] : null

  useEffect(() => {
    if (ModalState.auctionId) {
      getAuctionByIdApi(ModalState.auctionId).then((res) => {
        ModalState.setModalEditData(res.data)
        console.log(res.data)
      })
    }
  }, [ModalState.auctionId])
  const list = dataStep.map((item, i) => (
    <div className={`${styles.item} ${i === step ? styles.active : ''} ${i < step ? styles.ready : ''}`} key={i}>
      <span className={styles.icon}>{item.icon}</span>
      <div className={styles.status}>
        {i === step && (
          <span className={styles.loader}>
            <LoaderIcon />
          </span>
        )}
        {i > step && <span className={styles.default}></span>}
        {i < step && (
          <span className={styles.check}>
            <CheckCircleIcon />
          </span>
        )}
      </div>
      <span className={styles.text}>{item.text}</span>
    </div>
  ))

  return (
    <motion.div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      {step === 0 ? (
        <Link to="/" className={styles.exit} onClick={() => ModalState.toggleModalAuctionEdit(false)}>
          <ExitIcon />
        </Link>
      ) : (
        <div className={styles.exit} onClick={() => ModalState.setPrevStep()}>
          <ExitIcon />
        </div>
      )}
      {step < 5 && (
        <div className={styles.top}>
          <h2 className={styles.title}>Nueva subasta</h2>
          <div className={styles.list}>{list}</div>
        </div>
      )}

      {dataForm}
    </motion.div>
  )
})
