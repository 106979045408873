import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Tooltip } from 'chart.js'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import AppState from '../../../../../store/AppState'
import { priceFormat } from '../../../../../utils/price.utils'
import styles from './MainChart.module.scss'
import {TGetStatisticUserApi} from "../../../../../api/statApi";
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

export type TChart = {
  month: string
  value: number
}

type TChartStat = {
  title: string
  value: number
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      border: {
        display: false,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    y: {
      beginAtZero: true,
      border: {
        display: false,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      },
    },
  },
  borderRadius: 10,
  borderSkipped: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      backgroundColor: '#000',
      bodyFontSize: 14,
      yAlign: 'bottom',
      callbacks: {
        label: function (context: { dataset: { label: string }; parsed: { y: number | bigint | null } }) {
          let label = ''

          if (label) {
            label += ': '
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat('esp', { style: 'currency', currency: 'EUR', minimumSignificantDigits: 1 }).format(context.parsed.y)
          }
          return label
        },
        title: () => {
          return ''
        },
        labelColor: () => {
          return ''
        },
      },
      padding: 10,
    },
  },
}
// const dataChart: TChart[] = [
//   {
//     month: 'ENE',
//     value: 2000,
//   },
//   {
//     month: 'FEB',
//     value: 5000,
//   },
//   {
//     month: 'MAR',
//     value: 4500,
//   },
//   {
//     month: 'ABR',
//     value: 2100,
//   },
//   {
//     month: 'MAY',
//     value: 4800,
//   },
//   {
//     month: 'JUN',
//     value: 3500,
//   },
//   {
//     month: 'JUL',
//     value: 2800,
//   },
//   {
//     month: 'AGO',
//     value: 5700,
//   },
//   {
//     month: 'SEP',
//     value: 3600,
//   },
//   {
//     month: 'OCT',
//     value: 3500,
//   },
//   {
//     month: 'NOV',
//     value: 2100,
//   },
//   {
//     month: 'DIC',
//     value: 4100,
//   },
// ]

type TProps = {
  userStatistic: TGetStatisticUserApi
}

export const MainChart: FC<TProps> = observer(({userStatistic}) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  const data: TChartStat[] = [
    {
      title: 'Este mes',
      value: userStatistic.sumDepositsMonth,
    },
    {
      title: 'Últimos 6 meses',
      value: userStatistic.sumDeposits6Month,
    },
    {
      title: 'Último año',
      value: userStatistic.sumDepositsYear,
    },
  ]

  const list = data.map((item) => (
    <div className={styles.item} key={item.title}>
      <h5 className={styles.title}>{item.title}</h5>
      <span className={styles.value}>{priceFormat(item.value)}</span>
    </div>
  ))
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.list}>{list}</div>
      <div className={styles.chart}>
        <Bar
          options={options as any}
          data={{
            labels: userStatistic.statisticDeposits.map((item) => item.month),
            datasets: [
              {
                label: 'Dataset 1',
                data: userStatistic.statisticDeposits.map((item) => item.sum),
                backgroundColor: '#F4F4F4',
                borderWidth: 0,
                hoverBackgroundColor: '#0093D3',
              },
            ],
          }}
        />
      </div>
    </div>
  )
})
