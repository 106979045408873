import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TGetZipCodeObj, deleteZipCodeApi, updateZipCodeApi } from '../../../../api/zipcodeApi'
import { EditIcon, PinIcon, TrashIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import CodesState from '../../../../store/CodesState'
import ModalState from '../../../../store/ModalState'
import UpdateState from '../../../../store/UpdateState'
import styles from './PostcodesItem.module.scss'

type TPostcodesItemProps = TGetZipCodeObj

export const PostcodesItem: FC<{ item: TPostcodesItemProps }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const onDelete = (id: string) => {
    deleteZipCodeApi({ id: id })
      .then((res) => UpdateState.setZipCodesUpdate())
      .catch((err) => console.log(err))
  }
  const onChange = () => {
    CodesState.setObj({
      id: item.id,
      code: item.code,
      city: item.city,
      provinceId: item.provinceId,
      active: item.active,
    })
    ModalState.setModalCodesType('update')
    ModalState.showModalCodes()
  }

  const onUpdate = () => {
    const data = {
      id: item.id,
      code: item.code,
      city: item.city,
      provinceId: item.provinceId,
      active: !item.active,
    }
    updateZipCodeApi(data)
      .then((res) => UpdateState.setZipCodesUpdate())
      .catch((err) => console.log(err))
  }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.left}>
        <div className={styles.icon}>
          <PinIcon />
        </div>
        <div className={styles.content}>
          <h4 className={styles.title}>{item.code}</h4>
          <div className={styles.location}>
            <span>{item.Province.name},</span>
            <span>{item.city}</span>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.status}>{item.active ? <span className={styles.green}>Activo</span> : <span className={styles.red}>Inactivo</span>}</div>
        <div className={`${styles.switch} ${item.active ? styles.active : ''}`} onClick={onUpdate}>
          <span className={styles.line}></span>
          <span className={styles.circle}></span>
        </div>
        <div className={styles.edit}>
          <button onClick={onChange}>
            <EditIcon />
          </button>
          <button onClick={() => onDelete(item.id)}>
            <TrashIcon />
          </button>
        </div>
      </div>
    </div>
  )
})
