const localStorageName = 'authToken'

export const setAuthLocaleStorage = (token: string) => {
  return localStorage.setItem(localStorageName, token)
}

export const checkAuthLocalStorage = () => {
  const data = localStorage.getItem(localStorageName)
  if (data) {
    return data
  }
  return false
}

export const clearAuthLocalStorage = () => {
  localStorage.removeItem(localStorageName)
  return true
}