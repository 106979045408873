import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TUsersObj, patchUserByIdApi } from '../../../../api/usersApi'
import { DownIcon, LockCloseIcon, TrashIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import ModalState from '../../../../store/ModalState'
import UpdateState from '../../../../store/UpdateState'
import UserState from '../../../../store/UserState'
import { dateFormatFull } from '../../../../utils/date.utils'
import { nameFormat } from '../../../../utils/name.utils'
import styles from './UsersItem.module.scss'

export type TUsersItemProps = TUsersObj

export const UsersItem: FC<{ item: TUsersItemProps }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const onBlock = (id: string) => {
    patchUserByIdApi({ userId: id, isBlocked: !item.isBlocked })
      .then((res) => UpdateState.setUsersUpdate())
      .catch((err) => console.log(err))
  }

  const onDelete = (id: string) => {
    UserState.setHandlerId(id)
    ModalState.showModalUserDelete()
  }

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.left}>
        {item.ProfessionalData && (
          <>
            <span className={styles.icon} style={{ backgroundImage: `url(data:${item.ProfessionalData.logoType};base64,${item.ProfessionalData.logoData})` }}></span>
            <div className={styles.text}>
              <h4 className={styles.title}>{item.ProfessionalData.company}</h4>
              <span className={styles.cif}>CIF: {item.ProfessionalData.CIF}</span>
            </div>
          </>
        )}

        {item.ParticularData && (
          <>
            <span className={styles.name}>{nameFormat(`${item.ParticularData.name} ${item.ParticularData.firstSurname}`)}</span>
            <div className={styles.text}>
              <h4 className={styles.title}>
                {item.ParticularData.name} {item.ParticularData.firstSurname} {item.ParticularData.secondSurname}
              </h4>
              <span className={styles.cif}>DNI: {item.ParticularData.NIF}</span>
            </div>
          </>
        )}
      </div>
      <div className={styles.center}>
        <div className={styles.list}>
          <div className={styles.item}>
            <span className={styles.value}>{item.Auction ? item.Auction.length : 0}</span>
            <h4 className={styles.title}>Subastas subidas</h4>
          </div>
          <div className={styles.item}>
            <span className={styles.value}>{item.Bid ? item.Bid.length : 0}</span>
            <h4 className={styles.title}>Pujas realizadas</h4>
          </div>
          <div className={styles.item}>
            <span className={styles.value}>{item.Awarded ? item.Awarded.length : 0}</span>
            <h4 className={styles.title}>S. Adjudicadas</h4>
          </div>
          <div className={styles.item}>
            <span className={styles.value}>{item.Active ? item.Active.length : 0}</span>
            <h4 className={styles.title}>S. Activas</h4>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.date}>
          <span className={styles.value}>{dateFormatFull(item.accessControl)}</span>
          <h4 className={styles.title}>Última conexión</h4>
        </div>
        <div className={styles.btn}>
          <span className={`${styles.status} ${item.isBlocked ? styles.red : styles.green}`} onClick={() => onBlock(item.id)}>
            <LockCloseIcon />
          </span>
          <span className={styles.remove} onClick={() => onDelete(item.id)}>
            <TrashIcon />
          </span>
          <span className={styles.link} onClick={() => UserState.setHandlerUserLink(item.id)}>
            <DownIcon />
          </span>
        </div>
      </div>
    </div>
  )
})
