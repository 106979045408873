import { orderBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TAuctionObj } from '../../../../api/auctionApi'
import { TUsersObj, getUserByIdApi } from '../../../../api/usersApi'
import { Button } from '../../../../components/Button/Button'
import { Favorites } from '../../../../components/Favorites/Favorites'
import { BuildIcon, HomeIconHover, LocationIcon, PlacesIcon, RepairIcon, ShowersIcon, SquareIcon } from '../../../../components/Icons/Icons'
import { Timer } from '../../../../components/Timer/Timer'
import { urlApi } from '../../../../config/apiConfig'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import { nameFormat } from '../../../../utils/name.utils'
import { priceFormat } from '../../../../utils/price.utils'
import styles from './EstateItem.module.scss'

export const EstateItem: FC<{ item: TAuctionObj; isAdmin?: boolean }> = observer(({ item, isAdmin }) => {
	const width = AppState.widthWindow
	const isMobile = width <= 768
	const [user, setUser] = useState<TUsersObj | null>(null)
	const userId = AuthState.user?.id

	useEffect(() => {
		if (isAdmin) {
			getUserByIdApi(item.userId).then((res) => {
				setUser(res.data)
			})
		}
	}, [])
	return (
		<div className={`${isMobile ? styles.rootMobile : styles.root}`}>
			<Link
				to={`/auction/${item.id}`}
				className={styles.img}
				style={
					item.AuctionMedia.length !== 0 && item.AuctionMedia[0].id
						? { backgroundImage: `url(${urlApi}auction/media?id=${item.AuctionMedia[0].id})` }
						: undefined
				}></Link>
			<div className={styles.content}>
				<span className={styles.ref}>Referencia: {item.reference}</span>
				<Link to={`/auction/${item.id}`} className={styles.title}>
					{item.TypeProperty?.name} en {item.address} {item.city} con {item.bedrooms} habitaciones y {item.bathrooms}{' '}
					{item.bathrooms >= 2 ? 'baños.' : 'baño'}
				</Link>
				<div className={styles.location}>
					<LocationIcon />
					{item.address && <span>{item.address},</span>}
					{item.postcode && <span>{item.postcode},</span>}
					{item.Province !== null && <span>{item.Province.name}, </span>}
					{item.city !== null && <span>{item.city}, </span>}
				</div>
				{/*{item.internalObservations && <div className={styles.text}>{cuttextUtils(item.description, 300)}</div>}*/}
				<div className={styles.info}>
					<div className={styles.list}>
						<div className={styles.item}>
							<span className={styles.value}>
								<SquareIcon />
								{item.plotMeters}m<sup>2</sup>
							</span>
						</div>
						{item.bedrooms && (
							<div className={styles.item}>
								<span className={styles.value}>
									<PlacesIcon />
									{item.bedrooms} hab
								</span>
							</div>
						)}
						{item.bathrooms && (
							<div className={styles.item}>
								<span className={styles.value}>
									<ShowersIcon />
									{item.bathrooms} baños
								</span>
							</div>
						)}
						<div className={styles.item}>
							<span className={styles.value}>
								<BuildIcon />
								{item.TypeProperty && item.TypeProperty.name}
							</span>
						</div>
						<div className={styles.item}>
							<span className={styles.value}>
								<RepairIcon />
								{item.PropertyStatus && item.PropertyStatus.name}
							</span>
						</div>
					</div>
				</div>
				<div className={styles.price}>
					<div className={styles.list}>
						<div className={styles.item}>
							<span className={styles.value}> {priceFormat(item.auctionStartingPrice)}</span>
							<span className={styles.text}>Precio de salida</span>
						</div>
						<div className={styles.item}>
							<span className={styles.value}> {priceFormat(item.Bid.length ? orderBy(item.Bid, ['sum'], ['desc'])[0].sum : 0)}</span>
							<span className={styles.text}>Última puja</span>
						</div>
						<div className={styles.item}>
							<span className={styles.value}>
								{' '}
								{!userId || !item.Bid.some((i) => i.userId === userId)
									? priceFormat(0)
									: priceFormat(
											orderBy(
												item.Bid.filter((i) => i.userId === userId),
												['sum'],
												'desc'
											)[0].sum
									  )}
							</span>
							<span className={styles.text}>Mi última puja</span>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.block}>
				<div className={styles.timer}>
					<Timer date={new Date(item.endAuction)} size="big" />
				</div>
				{isAdmin && (
					<>
						{user?.ProfessionalData !== null && (
							<div className={styles.bottom}>
								<div className={styles.content}>
									<h4 className={styles.title}>{user?.ProfessionalData?.company}</h4>
									<span className={styles.text}>{user?.ProfessionalData?.contactPerson}</span>
								</div>
								<span className={styles.icon}>
									<HomeIconHover />
								</span>
							</div>
						)}

						{user?.ParticularData !== null && (
							<div className={styles.bottom}>
								<div className={styles.content}>
									<h4 className={styles.title}>
										{user?.ParticularData?.name} {user?.ParticularData?.firstSurname} {user?.ParticularData?.secondSurname}
									</h4>
									<span className={styles.text}>Particular</span>
								</div>
								<span className={styles.name}>{nameFormat(`${user?.ParticularData?.name} ${user?.ParticularData?.firstSurname}`)}</span>
							</div>
						)}
					</>
				)}

				{!isAdmin && (
					<div className={styles.bottom}>
						<div className={styles.favorite}>
							<Favorites />
						</div>
						<div className={styles.btn}>
							<Link to={`/auction/${item.id}`}>
								<Button color="blue">Pujar</Button>
							</Link>
						</div>
					</div>
				)}
			</div>
		</div>
	)
})
