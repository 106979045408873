import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { FilterBlock } from '../../components/FilterBlock/FilterBlock'
import { Switch } from '../../components/Switch/Switch'
import AppState from '../../store/AppState'
import styles from './BidsPage.module.scss'
import { BidsActive } from './components/BidsActive/BidsActive'
import { BidsClose } from './components/BidsClose/BidsClose'
import { BidsSuccess } from './components/BidsSuccess/BidsSuccess'

const data: string[] = ['Pujas activas', 'Pujas cerradas', 'Pujas adjudicadas']
const dataFilter: string[] = ['Data 1', 'Data 2', 'Data 3', 'Data 4', 'Data 5']
const elements: JSX.Element[] = [<BidsActive />, <BidsClose />, <BidsSuccess />]
export const BidsPage: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [index, setIndex] = useState(0)

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.top}>
        <FilterBlock items={dataFilter} title="Pujas" full />
      </div>
      <div className={styles.switch}>
        <Switch items={data} index={index} onChange={(i) => setIndex(i)} />
      </div>
      <div className={styles.content}>{elements[index]}</div>
    </div>
  )
})
