import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TAuctionObj } from '../../../../api/auctionApi'
import AppState from '../../../../store/AppState'
import styles from './MainValidate.module.scss'
import { MainValidateItem } from './components/MainValidateItem/MainValidateItem'

export const MainValidate: FC<{ items: TAuctionObj[] }> = observer(({ items }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  const list = items.map((item, i) => <MainValidateItem item={item} key={i} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h3 className={styles.title}>Pending validation</h3>
      <div className={styles.list}>{list}</div>
    </div>
  )
})
