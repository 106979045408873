import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import {TAuctionEditFile, postUploadFileApi, postUploadLinkApi, deleteFileApi} from '../../../../../api/auctionApi'
import { ImageFile } from '../../../../../pages/NewAuctionPage/components/ImageFile/ImageFile'
import AppState from '../../../../../store/AppState'
import ModalState from '../../../../../store/ModalState'
import { Button } from '../../../../Button/Button'
import { AcortarIcon, ExitIcon, RightArrowIcon } from '../../../../Icons/Icons'
import styles from './ModalAuctionMedia.module.scss'

export const ModalAuctionMedia: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const ref = ModalState.data?.reference
  const step = ModalState.step
  const size = String(Math.floor(Math.random() * 1000))
  const [file, setFile] = useState<null | File[]>(null)
  const [newFileResult, setNewFileResult] = useState<TAuctionEditFile[]>([])
  const [oldFileResult, setOldFileResult] = useState<TAuctionEditFile[]>([])
  const [link, setLink] = useState<string>('')
  const [nameFile, setNameFile] = useState<string | null>(null)

  useEffect(() => {
    if (file) {
      const formData = new FormData()
      formData.append('reference', String(ref))

      for (let i = 0; i < file.length; i++) {
        formData.append('media', file[i])
      }
      postUploadFileApi(formData)
        .then((res) => {
          setNewFileResult((prev) => [...prev].concat(res.data))
          ModalState.setImages(newFileResult)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [file])

  useEffect(() => {
    ModalState.setImages(newFileResult)
  }, [newFileResult])

  useEffect(() => {
    if (ModalState.data) {
      ModalState.data.AuctionMedia.forEach((item) =>
        setOldFileResult((prev) => [
          ...prev,
          {
            auctionId: item.id,
            id: item.id,
            name: 'loaded.png',
          },
        ])
      )

      ModalState.data.AuctionMediaLink.forEach((item) =>
        setOldFileResult((prev) => [
          ...prev,
          {
            auctionId: item.id,
            id: item.id,
            name: item.link ? item.link : '',
          },
        ])
      )
    }
  }, [ModalState.data])

  const changeFile = (file: File[]) => {
    setFile(file)
  }

  const changeLink = () => {
    const id = String(Math.floor(Math.random() * 100000))
    if (link !== '') {
      setNewFileResult((prev) => [...prev, { id: id, type: 'link', name: 'Link externo', size: link, auctionId: id }])
      postUploadLinkApi({ reference: ref ? ref : 0, link: link })
        .then((res) => {
          /* console.log(res) */
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const removeFile = async (id: string) => {
    deleteFileApi({id}).then(() => {
      setOldFileResult((prev) => prev.filter((el) => el.id !== id))
      setNewFileResult((prev) => prev.filter((el) => el.id !== id))
    })

  }

  const onSubmit = () => {
    /* ModalState.setImages(newFileResult) */
    console.log(ModalState.images)
    ModalState.setNextStep()
  }
  const oldFileList = oldFileResult.map((item) => (
    <div className={styles.item} key={item.id}>
      {item.name.split('.')[1] === 'png' || item.name.split('.')[1] === 'jpeg' || item.name.split('.')[1] === 'jpg' || item.name.split('.')[1] === 'pdf' ? (
        <span className={styles.loader}>100%</span>
      ) : (
        <span className={styles.acortar}>
          <AcortarIcon />
        </span>
      )}
      <div className={styles.info}>
        <h5 className={styles.title}>{item.name}</h5>
        {item.name.split('.')[1] === 'png' || item.name.split('.')[1] === 'jpeg' || item.name.split('.')[1] === 'jpg' || item.name.split('.')[1] === 'pdf' ? (
          <span className={styles.size}>100Kb/100Kb</span>
        ) : (
          <span className={styles.size}></span>
        )}
      </div>
      <span className={styles.remove} onClick={() => removeFile(item.id)}>
        <ExitIcon />
      </span>
    </div>
  ))

  const newFileList = (ModalState.images !== null ? ModalState.images : newFileResult).map((item) => (
    <div className={styles.item} key={item.id}>
      {item.name.split('.')[1] === 'png' || item.name.split('.')[1] === 'jpeg' || item.name.split('.')[1] === 'jpg' || item.name.split('.')[1] === 'pdf' ? (
        <span className={styles.loader}>100%</span>
      ) : (
        <span className={styles.acortar}>
          <AcortarIcon />
        </span>
      )}
      <div className={styles.info}>
        <h5 className={styles.title}>{item.name}</h5>
        {item.name.split('.')[1] === 'png' || item.name.split('.')[1] === 'jpeg' || item.name.split('.')[1] === 'jpg' || item.name.split('.')[1] === 'pdf' ? (
          <span className={styles.size}>100Kb/100Kb</span>
        ) : (
          <span className={styles.size}></span>
        )}
      </div>
      <span className={styles.remove} onClick={() => removeFile(item.id)}>
        <ExitIcon />
      </span>
    </div>
  ))
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.file}>
            <ImageFile title="Sube aquí tus imágenes" changeFile={changeFile} />
            {nameFile}
          </div>
          <div className={styles.link}>
            <h5 className={styles.title}>Añade un link a un video o 360º</h5>
            <div className={styles.block}>
              <input type="text" className={styles.input} value={link} placeholder="Pega aquí el link" onChange={(e) => setLink(e.currentTarget.value)} />
              <button className={styles.add} onClick={changeLink}>
                <RightArrowIcon />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.top}>
            <h4 className={styles.title}>Imágenes subidas</h4>
            <span className={styles.length}>{oldFileList.length}</span>
          </div>
          <div className={styles.list}>{oldFileList}</div>
          <div className={styles.list}>{newFileList}</div>
        </div>
      </div>
      <div className={styles.navigation}>
        {step > 0 && (
          <span className={styles.prev} onClick={() => ModalState.setPrevStep()}>
            <RightArrowIcon />
            Volver al paso anterior
          </span>
        )}
        <span className={styles.next} onClick={onSubmit}>
          <Button color="blue">Continuar</Button>
        </span>
      </div>
    </div>
  )
})
