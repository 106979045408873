import { ChangeEvent, FC, useState } from 'react'
import AppState from '../../store/AppState'
import { SearchIcon } from '../Icons/Icons'
import styles from './InputFile.module.scss'

type TProps = {
  changeFile: (file: File) => void
  error?: boolean
  label: string
  placeholder: string
}

export const InputFile: FC<TProps> = ({ changeFile, error, label, placeholder }) => {
  const id = String(Math.floor(Math.random() * 100000))
  const [nameFile, setNameFile] = useState<string | null>(null)
  const width = AppState.widthWindow
  const isMobile = width <= 768

  const _changeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length) {
      setNameFile(e.currentTarget.files[0].name)
      changeFile(e.currentTarget.files[0])
    }
  }

  const getNameFile = () => {
    if (!nameFile) {
      return placeholder
    } else {
      if (nameFile.length > 38) {
        return `${nameFile.slice(0, 35)}...`
      }
      return nameFile
    }
  }

  return (
    <div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
      <input id={id} type={'file'} className={styles.input} onChange={_changeFile} />
      <label htmlFor={id} className={styles.label}>
        <p className={styles.fakeLabel}>{label}</p>
        <div className={styles.fakeBox}>
          <div className={`${styles.placeholder} ${error ? styles.error : ''}`}>{getNameFile()}</div>
          <div className={styles.button}>
            <SearchIcon />
          </div>
        </div>
      </label>
    </div>
  )
}
