import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { TAuctionObj } from '../../../../api/auctionApi'
import { RightArrowIcon } from '../../../../components/Icons/Icons'
import AppState from '../../../../store/AppState'
import styles from './MainLatest.module.scss'
import { MainLatestItem } from './components/MainLatestItem/MainLatestItem'
import {BIDS_PATH} from '../../../../services/mainServices/RoutesService'

export const MainLatest: FC<{ items: TAuctionObj[] }> = observer(({ items }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768

  const list = items.map((item, i) => <MainLatestItem item={item} key={i} />)
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <h3 className={styles.title}>
        Últimas pujas{' '}
        <Link to={BIDS_PATH}>
          Ver todas <RightArrowIcon />
        </Link>
      </h3>
      <div className={styles.list}>{list}</div>
    </div>
  )
})
