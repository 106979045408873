import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { addDataProfessionalApi } from '../../../../api/authApi'
import { TProvince, getProvincesApi } from '../../../../api/provinceApi'
import { Button } from '../../../../components/Button/Button'
import { ExitIcon } from '../../../../components/Icons/Icons'
import { InputFile } from '../../../../components/InputFile/InputFile'
import { SelectField, TItemSelect } from '../../../../components/SelectField/SelectField'
import { TextField } from '../../../../components/TextField/TextField'
import AppState from '../../../../store/AppState'
import AuthState from '../../../../store/AuthState'
import styles from './RegistrationProfessional.module.scss'

type TFormRegistrationObj = {
	name: string
	error: boolean
}

type TFormRegistrationProfessional = {
	tradeName: TFormRegistrationObj
	CIF: TFormRegistrationObj
	address: TFormRegistrationObj
	contactPerson: TFormRegistrationObj
	company: TFormRegistrationObj
	phone: TFormRegistrationObj
	postcode: TFormRegistrationObj
	city: TFormRegistrationObj
}

type TFormFieldName = 'tradeName' | 'CIF' | 'address' | 'contactPerson' | 'company' | 'phone' | 'postcode'
type TFormFieldNameSelect = 'city' | 'province'

type TProps = {
	closeRegistrationProfessional: () => void
	setSelectedPlan: () => void
}

export const RegistrationProfessional: FC<TProps> = observer(({ closeRegistrationProfessional, setSelectedPlan }) => {
	const [form, setForm] = useState<TFormRegistrationProfessional>({
		tradeName: {
			name: '',
			error: false,
		},
		company: {
			name: '',
			error: false,
		},
		CIF: {
			name: '',
			error: false,
		},
		phone: {
			name: '',
			error: false,
		},
		address: {
			name: '',
			error: false,
		},
		postcode: {
			name: '',
			error: false,
		},
		contactPerson: {
			name: '',
			error: false,
		},
		city: {
			name: '',
			error: false,
		},
	})
	const [province, setProvince] = useState<TItemSelect | null>(null)
	const [logotype, setLogotype] = useState<null | File>(null)
	const [provinces, setProvinces] = useState<TProvince[]>([])
	const [selectCityError, setSelectCityError] = useState<boolean>(false)
	const [selectProvinceError, setSelectProvinceError] = useState<boolean>(false)
	const [selectLogotypeError, setSelectLogotypeError] = useState<boolean>(false)
	useEffect(() => {
		getProvincesApi().then((res) => {
			setProvinces(res.data)
		})
	}, [])

	const fields: {
		id: number
		label: string
		placeholder: string
		type: 'input' | 'select'
		fieldName: TFormFieldName | TFormFieldNameSelect
		listSelect?: TItemSelect[]
	}[] = [
		{ id: 1, label: 'Nombre comercial*', placeholder: 'Introduce un nombre', type: 'input', fieldName: 'tradeName' },
		{ id: 2, label: 'Empresa*', placeholder: 'Nombre de la empresa', type: 'input', fieldName: 'company' },
		{ id: 3, label: 'Teléfono', placeholder: 'Introduce número de teléfono', type: 'input', fieldName: 'phone' },
		{ id: 4, label: 'CIF*', placeholder: 'Introduce tu CIF', type: 'input', fieldName: 'CIF' },
		{ id: 5, label: 'Código postal*', placeholder: 'Introduce código postal', type: 'input', fieldName: 'postcode' },
		{ id: 6, label: 'Dirección*', placeholder: 'Intoduce dirección', type: 'input', fieldName: 'address' },
		{ id: 7, label: 'Provincia*', placeholder: 'Selecciona provincia', type: 'select', fieldName: 'province', listSelect: provinces },
		{ id: 8, label: 'Ciudad*', placeholder: 'Intoduce ciudad', type: 'input', fieldName: 'city' },
		{ id: 9, label: 'Persona de contacto*', placeholder: 'Nombre y apellidos', type: 'input', fieldName: 'contactPerson' },
	]

	/* const rightCol: { id: number; label: string; placeholder: string; type: 'input' | 'select'; fieldName: TFormFieldName | TFormFieldNameSelect; listSelect?: TItemSelect[] }[] = [
    { id: 6, label: 'Empresa*', placeholder: 'Nombre de la empresa', type: 'input', fieldName: 'company' },
    { id: 7, label: 'Teléfono', placeholder: 'Introduce número de teléfono', type: 'input', fieldName: 'phone' },
    { id: 8, label: 'Código postal*', placeholder: 'Introduce código postal', type: 'input', fieldName: 'postcode' },
    { id: 9, label: 'Provincia*', placeholder: 'Selecciona provincia', type: 'select', fieldName: 'province', listSelect: provinces },
  ] */

	const changeForm = (e: ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name
		if (
			name === 'tradeName' ||
			name === 'CIF' ||
			name === 'address' ||
			name === 'contactPerson' ||
			name === 'company' ||
			name === 'city' ||
			name === 'phone' ||
			name === 'postcode'
		) {
			setForm({ ...form, [name]: { name: e.currentTarget.value, error: name === 'phone' ? false : !!true } })
		}
	}

	const changeLogotype = (file: File) => {
		if (logotype === null) {
			setLogotype(file)
			setSelectLogotypeError(false)
		} else {
			setSelectLogotypeError(true)
		}
	}

	const changeSelect = (name: string, item: TItemSelect) => {
		if (name === 'province') {
			setProvince(item)
			setSelectProvinceError(false)
		}
	}

	const handlerAddProfessionalData = async () => {
		const result = Object.entries(form).map(([key, value]) => [
			key,
			{ name: value.name, error: key === 'phone' ? false : value.name === '' ? true : false },
		])
		setSelectProvinceError(province === null ? true : false)
		setForm(Object.fromEntries(result))

		logotype !== null ? setSelectLogotypeError(false) : setSelectLogotypeError(true)

		const error = Object.entries(form).some(([key, value]) => (key === 'phone' ? false : value.name === ''))

		if (!error && province !== null && logotype !== null) {
			const formData = new FormData()
			formData.append('tradeName', form.tradeName.name)
			formData.append('CIF', form.CIF.name)
			formData.append('address', form.address.name)
			formData.append('contactPerson', form.contactPerson.name)
			formData.append('company', form.company.name)
			formData.append('phone', form.phone.name)
			formData.append('postcode', form.postcode.name)
			formData.append('city', form.city.name)
			formData.append('provinceId', province.id)
			formData.append('logotype', logotype)

			addDataProfessionalApi(formData)
				.then((res) => {
					AuthState.setUser(res.data)
					setSelectedPlan()
				})
				.catch((err) => {
					console.log(err.response.data)
				})
		}
	}

	const list = fields.map((i) => {
		if (i.type === 'input' && i.fieldName !== 'province') {
			return (
				<div key={i.id} className={styles.field}>
					<TextField
						label={i.label}
						error={form[i.fieldName].error}
						name={i.fieldName}
						value={form[i.fieldName].name}
						placeholder={i.placeholder}
						onChange={changeForm}
					/>
				</div>
			)
		}
		if (i.type === 'select' && i.listSelect) {
			return (
				<div key={i.id} className={styles.field}>
					<SelectField
						scroll
						label={i.label}
						placeholder={i.placeholder}
						selected={i.fieldName === 'province' ? province : null}
						list={i.listSelect}
						name={i.fieldName}
						error={selectCityError}
						changeSelected={changeSelect}
					/>
				</div>
			)
		}
		return null
	})

	const width = AppState.widthWindow
	const isMobile = width <= 768

	return (
		<div className={`${isMobile ? styles.wrapperMobile : styles.wrapper}`}>
			<div className={styles.exit} onClick={closeRegistrationProfessional}>
				<ExitIcon />
			</div>
			<h1 className={styles.title}>Completa los datos de tu cuenta</h1>
			<div className={styles.form}>
				{list}
				<InputFile error={selectLogotypeError} changeFile={changeLogotype} label="Logotipo*" placeholder="Sube tu logotipo" />
			</div>

			<div className={styles.button}>
				<Button onClick={handlerAddProfessionalData}>Crear cuenta</Button>
			</div>
		</div>
	)
})
