import { apiService } from '../services/apiServices/apiService'

export type TPlan = {
  favorite: boolean
  id: string
  limit: number
  name: string
  payment: 'ONE_TIME_PAYMENT' | 'MONTHLY_PAYMENT'
  price: number
  description: string
}

type TResGetPlansApi = TPlan[]

export const getPlansApi = async () => {
  return await apiService<TResGetPlansApi>('get', 'plan')
}

type TResGetPaymentDataApi = {
  clientSecret: string
  price: number
  name: string
}

export const getPaymentDataApi = async () => {
  return await apiService<TResGetPaymentDataApi>('get', 'plan/getPaymentData')
}
