import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AppState from '../../store/AppState'
import AuthState from '../../store/AuthState'
import styles from './MainPage.module.scss'
import { MainAdmin } from './components/MainAdmin/MainAdmin'
import { MainDefault } from './components/MainDefault/MainDefault'

export const MainPage: FC = observer(() => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const isAdmin = AuthState.user?.type === 'ADMIN'

  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      {isAdmin && (
        <h2 className={styles.title}>
          <span>Admin,</span> How are you?
        </h2>
      )}
      {AuthState.user?.type === 'PROFESSIONAL' && !isAdmin && (
        <h2 className={styles.title}>
          <span>{AuthState.user?.ProfessionalData?.contactPerson},</span> How are you?
        </h2>
      )}
      {AuthState.user?.type === 'PARTICULAR' && !isAdmin && (
        <h2 className={styles.title}>
          <span>{AuthState.user?.ParticularData?.firstSurname}, </span> How are you?
        </h2>
      )}
      <div className={styles.content}>{isAdmin ? <MainAdmin /> : <MainDefault />}</div>
    </div>
  )
})
