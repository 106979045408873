import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { getRolesAllApi, updateRolesAllApi } from '../../../api/rolesApi'
import { EditIcon } from '../../../components/Icons/Icons'
import { SelectField, TItemSelect } from '../../../components/SelectField/SelectField'
import AppState from '../../../store/AppState'
import { nameFormat } from '../../../utils/name.utils'
import { TAuctionSelectData, TAuctionSelectField } from '../../NewAuctionPage/components/PropertyForm/PropertyForm'
import styles from './RolesItem.module.scss'

/* export type TRolesItem = {
  fullName: string
  dni: string
  phone: string
  email: string
  role: 'Admin' | 'Professional' | 'Particular'
} */
type TRolesProps = getRolesAllApi
export const RolesItem: FC<{ item: TRolesProps }> = observer(({ item }) => {
  const width = AppState.widthWindow
  const isMobile = width <= 768
  const [isActive, setIsActive] = useState(false)
  const [roleData, setRoleData] = useState<TAuctionSelectData[]>([
    { id: 'ADMIN', name: 'ADMIN' },
    { id: 'PROFESSIONAL', name: 'PROFESSIONAL' },
  ])

  const [role, setRole] = useState<TAuctionSelectField>({
    value: null,
    error: false,
  })

  useEffect(() => {
    setRole((prev) => ({ ...prev, value: { id: item.type, name: item.type }, error: false }))
  }, [])

  const changeSelect = (name: string, el: TItemSelect) => {
    if (name === 'role') {
      setRole((prev) => ({ ...prev, value: el, error: false }))
      setIsActive(false)

      if (role.value !== null) {
        updateRolesAllApi({
          userId: item.ProfessionalData.userId,
          tradeName: item.ProfessionalData.tradeName,
          CIF: item.ProfessionalData.CIF,
          address: item.ProfessionalData.address,
          contactPerson: item.ProfessionalData.contactPerson,
          company: item.ProfessionalData.company,
          phone: item.ProfessionalData.phone,
          postcode: item.ProfessionalData.postcode,
          city: item.ProfessionalData.city,
          provinceId: item.ProfessionalData.provinceId,
          type: role.value?.name,
        })
      }
    }
  }

  useEffect(() => {
    console.log(role.value?.name)
  }, [role])
  return (
    <div className={`${isMobile ? styles.rootMobile : styles.root}`}>
      <div className={styles.left}>
        <div className={styles.icon}>{item.ProfessionalData ? nameFormat(item.ProfessionalData.contactPerson) : 'Ad'}</div>
        <h3 className={styles.title}>{item.ProfessionalData ? item.ProfessionalData.contactPerson : 'Admin'}</h3>
      </div>
      <div className={styles.center}>
        <div className={styles.block}>
          <span className={styles.value}>{item.ProfessionalData ? item.ProfessionalData.CIF : '123456'}</span>
          <h4 className={styles.title}>DNI</h4>
        </div>
        <div className={styles.block}>
          <span className={styles.value}>{item.ProfessionalData ? item.ProfessionalData.phone : '89997775522'}</span>
          <h4 className={styles.title}>Teléfono</h4>
        </div>
        <div className={styles.block}>
          <span className={styles.value}>{item.email}</span>
          <h4 className={styles.title}>Email</h4>
        </div>
      </div>
      <div className={styles.right}>
        {isActive && (
          <div className={styles.select}>
            <SelectField labelShow={false} label="Permiso*" placeholder="Selecciona permiso" selected={role.value} list={roleData} name="role" changeSelected={changeSelect} />
          </div>
        )}
        {!isActive && (
          <>
            <div className={styles.role}>
              <span className={styles.value}>{role.value?.name}</span>
              <h4 className={styles.title}>Permiso</h4>
            </div>

            <div className={styles.edit} onClick={() => setIsActive(!isActive)}>
              <button>
                <EditIcon />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
})
